import CryptoJS from 'crypto-js';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();
export const push = (url, state) => history.push(process.env.REACT_APP_PREFIX + url, state);
window.pushF = push;
export const replace = (url, state) => history.replace(process.env.REACT_APP_PREFIX + url, state);

/**
 * 将search转换成对象
 * @returns 
 */
export function formatSearch() {
  let se = window.location.search
  if (typeof se !== "undefined") {
    se = se.substring(1);
    var arr = se.split("&"),
      obj = {},
      newarr = [];
    arr.forEach((v, i) => {
      newarr = v.split("=");
      if (typeof obj[newarr[0]] === "undefined") {
        obj[newarr[0]] = newarr[1];
      }
    }
    )
    return obj ;
  };
}

/**
 * 加密
 * @method encryptForLogin
 * @param  String word 待加密数据
 */
// DESCBC模式加密
// 加密内容、秘钥、向量
export function encryptByDES(message, key = 's!5%E_d,', iv = 's!5%E_d,') {
  const keyHex = CryptoJS.enc.Utf8.parse(key);
  const ivHex = CryptoJS.enc.Utf8.parse(iv);
  const encrypted = CryptoJS.DES.encrypt(message, keyHex, {
    iv: ivHex,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.ciphertext.toString()
    .toLocaleUpperCase();
}

//DES  CBC模式解密
export function decryptByDESModeEBC(ciphertext, key = 's!5%E_d,', iv = 's!5%E_d,') {
  //把私钥转换成UTF - 8编码的字符串
  var keyHex = CryptoJS.enc.Utf8.parse(key);
  var ivHex = CryptoJS.enc.Utf8.parse(iv);
  // direct decrypt ciphertext
  var decrypted = CryptoJS.DES.decrypt(
    {
      ciphertext: CryptoJS.enc.Hex.parse(ciphertext),
    },
    keyHex,
    {
      iv: ivHex,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  return decrypted.toString(CryptoJS.enc.Utf8);
}

export function getImg(imgFile,quality=0.92) {
  return new Promise((resolve) => {
    const file = imgFile.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      const image = new Image(); // 新建一个img标签（还没嵌入DOM节点)
      image.src = e.target.result;
      image.onload = function () {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const imageWidth = image.width / 2;
        const imageHeight = image.height / 2;
        canvas.width = imageWidth;
        canvas.height = imageHeight;
        context.drawImage(image, 0, 0, imageWidth, imageHeight);
        const data = canvas.toDataURL('image/jpeg',quality);
        resolve({ data, file });
      };
    };
  });
}

export function convertBase64UrlToBlob(urlData) {
  const bytes = window.atob(urlData.split(',')[1]); // 去掉url的头，并转换为byte

  // 处理异常,将ascii码小于0的转换为大于0
  const ab = new ArrayBuffer(bytes.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i);
  }

  return new Blob([ab], {
    type: 'image/jpg',
  });
}
