import { MOCK_API } from "./config";
import Cookies from "js-cookie";
import { history } from "@/utils/common";

/**
 * @description http通用工具函数
 * @author 卿光扬 angus_qing168@163.com
 */
import axios from "axios";
import { Toast } from "antd-mobile";
import { EventDispatcher, NetState } from "@utils";
// axios.defaults.timeout = 12000;
axios.defaults.baseURL = MOCK_API;
axios.interceptors.request.use(
  (config: any) => {
    try {
      EventDispatcher.TriggerGlobalLoading(true);
    } catch (err) {
      console.error(err);
    }
    const token = Cookies.get("user_token");
    if (token) config.headers["Authorization"] = token;
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
axios.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      switch (response.data.code) {
        case 51103:
          break;
        default:
          break;
      }
    }

    return response;
  },
  (error) => {    
    if (error) {
      if (error.code && error.code === "ECONNABORTED") {
        return Promise.reject(error);
      }
      if (error.code && error.code === "ERR_NETWORK") {
        Toast.show({ icon: "fail", content: '网络连接出现问题!' });
        return Promise.reject(error);
      }
      const {
        response: {
          status,
          data: { statusCode = 400, message = "服务器发生错误" },
        },
      } = error;

      if (
        status === NetState.Unthorization ||
        statusCode === NetState.Unthorization
      ) {
        Toast.show({ icon: "fail", content: "请先登录!" });
        const pathname = history.location.pathname;
        if (!/login$/.test(pathname)) {
          Cookies.set(
            "last_page",
            history.location.pathname + history.location.search
          );
          Cookies.set(
            "last_page_state",
            JSON.stringify(history.location.state)
          );
        }

        setTimeout(() => {
          history.replace(process.env.REACT_APP_PREFIX + "/login");
        }, 500);
        return Promise.reject(error);
      } else {
        Toast.show({ icon: "fail", content: message });
        return Promise.resolve(error);
      }
    }
  }
);

interface IFRequestParam {
  url: string;
  msg?: string;
  config?: any;
  data?: any;
}

/**
 * 公用get请求
 * @param url       接口地址
 * @param msg       接口异常提示
 * @param headers   接口所需header配置
 */
export const get = ({ url, msg = "接口异常", config }: IFRequestParam) => {
  return axios
    .get(url, config)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response && err.response.data.code === NetState.Unthorization) {
        // 无授权登入，获取信息
        if (process.env.REACT_APP_Version === "ShenZhenNoLogin") return;
        Toast.show({ icon: "fail", content: "请先登录!" });
        setTimeout(() => {
          history.replace(process.env.REACT_APP_PREFIX + "/login");
        }, 500);
      } else if (!err.response || !err.response.data) {
        Toast.show({ icon: "fail", content: `API Server ${err.toString()}` });
        return;
      }
    });
};

export const getSilence = ({
  url,
  msg = "接口异常",
  config,
}: IFRequestParam) => {
  return axios
    .get(url, config)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response && err.response.data.code === NetState.Unthorization) {
        Toast.show({ icon: "fail", content: "请先登录!" });
        setTimeout(() => {
          history.replace(process.env.REACT_APP_PREFIX + "/login");
        }, 500);
      } else if (!err.response || !err.response.data) {
        Toast.show({ icon: "fail", content: `API Server ${err.toString()}` });
        return;
      }
    });
};

/**
 * 公用post请求
 * @param url       接口地址
 * @param data      接口参数
 * @param msg       接口异常提示
 * @param headers   接口所需header配置
 */
export const post = ({
  url,
  data,
  msg = "接口异常",
  config,
}: IFRequestParam) => {
  return axios
    .post(url, data, config)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response && err.response.data.code === NetState.Unthorization) {
        Toast.show({ icon: "fail", content: "请先登录!" });
        setTimeout(() => {
          history.replace(process.env.REACT_APP_PREFIX + "/login");
        }, 500);
      } else if (!err.response || !err.response.data) {
        Toast.show({ icon: "fail", content: `API Server ${err.toString()}` });
        return;
      }
    });
};

/**
 * 公用post请求
 * @param url       接口地址
 * @param data      接口参数
 * @param msg       接口异常提示
 * @param headers   接口所需header配置
 */
export const postSilence = ({
  url,
  data,
  msg = "接口异常",
  config,
}: IFRequestParam) => {
  return axios
    .post(url, data, config)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response && err.response.data.code === NetState.Unthorization) {
        Toast.show({ icon: "fail", content: "请先登录!" });
        setTimeout(() => {
          history.replace(process.env.REACT_APP_PREFIX + "/login");
        }, 500);
      } else if (!err.response || !err.response.data) {
        Toast.show({ icon: "fail", content: `API Server ${err.toString()}` });
        return;
      }
    });
};

/**
 * 公用put请求
 * @param url
 * @param data
 * @param msg
 * @param config
 */
export const put = ({
  url,
  data,
  msg = "接口异常",
  config,
}: IFRequestParam) => {
  return axios
    .put(url, data, config)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response && err.response.data.code === NetState.Unthorization) {
        Toast.show({ icon: "fail", content: "请先登录!" });
        setTimeout(() => {
          history.replace(process.env.REACT_APP_PREFIX + "/login");
        }, 500);
      } else if (!err.response || !err.response.data) {
        Toast.show({ icon: "fail", content: `API Server ${err.toString()}` });
        return;
      }
    });
};

/**
 * 公用delete请求
 * @param url
 * @param msg
 * @param config
 */
export const del = ({ url, config, msg = "接口异常" }: IFRequestParam) => {
  return axios
    .delete(url, config)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response && err.response.data.code === NetState.Unthorization) {
        Toast.show({ icon: "fail", content: "请先登录!" });
        setTimeout(() => {
          history.replace(process.env.REACT_APP_PREFIX + "/login");
        }, 500);
      } else if (!err.response || !err.response.data) {
        Toast.show({ icon: "fail", content: `API Server ${err.toString()}` });
        return;
      }
    });
};

/**
 *
 * @param file
 * @param name
 */
export const upload = ({
  url,
  config,
  data,
  msg = "接口异常",
}: IFRequestParam) => {
  return axios
    .post(url, data, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return;
    });
};
