import wx from "weixin-js-sdk-ts";
import { authorizationWeChatQuery } from "../axios/wxAPI";

let locationHref: string = "";

if (window.location.href) {
  locationHref = window.location.href.split("#")[0];
}

function getUrlValue(url: string, key: string) {
  try {
    const query = url.split("?");
    const vars = query[1].split("&");
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=");
      if (pair[0] === key) {
        return pair[1];
      }
    }
  } catch (e) {
    console.error(e);
  }

  return false;
}
// 注释--微信
/**
 * 断言是否是微信
 * @return {boolean}
 */
function assertWeChat() {
  // 首先判断是否使用微信内，因为微信JS-SDK只有在微信环境下才有用
  const environmental = window.navigator.userAgent.toLowerCase();
  return environmental.match(/MicroMessenger/i)?.[0] === "micromessenger";
}

// 获取配置用 url
function getSignUrl() {
  const ua = navigator.userAgent.toLowerCase();
  if (/iphone|ipad|ipod/.test(ua)) {
    if (locationHref) return locationHref;
    else return window.location.href.split("#")[0];
  } else {
    return window.location.href.split("#")[0];
  }
}
// 注释--微信
/**
 * 微信授权
 */
export function authorizationWeChat(
  url: string,
  jsApiList: wx.jsApiList = ["scanQRCode"]
) {
  const isWeChat = assertWeChat();
  // 非微信内部浏览时，不进行请求
  if (!isWeChat) return Promise.reject('请在微信中访问本平台');

  return new Promise<boolean>((resolve) => {
    authorizationWeChatQuery(url).then((res) => {
      const data = res.value;
      // 注入权限验证配置
      wx.config({
        // debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名
        jsApiList, // 必填，调用微信扫一扫接口
        openTagList: [],
      });
      wx.ready(() => {
        resolve(true);
        console.log("wx.ready");
      });
      wx.error((res) => {
        resolve(false);
        console.log("***** wx.error *******");
        console.log('报错', res);
      });
    });
  });
}

/**
 * 扫描
 * @return {Promise<string>}
 */
export function scan(): Promise<string> {
  // 无授权登入，获取信息
  // if (process.env.REACT_APP_Version !== "ShenZhenNoLogin") {
  if (['ShenZhenYouLian'].includes(process.env.REACT_APP_Version)) {
    console.log('扫码测试12', window, (window as any)['em']);
    return new Promise(function (resolve, reject) {
      (window as any)['em'].ready(() => {
        console.log('扫码测试1-0',(window as any)['em'].checkJsApi("closeWindow"), (window as any)['em'].checkJsApi("objCallBack"), (window as any)['em']);
        // 执行接口调用

        if ((window as any)['em'].checkJsApi("scanQRCode")) {
          (window as any)['em'].scanQRCode({
            scanType: ["qrCode", "barCode"],
            needResult: 1,
            success: (res: any) => {
              console.log('扫码测试2', res);
              if (res.resultStr) {
                const url = res.resultStr;
                const deviceId = getUrlValue(url, "id");
                if (deviceId) {
                  resolve(deviceId);
                } else {
                  console.log("***** deviceId is undefind *******");
                  reject("请扫描正确的二维码！");
                }
              } else {
                console.log("***** res.resultStr is undefind *******");
                reject("扫码异常！");
              }
            },
            // closeWindow:() => {
            //   console.log("***** 用户取消扫码 *******");
              
            //   reject("用户取消扫码");
            // },
            cancel:() => {
              console.log("***** 用户取消扫码 *******");
              
              reject("用户取消扫码");
            },
            fail:() => {
              reject("调用扫码失败");
            },
          });
        }
        // if ((window as any)['em'].checkJsApi("closeWindow")) {
        //   (window as any)['em'].closeWindow({
        //     success: (res: any) => {
        //       console.log('扫码测试2', res);
        //       if (res.resultStr) {
        //         const url = res.resultStr;
        //         const deviceId = getUrlValue(url, "id");
        //         if (deviceId) {
        //           resolve(deviceId);
        //         } else {
        //           console.log("***** deviceId is undefind *******");
        //           reject("请扫描正确的二维码！");
        //         }
        //       } else {
        //         console.log("***** res.resultStr is undefind *******");
        //         reject("扫码异常！");
        //       }
        //     },
        //     cancel:() => {
        //       console.log("***** 用户取消扫码2 *******");
              
        //       reject("用户取消扫码");
        //     },
        //     fail:() => {
        //       console.log("***** 用户取消扫码3 *******");
        //       reject("调用扫码失败");
        //     },
        //   });
        // }
      });

    });
  } else if (['ShenZhenNoLogin', 'ShenZhenChuanBo', 'ZhouShanYouLian'].includes(process.env.REACT_APP_Version)) {

    // 注释--微信
    return authorizationWeChat(getSignUrl()).then((result) => {
      // 首先判断是否使用微信内，因为微信JS-SDK只有在微信环境下才有用
      // 获取本地跳转地址
      if (!result) return Promise.reject("配置异常！");
      return new Promise(function (resolve, reject) {
        wx.scanQRCode({
          needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
          success: (res) => {
            if (res.resultStr) {
              const url = res.resultStr;
              const deviceId = getUrlValue(url, "id");
              if (deviceId) {
                resolve(deviceId);
              } else {
                console.log("***** deviceId is undefind *******");
                reject("请扫描正确的二维码！");
              }
            } else {
              console.log("***** res.resultStr is undefind *******");
              reject("扫码异常！");
            }
          },
          cancel: () => {
            reject("用户取消扫码");
          },
          fail: () => {
            reject("调用扫码失败");
          },
        });
      });
    });
  }
  else if (['测试'].includes(process.env.REACT_APP_Version)) {
    //ZhouShanYouLian
    console.log('扫码测试12招商', window, (window as any)['waltz']);
    return new Promise(function (resolve, reject) {
      // (window as any)['em'].ready(() => {
      //   console.log('扫码测试1-0', (window as any)['em'].checkJsApi("scanQRCode"), (window as any)['em']);
      //   // 执行接口调用

      //   if ((window as any)['em'].checkJsApi("scanQRCode")) {
      //     (window as any)['em'].scanQRCode({
      //       scanType: ["qrCode", "barCode"],
      //       needResult: 1,
      //       success: (res: any) => {
      //         console.log('扫码测试2', res);
      //         if (res.resultStr) {
      //           const url = res.resultStr;
      //           const deviceId = getUrlValue(url, "id");
      //           if (deviceId) {
      //             resolve(deviceId);
      //           } else {
      //             console.log("***** deviceId is undefind *******");
      //             reject("请扫描正确的二维码！");
      //           }
      //         } else {
      //           console.log("***** res.resultStr is undefind *******");
      //           reject("扫码异常！");
      //         }
      //       }
      //     });
      //   }
      // });
      (window as any).waltz.call({ // 或者通过 window.waltz.call
        // callback: "263366293",             // 自定义ID，标识一次调用，可选
        module: 'WZScanner',                             // 模块名，对应下表的Module
        handlerName: 'scan',     // 方法名，对应下表的HandlerName
        data: { canOpenAlbum: true }                                            // 方法入参，json格式
      }).then((data: any) => {
        console.log('招商随行data', data);
        // 这里的data相当于responseData
        if (data.code === 10000) {
          // 成功回调的业务代码
          // let resultObject = data.body // 接口返回的业务实参对象
          // console.log(resultObject)
          // let pageParam = resultObject.param // 获取WZApi.getPageParam返回实参中的指定Key对应的value
          // console.log(pageParam)
        }
      })
    });
  } else {
    //预留
    return Promise.reject("请先配置授权");
  }
}

export const getWXCode = () => {
  let se = window.location.search;

  se = se.substring(1);
  const arr = se.split("&");
  console.log("getWXCode window.location.search:", window.location.search, se, arr, window.location.href);

  for (let index = 0; index < arr.length; index++) {
    const element = arr[index].split("=");
    if (
      element.length === 2 &&
      element[0] === "code" &&
      element[1] &&
      typeof element[1] === "string"
    ) {
      console.log("element[1]", element[1]);

      return Promise.resolve(element[1]);
    }
  }
  // http://zcsys.esdgd.com/wxapi/jsconfig?v=1731982783140&localurl=http://zcsys.esdgd.com/
  // https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxfd86458d61ce7e26&redirect_uri=http://zcsys.esdgd.com/&response_type=code&scope=snsapi_base&state=wxhuidiao#wechat_redirect
  // https://api.weixin.qq.com/sns/oauth2/access_token?appid=wxfd86458d61ce7e26&secret=31f40354189cba8913c8d9e4e688978d&code=001w6e000qTIcT1tKz300ewqRq4w6e0b&grant_type=authorization_code
  // 拿appid
  return authorizationWeChatQuery(window.location.href).then((res) => {
    const data = res?.value?.appId;
    if (res?.statusCode === 200 && data) {
      const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${data}&redirect_uri=${encodeURIComponent(
        window.location.href
      )}&response_type=code&scope=snsapi_base&state=wxhuidiao#wechat_redirect`;
      console.log("url", url);

      window.location.replace(url);
    }
    return "";
  }).catch((e) => {
    console.error(e);
  });
};

// 改用input
// /**
//  * 微信相机
//  * @return {Promise<unknown>}
//  */
// export function camera() {
//   const isWeChat = assertWeChat();
//   return new Promise((resolve, reject) => {
//     // 首先判断是否使用微信内，因为微信JS-SDK只有在微信环境下才有用0
//     if (isWeChat) {
//       wx.ready(() => {
//         wx.chooseImage({
//           count: 1, // 默认9
//           sourceType: ["camera", "previewImage", "uploadImage", "uploadFile"],
//           success(res) {
//             // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
//             const localIds = res.localIds[0].toString();
//             console.log("相机2", localIds);
//             setTimeout(() => {
//               wx.getLocalImgData({
//                 localId: localIds,
//                 success(response) {
//                   const localData = response.localData;
//                   console.log("localData", localData);
//                   let imageBase64 = "";
//                   if (localData.indexOf("data:image") === 0) {
//                     // 苹果的直接赋值，默认生成'data:image/jpeg;base64,'的头部拼接
//                     imageBase64 = localData;
//                     console.log("ios ===> localData", imageBase64);
//                   } else {
//                     // 此处是安卓中的唯一得坑！在拼接前需要对localData进行换行符的全局替换
//                     // 此时一个正常的base64图片路径就完美生成赋值到img的src中了
//                     imageBase64 = `data:image/jpeg;base64,${localData.replace(
//                       /\n/g,
//                       ""
//                     )}`;
//                     console.log("android ===> localData", imageBase64);
//                   }
//                   const result = {
//                     base64: imageBase64,
//                     clearBase64: imageBase64.substr(
//                       imageBase64.indexOf(",") + 1
//                     ),
//                   };
//                   console.log("result", result);
//                   console.log(
//                     "convertBase64UrlToBlob(result.base64)",
//                     convertBase64UrlToBlob(result.base64)
//                   );
//                   // resolve({ src: localIds, localIds: res.localIds, img: convertBase64UrlToBlob(result.base64) });
//                   const u = navigator.userAgent;
//                   const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
//                   // ios时，把base64显示在图片上
//                   if (isIOS) {
//                     resolve({
//                       src: imageBase64,
//                       localIds: imageBase64,
//                       img: convertBase64UrlToBlob(result.base64),
//                     });
//                   } else {
//                     resolve({
//                       src: localIds,
//                       localIds: res.localIds,
//                       img: convertBase64UrlToBlob(result.base64),
//                     });
//                   }
//                 },
//               });
//             }, 100);
//           },
//         });
//       });
//     } else {
//       alert("请在微信中登录！");
//       reject("请在微信中登录！");
//     }
//   });
// }
// /**
//  * 微信图片预览
//  * @return {Promise<unknown>}
//  */
// export function previewImage(src, localIds) {
//   const isWeChat = assertWeChat();
//   // 首先判断是否使用微信内，因为微信JS-SDK只有在微信环境下才有用
//   if (isWeChat) {
//     wx.previewImage({
//       current: src, // 当前显示图片的http链接
//       urls: localIds, // 需要预览的图片http链接列表
//     });
//   } else {
//     alert("请在微信中登录！");
//   }
// }
