import { map } from 'lodash';
import { Input, Radio, TextArea } from 'antd-mobile';
import TableForForm from '@/components/from/tableForForm';

export const getFormInputDom = (data: Form.FormItem<any>) => {
  if(!data.option) return;
  const { key, title, option: { type, showPlaceholder = false, datas, readonly = true, placeholderText }, event,rows } = data;
  switch(type) {
    case 'input':
      return <Input readOnly={readonly} { ...event } placeholder={showPlaceholder ? (placeholderText || `请输入${title}`) : ''} />
    case 'inputNumber':
      return <Input readOnly={readonly} { ...event } placeholder={showPlaceholder ? (placeholderText || `请输入${title}`) : ''} type="number" />
    case 'radio':
      return <Radio.Group disabled={readonly} { ...event }>
        { map(datas, (item, i) => <Radio key={`${key}-Radio-${i}`} value={item.value} disabled={item?.disabled} block>{ item.label }</Radio>) }
      </Radio.Group>
    case 'picker':
    case 'datePicker':
      return <Input className='pickerInput' clearable readOnly={readonly} placeholder={showPlaceholder ? (placeholderText || `请选择${title}`) : ''} { ...event } {...rows}/>
    case 'textArea':
      return <TextArea readOnly={readonly} placeholder={showPlaceholder ? (placeholderText || `请输入${title}`) : ''} { ...event } />
    case "tableForForm":
      return (
        <TableForForm
          recordOptions={data.option?.tableForFormConfig?.recordOptions || []}
          {...event}
        />
      );
  }
}