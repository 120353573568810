import React, { ReactNode, useMemo, useRef } from "react";
import { map, isFunction } from 'lodash';
import { Form } from "antd-mobile";
import { getFormInputDom } from '@utils/formDom';
import Tamplate from "@components/from/Tamplate";
import Process from "@components/from/Process";

const HorizontalContact: React.FC = () => {

  const formItems = useRef<Array<Form.FormItem<any>>>([
    { key: '', title: '执行部门', option: { type: 'input' } },
    { key: '', title: '项目名称', option: { type: 'input' } },
    { key: '', title: '工程内容及要求', option: { type: 'input' } },
    { key: '', title: '完工时间', option: { type: 'input' } },
    { key: '', title: '执行部门主管', option: { type: 'input' } },
    { key: '', title: '备注', option: { type: 'input' } },
    { key: '', title: '领导批示', option: { type: 'input' } },
    { key: '', title: '申请人', option: { type: 'input' } },
    { key: '', title: '部门领导', option: { type: 'input' } },
    { key: '', title: '申请时间', option: { type: 'input' } },
  ]);

  const getForm = () => {}
  const getFormF = () => getForm();

  const formItemsDom = useMemo<ReactNode>(() => {
    return map(formItems.current, item => {
      const { title, titleRender } = item;
      return <>
        { title === '申请人' && <div className='form-separated'></div> }
        <Form.Item label={titleRender ? (isFunction(titleRender) ? titleRender() : titleRender) : (title ? `${title}:` : '') }>
          { item.render ? item.render() : getFormInputDom(item) }
        </Form.Item>
      </>
    });
  }, [formItems]);

  return <Tamplate title="维修横向联系单" showHeaderCompany={false}>
    <Form layout="horizontal">
      { formItemsDom }
    </Form>
    
    <Process formId="" onUpdateForm={getFormF} deleteForm={() =>Promise.reject({stateCode: 400})} />
  </Tamplate>
}

export default HorizontalContact;
