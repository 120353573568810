import { useMyLocation } from "@/hooks";
import { GetProjectCostBudget, ProjectCostBudgetNextStep, DeleteProjectCostBudget } from "@axios/form";
import Process from "@components/from/Process";
import Tamplate from "@components/from/Tamplate";
import { NetState } from "@utils";
import { push } from "@utils/common";
import { getFormInputDom ,} from "@utils/formDom";
import { Form, Radio, Space, Toast } from "antd-mobile";
import { isFunction, map } from "lodash";
import type { FC, ReactNode } from "react";
import { useEffect, useMemo, useState } from "react";

const ProjectCostBudget: FC = (props) => {
  const { state: LocationState } = useMyLocation<Form.IRouterLocationState>();
  const [formItems] = useState<Array<Form.FormItem<any>>>([
    { key: "deviceName", title: "设备名称", option: { type: "input" } },
    { key: "deviceNumber", title: "设备编号", option: { type: "input" } },
    { key: "departmentName", title: "受益部门", option: { type: "input" } },
    {
      key: "planTypeEnum",
      title: "",
      render: () => (
        <Radio.Group disabled>
          <Space style={{ "--gap": "2rem", width: "100%" }} justify="center">
            <Radio value={0}>计划内</Radio>
            <Radio value={1}>计划外</Radio>
          </Space>
        </Radio.Group>
      ),
    },
    { key: "maintainCode", title: "维修编号", option: { type: "input" } },
    {
      key: "maintainFinanceNumber",
      title: "维修财务编号",
      option: { type: "input" },
    },
    {
      key: "projectContent",
      title: "项目内容描述",
      option: { type: "textArea" },
    },
    {
      key: "materialCostBudget",
      title: "材料成本预算(元)",
      titleRender: (
        <>
          材料成本
          <br />
          预算(元)
        </>
      ),
      option: { type: "inputNumber" },
    },
    {
      key: "cloutUseCost",
      title: "其余余料利用成本(元)",
      titleRender: (
        <>
          其余余料利
          <br />
          用成本(元)
        </>
      ),
      option: { type: "inputNumber" },
    },
    {
      key: "artificialCostBudget",
      title: "人工成本预算(元)",
      titleRender: (
        <>
          人工成本
          <br />
          预算(元)
        </>
      ),
      option: { type: "inputNumber" },
    },
    {
      key: "ourFactoryArtificialCost",
      title: "其中本厂人工成本(元)",
      titleRender: (
        <>
          其中本厂人
          <br />
          工成本(元)
        </>
      ),
      option: { type: "inputNumber" },
    },
    {
      key: "descriptions",
      title: "其他成本预算(元)",
      titleRender: (
        <>
          其他成本
          <br />
          预算(元)
        </>
      ),
      option: { type: "inputNumber" },
    },
    {
      key: "projectCostTotal",
      title: "项目成本合计(元)",
      titleRender: (
        <>
          项目成本
          <br />
          合计(元)
        </>
      ),
      option: { type: "inputNumber" },
    },
    {
      key: "seeMaterialCostBudget",
      title: "参考材料成本(元)",
      titleRender: (
        <>
          参考材料
          <br />
          成本(元)
        </>
      ),
      option: { type: "inputNumber" },
    },
    {
      key: "seeArtificialCostBudget",
      title: "参考人工成本(元)",
      titleRender: (
        <>
          参考人工
          <br />
          成本(元)
        </>
      ),
      option: { type: "inputNumber" },
    },
    { key: "msName", title: "设备部主管", option: { type: "input" } },
    { key: "sbbJlMc", title: "设备部经理", option: { type: "input" } },
    { key: "thoedName", title: "分管领导", option: { type: "input" } },
    { key: "cfoName", title: "财务总监", option: { type: "input" } },
    { key: "ceoName", title: "总经理", option: { type: "input" } },
    {
      key: "materialCostAccount",
      title: "材料成本预算(元)",
      titleRender: (
        <>
          材料成本
          <br />
          预算(元)
        </>
      ),
      option: { type: "inputNumber" },
    },
    {
      key: "artificialCostAccount",
      title: "人工成本核算(元)",
      titleRender: (
        <>
          人工成本
          <br />
          核算(元)
        </>
      ),
      option: { type: "inputNumber" },
    },
    {
      key: "elseCostAccount",
      title: "其他成本核算(元)",
      titleRender: (
        <>
          其他成本
          <br />
          核算(元)
        </>
      ),
      option: { type: "inputNumber" },
    },
    {
      key: "projectCostTotalAccount",
      title: "项目总成本合计(元)",
      titleRender: (
        <>
          项目总成本
          <br />
          合计(元)
        </>
      ),
      option: { type: "inputNumber" },
    },
    {
      key: "remark",
      title: "备注",
      render: () => (
        <p style={{ textAlign: "left", color: "#adadad" }}>
          1、送审时，并根据项目实际情况附《生产设备维修申请单》、《维修备件审批表》等表格。
          <br />
          <br />
          2、项目预算总成本（不含本厂工人人工成本和余料利用材料成本）低于5万元的，由设备部经理审批，超过5万元（含5万元）及低于10万元的交主管副总审批，高于10万元（含10万元）交财务总监和总经理审批。
          <br />
          <br />
          3、如属外协、项目总包、项目人工总包，需提供《外协工程审批表》、《项目总包协议/合同》、《项目人工总包/合同》。
          <br />
          <br />
          4、如项目需要招标的，要提供完整的招标文件和合同。
          <br />
          <br />
          5、如属计划外维修，设备所属部门要提交报告，交总经理室审批。
          <br />
          <br />
          6、项目完工验收后，由设备部统计主管填写预算执行情况。
          <br />
          <br />
          7、本表由设备管理部责任主管归档。
        </p>
      ),
    },
  ]);
  const [formData, setFormData] =
    useState<ProjectCostBudget.ProjectCostBudgetData | null>(null);
  const [formDataLoading, setFormDataLoading] = useState(false);
  const [formId, setFormId] = useState("");
  const [formRef] = Form.useForm();

  useEffect(() => {
    if (LocationState?.formId) {
      setFormId(LocationState.formId);
      getForm(LocationState.formId);
    } else {
      Toast.show("跳转表单出错");
      setTimeout(() => {
        push("/home");
      }, 500);
    }
  }, [formId]);
  useEffect(() => {
    if (formData) formRef.resetFields();
  }, [formData, formRef]);

  const getForm = (formId: string) => {
    setFormDataLoading(true);
    GetProjectCostBudget(formId)
      .then((res: IRes<ProjectCostBudget.ProjectCostBudgetData>) => {
        if (res?.statusCode !== NetState.Ok)
          return Toast.show({
            icon: "fail",
            content: res?.message || "获取表单数据失败",
          });
        setFormDataLoading(false);
        setFormData(res.value);
      })
      .catch(() => {
        Toast.show("获取表单内容出错");
        setTimeout(() => {
          push("/home");
        }, 500);
      });
  };
  const getFormF = () => getForm(formId);

  /** 表单内容 */
  const formItemsDom = useMemo<ReactNode>(() => {
    return map(formItems, (item, _i) => {
      const { title, titleRender, render } = item;
      return (
        <div key={item.key}>
          {title === "总经理" && (
            <div className="form-separated-text">预算执行情况</div>
          )}
          <Form.Item
            name={item.key}
            label={
              titleRender
                ? isFunction(titleRender)
                  ? titleRender()
                  : titleRender
                : title
                ? `${title}:`
                : ""
            }
          >
            {render ? render() : getFormInputDom(item)}
          </Form.Item>
        </div>
      );
    });
  }, [formItems]);
  return (
    <Tamplate
      title="成本预算审批单"
      companyId={formData?.companyId}
      formDataLoading={formDataLoading}
    >
      <Form
        form={formRef}
        layout="horizontal"
        initialValues={formData ? formData : {}}
      >
        {formItemsDom}
      </Form>

      {formId && (
        <Process
          formId={formId}
          auditCallback={ProjectCostBudgetNextStep}
          onUpdateForm={getFormF}
          deleteForm={DeleteProjectCostBudget}
        />
      )}
    </Tamplate>
  );
};

export default ProjectCostBudget;
