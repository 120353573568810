import React, { FC, useContext, useEffect, useRef, useState } from "react";

interface ITableForFormProps<TValue = any> {
  value?: TValue[];
  onChange?: (newValue: TValue[]) => void;
  recordOptions: NWarehousing.IRecordOptions<TValue>[];
  rowKey? : string;
}
const TableForForm: FC<ITableForFormProps> = (props) => {
  const { value = [], recordOptions, rowKey = 'id' } = props;
  return (
    <div className="box-base">
      {value.map((item, index) => {
        return (
          <div className="box" key={item[rowKey]}>
            <div className="box-title">
              <span>序号：</span>
              <span>{index + 1}</span>
            </div>
            {recordOptions.map((option) => {
              return (
                <div className="boxRow" key={option.dataIndex}>
                  <div className="itemName">{option.label}:</div>
                  <div className="itemValue">
                    {option.render
                      ? option.render(item,option)
                      : item[option.dataIndex as keyof typeof item]}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default TableForForm;
