import { NetState } from "@/utils";
import {
  GetUpkeepCheckApplicationsDto,
  UpkeepApplicationNextStep,
  DeleteUpkeepCheckApplication,
} from "@axios/form";
import Process from "@components/from/Process";
import Tamplate from "@components/from/Tamplate";
import { useMyLocation } from "@hooks";
import { push } from "@utils/common";
import { getFormInputDom } from "@utils/formDom";
import { Form, Radio, Space, Toast } from "antd-mobile";
import { find, isFunction } from "lodash";
import { MobXProviderContext } from "mobx-react";
import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

const UpkeepCheck: React.FC = () => {
  const { state: LocationState } = useMyLocation<Form.IRouterLocationState>();
  const [formId, setFormId] = useState("");
  const RootStore = useContext(MobXProviderContext);
  const departments = useRef<Array<Department>>(
    RootStore.GlobalStore.allDepartments
  );
  const [formData, setFormData] = useState<UpkeepCheck.IUpkeepCheckData>({});
  const formItems = useRef<Array<Form.FormItem<UpkeepCheck.IUpkeepCheckData>>>([
    { key: "deviceName", title: "设备名称", option: { type: "input" } },
    { key: "deviceNumber", title: "设备编号", option: { type: "input" } },
    {
      key: "departmentName",
      title: "使用部门",
      render: (record) => {
        const d = find(
          departments.current,
          (dep) => dep.key === record?.departmentId
        );
        return <span>{d?.title}</span>;
      },
    },
    {
      key: "isUpkeepContentFinish",
      title: "二级保养内容是否完成",
      render: () => (
        <Radio.Group disabled>
          <Space style={{ "--gap": "1rem" }}>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
          </Space>
        </Radio.Group>
      ),
    },
    {
      key: "noUpkeepContentFinish",
      title: "二级保养内容未完成原因",
      option: { type: "textArea" },
    },
    {
      key: "treatmentMeasure",
      title: "处理措施、意见",
      option: { type: "input" },
    },
    { key: "miwName", title: "保养实施车间确认", option: { type: "input" } },
    { key: "sqrName", title: "申请人", option: { type: "input" } },
    { key: "rsName", title: "车间设备主管确认", option: { type: "input" } },
    { key: "soemdName", title: "设备部主管", option: { type: "input" } },
    { key: "fmmName", title: "设备部经理", option: { type: "input" } },
  ]);
  const [formDataLoading, setFormDataLoading] = useState(false);
  const [formRef] = Form.useForm();

  useEffect(() => {
    if (LocationState?.formId) {
      setFormId(LocationState.formId);
      getForm(LocationState.formId);
    } else {
      Toast.show("跳转表单出错");
      setTimeout(() => {
        push("/home");
      }, 500);
    }
  }, []);

  const getForm = (formId: string) => {
    setFormDataLoading(true);
    GetUpkeepCheckApplicationsDto(formId)
      .then((res: IRes<UpkeepCheck.IUpkeepCheckData>) => {
        if (res?.statusCode !== NetState.Ok) return Promise.reject();
        const { isUpkeepContentFinish } = res.value;
        res.value.isUpkeepContentFinish =
          isUpkeepContentFinish === true ? 1 : 0;
        setFormData(res.value);
        setFormDataLoading(false);
      })
      .catch(() => {
        Toast.show("获取表单内容出错");
        setTimeout(() => {
          push("/home");
        }, 500);
      });
  };
  const getFormF = () => getForm(formId);

  useEffect(() => {
    if (formData.id) formRef.resetFields();
  }, [formData, formRef]);

  /** 表单主体 */
  const formItemsDom = useMemo<ReactNode>(() => {
    const items: Array<ReactNode> = [];
    formItems.current.forEach((item) => {
      const { key, title, titleRender } = item;
      items.push(
        <Form.Item
          key={`form-item-${key}`}
          name={key}
          label={
            titleRender
              ? isFunction(titleRender)
                ? titleRender()
                : titleRender
              : title
              ? `${title}:`
              : ""
          }
        >
          {item.render ? item.render() : getFormInputDom(item)}
        </Form.Item>
      );
    });
    return items;
  }, [formItems]);
  return (
    <Tamplate
      title="保养检查申请表"
      showHeaderCompany={false}
      formDataLoading={formDataLoading}
    >
      <Form form={formRef} layout="horizontal" initialValues={formData}>
        {formItemsDom}
      </Form>

      {formId && (
        <Process
          formId={formId}
          auditCallback={UpkeepApplicationNextStep}
          onUpdateForm={getFormF}
          deleteForm={DeleteUpkeepCheckApplication}
        />
      )}
    </Tamplate>
  );
};

export default UpkeepCheck;
