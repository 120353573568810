import {
  useState,
  useEffect,
  useImperativeHandle,
  Ref,
  forwardRef,
} from "react";
import style from "./index.module.less";

const Table = (props: tableType.TableData, ref: Ref<unknown> | undefined) => {
  //选择的行数据
  const [selectNodes, setSelectNodes] = useState<any[]>([]);
  //选择的行数据key对象(用于判断)
  const [selectObj, setSelectObj] = useState<any>({});
  const [allChecked, setAllChecked] = useState(false);
  const {
    columns,
    rowKey,
    dataSource,
    rowSelection,
    className,
    overflow,
    onSelection,
    currentModule,
  } = props;

  useEffect(() => {
    if (rowSelection && onSelection) onSelection(selectNodes);
  }, [selectNodes, rowSelection, onSelection]);

  /**
   * 全选/取消全选
   * @param checked 判断是选择还是取选
   */
  const selectAll = (checked: boolean) => {
    if (checked && dataSource?.length) {
      setAllChecked(true);
      let newSelectNodes: any[] = dataSource;
      let newSelectObj: any = {};
      newSelectNodes.forEach((item, i) => {
        if (rowKey) {
          newSelectObj[item[rowKey]] = true;
        } else {
          newSelectObj[i] = true;
          item.index = i;
        }
      });
      setSelectNodes(newSelectNodes);
      setSelectObj(newSelectObj);
    } else {
      setAllChecked(false);
      setSelectNodes([]);
      setSelectObj({});
    }
  };

  useImperativeHandle(ref, () => ({
    cleanSelect: () => {
      setSelectNodes([]);
      setSelectObj({});
      setAllChecked(false);
    },
  }));

  /**
   * 选择/取选
   * @param d 行数据
   * @param identification 标识key
   * @param checked 判断是选择还是取选
   */
  const changeSelect = (d: any, identification: any, checked: boolean) => {
    if (checked) {
      selectObj[identification] = true;
      const newSelectNodes = [...selectNodes, d];
      setSelectNodes(newSelectNodes);
      setSelectObj(selectObj);
      if (dataSource?.length && newSelectNodes.length >= dataSource.length)
        setAllChecked(true);
    } else {
      delete selectObj[identification];
      if (allChecked) setAllChecked(false);
      if (rowKey) {
        setSelectNodes(
          selectNodes.filter((item) => item[rowKey] !== identification)
        );
      } else {
        delete selectNodes[identification];
        setSelectNodes(selectNodes);
      }
      setSelectObj(selectObj);
    }
  };

  return (
    <div
      className={`${className && className} ${
        overflow && style.overflowTable
      } ${style.table}`}
      style={props.style}
    >
      <table cellSpacing="0">
        <thead>
          <tr>
            {rowSelection && (
              <th className={style.checkbox} style={{ width: 20 }}>
                <label>
                  <input
                    type="checkbox"
                    checked={allChecked}
                    onChange={(e) => selectAll(e.target.checked)}
                    disabled={!dataSource || dataSource?.length < 1}
                  />
                  <span />
                </label>
              </th>
            )}
            {columns.map((c, i) => (
              <th key={i} style={{ width: c?.width || "auto" }}>
                {c.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataSource?.map((d, i) => {
            const identification = rowKey ? d[rowKey] : i;
            return (
              <tr key={identification}>
                {rowSelection && (
                  <td className={style.checkbox}>
                    <label>
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          changeSelect(d, identification, e.target.checked)
                        }
                        checked={
                          selectObj[identification]
                            ? selectObj[identification]
                            : false
                        }
                      />
                      <span />
                    </label>
                  </td>
                )}
                {columns.map((c, ii) => {
                  let text;
                  if (c.render) {
                    text = c.render(d, i);
                  } else text = c.dataIndex ? d[c.dataIndex] : "";
                  return (
                    <td
                      key={identification + "_" + ii}
                      className={
                        currentModule === "pointCheckContent" &&d.hasOwnProperty('isNormal')&&!d["isNormal"]
                          ? style.tableWarn
                          : ""
                      }
                    >
                      {text}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default forwardRef(Table);
