import { makeAutoObservable } from "mobx";
import { includes } from "lodash";
import { Toast } from "antd-mobile";
import { GetRepairBudgetType } from "@axios/shipForm";
import { RefreshToken, Signout, GetDepartments, GetCompanyInfo } from "@axios";
import { NetState } from "@/utils";
import { push } from "@utils/common";
import Cookies from "js-cookie";

interface IUser {
  appId: string;
  appKey: string;
  token: {
    authToken: string;
    expiration: number;
    refreshToken: string;
    tokenType: string;
  };
  user: {
    isShipUser?: boolean;
    accountCode: string;
    companyCode: string;
    companyId: string;
    companyName: string;
    email: string;
    headPortrait: string;
    id: string;
    name: string;
    password: string;
    remark: string;
    status: string;
    telephone: string;
    userRoles: { roleCode: string; roleId: string; roleName: string }[];
    userFlowPermission: any;
    departmentUsers: {
      departmentCode: string;
      departmentId: string;
      departmentName: string;
    }[];
  };
}

export interface IGlobalStore {
  user?: IUser;
  companys: ICompanyInfo[];
  loginCallback(user: IUser): void;
  allDepartments: Department[];
  maintenanceTypeEnum?: MaintenanceTypeEnum
  defaultRefreshDate: number;
  permissions: {
    isAdmin: boolean;
    versionCode: string;
  };
}

class GlobalStore implements IGlobalStore {
  constructor() {
    makeAutoObservable(this);

    /** token 刷新控制
     * 无用户信息 则跳转登录
     * 有用户信息 没有刷新时间 则跳转登录
     * 有用户信息 超出刷新时间 则刷新token 设置刷新任务
     * 有用户信息 没有超出刷新时间 则设置刷新任务
     */

    // 无授权登入，获取信息
    if (process.env.REACT_APP_Version === "ShenZhenNoLogin") return;
    const user_localStorage = localStorage.getItem("user");
    if (user_localStorage) {
      const user = JSON.parse(user_localStorage);

      const lastRefreshTime = localStorage.getItem("lastRefreshTime");
      if (lastRefreshTime) {
        const temp = Number.parseInt(lastRefreshTime);
        if (Number.isFinite(temp)) {
          // 判断是否保持在刷新间隔内
          const time = this.defaultRefreshDate - (Date.now() - temp);
          if (time > 0) {
            this.refreshDate = time;
            this.loginCallback(user);//会刷新，标记一下，需要清除 localStorage.getItem("user")
          } else {
            this.setRefresh(true);
          }
        } else {
          push("/login" + window.location.search);
        }
      } else {
        push("/login" + window.location.search);
      }
    } else {
      push("/login" + window.location.search);
    }
  }
  user?: IUser;
  /** 上次刷新时间 */
  lastRefreshTime = 0;

  /** 刷新定时器 */
  refreshTimeout?: NodeJS.Timer;
  /** 刷新等待时间 设定时间 */
  public readonly defaultRefreshDate = 28 * 60 * 1000;
  /** 下次刷新等待时间 */
  public refreshDate = this.defaultRefreshDate;

  public allDepartments: Department[] = [];
  public maintenanceTypeEnum: MaintenanceTypeEnum = {};

  permissions = {
    // 使用界面的显示由用户所属部门来判断
    isAdmin: false,
    versionCode: "ShenZhenYouLian",
  };

  public companys: ICompanyInfo[] = [];

  loginCallback(user: IUser) {
    if (user) {
      this.user = user;
      this.isPermissionsType(user);
      if (this.allDepartments.length === 0) this.getDepartments();
      if (this.companys.length === 0) this.getCompanys();
      if (Object.keys(this.maintenanceTypeEnum).length === 0) this.getRepairBudgetType();
      localStorage.setItem("lastRefreshTime", Date.now().toString());
      this.refreshDate = this.defaultRefreshDate;
      this.setRefresh();
    }
  }

  /** 设置刷新任务
   * @param ExecuteNow 是否调用后立即执行刷新token
   */
  setRefresh(ExecuteNow: boolean = false) {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = undefined;
    }
    if (ExecuteNow) this.refreshToken();
    this.refreshTimeout = setTimeout(
      this.refreshToken.bind(this),
      this.refreshDate
    );
  }

  async refreshToken() {
    console.log(this.user);
    // 无授权登入，获取信息
    if (process.env.REACT_APP_Version === "ShenZhenNoLogin") return;
    localStorage.setItem("refreshToken", "进入刷新");
    if (this.user) {
      localStorage.setItem("refreshToken", "this.user 存在");
      const res = await RefreshToken({
        userName: this.user.user.accountCode,
        refreshToken: this.user.token.refreshToken,
      });
      if (res?.statusCode === 200 && res?.value) {
        localStorage.setItem("refreshToken", "刷新成功");
        const lastRefreshTime = Date.now();
        this.lastRefreshTime = lastRefreshTime;
        localStorage.setItem("lastRefreshTime", lastRefreshTime.toString());

        const user_token = `${res.value.tokenType} ${res.value.authToken}`;
        Cookies.set("user_token", user_token);

        this.user.token = res.value;
        this.user.token.refreshToken = res.value.refreshToken;
        localStorage.setItem("user", JSON.stringify(this.user));

        if (this.allDepartments.length === 0) this.getDepartments();
        if (Object.keys(this.maintenanceTypeEnum).length === 0) this.getRepairBudgetType();

        this.refreshDate = this.defaultRefreshDate;
        this.setRefresh();
      } else {
        localStorage.setItem("refreshToken", "刷新失败");
        this.user = undefined;
        this.lastRefreshTime = 0;
        localStorage.removeItem("lastRefreshTime");
        localStorage.removeItem("user");
        Cookies.remove("user_token");
        Toast.show("登入过期，请先登录");
        push("/login" + window.location.search);
        this.logoutSilent();
        if (this.refreshTimeout) {
          clearTimeout(this.refreshTimeout);
          this.refreshTimeout = undefined;
        }
      }
    } else {
      localStorage.setItem("refreshToken", "this.user 不存在 code:RT-01");
      if (this.refreshTimeout) {
        clearTimeout(this.refreshTimeout);
        this.refreshTimeout = undefined;
      }
      Cookies.remove("user_token");
      push("/login" + window.location.search);
      Toast.show("请先登录!");
    }
  }

  async logoutSilent() {
    await Signout();
    localStorage.removeItem("user");
    this.user = undefined;
    this.lastRefreshTime = 0;
  }

  async logout() {
    const res = await Signout();
    if (res?.statusCode === 200) {
      Toast.show("退出成功");
      localStorage.removeItem("user");
      this.user = undefined;
      this.lastRefreshTime = 0;
      push("/login" + window.location.search);
    }
  }

  /**
   * 权限-界面判断
   * @param {string} userInfo 用户数据
   * @return {object} 对应 store.permissions
   */
  isPermissionsType(userInfo: any) {
    try {
      // 版本代号
      let versionCode = "ShenZhenYouLian";
      /* const companyCode = userInfo.user.companyCode;
      switch (companyCode) {
        case "0394": // 深圳友联
        case "0400": // 招商重工
          versionCode = "ShenZhenYouLian";
          break;
        // case '0400': // 招商重工
        //   versionCode = 'ZhaoShangZhongGong';
        //   break;
        case "1684": // 舟山友联
          versionCode = "ZhouShanYouLian";
          break;
        default:
          throw new Error("Missing company code");
      } */

      // TODO: 早期开发强制配置
      if (process.env?.REACT_APP_Version_Code) {
        const x = process.env?.REACT_APP_Version_Code;

        if (
          typeof x === "string" &&
          includes(
            Object.keys(EVersionCode),
            process.env.REACT_APP_Version_Code
          )
        ) {
          versionCode = process.env.REACT_APP_Version_Code;
        }
      }
      console.log(`versionCode=${versionCode}`);

      // 判断是否为 管理员
      const isAdmin = userInfo.user.userRoles.some(
        (x: any) => x.roleCode === "admin"
      );

      return {
        isAdmin,
        versionCode,
      };
    } catch (error) {
      console.error("localStorageUserInfo Data is incorrect! error:", error);
    }
  }

  setAllDepartments(departments: Array<Department>) {
    this.allDepartments = departments;
  }

  getDepartments() {
    GetDepartments().then((res: IRes<Array<Department>>) => {
      if (res?.statusCode === NetState.Ok) this.allDepartments = res.value;
    });
  }
  getRepairBudgetType() {
    GetRepairBudgetType().then((res: IRes<MaintenanceTypeEnum>) => {
      if (res?.statusCode === NetState.Ok) this.maintenanceTypeEnum = res.value;
    });
  }
  getCompanys() {
    GetCompanyInfo().then((res: IRes<Array<ICompanyInfo>>) => {
      if (res?.statusCode !== 200) return console.error(res?.message);
      this.companys = res.value;
    });
  }
}
export default GlobalStore;
