import { getWorkflowInstanceInfo, postBatchApproval, Signout } from "@axios";
import {
  MaintenanceRecords,
  MaintenanceRecordsBaoYang,
  Quick,
  ScanQRCode,
  SpotCheck,
  GetMaterialsImg,
} from "@components/icon";
import Inventory from "@imgs/Inventory.png";
import type { IGlobalStore } from "@store/global";
import { createConsole, Dateformat } from "@utils";
import { push } from "@utils/common";
import {
  Button,
  Checkbox,
  Collapse,
  DatePicker,
  Dropdown,
  Form,
  Grid,
  InfiniteScroll,
  Input,
  NavBar,
  Picker,
  Space,
  Toast,
  WaterMark,
  PullToRefresh,
} from "antd-mobile";
import type { DatePickerRef } from "antd-mobile/es/components/date-picker";
import type { FormInstance } from "antd-mobile/es/components/form";
import type { PickerColumn, PickerRef } from "antd-mobile/es/components/picker";
import { MobXProviderContext } from "mobx-react";
import { FC, RefObject, useContext, useMemo, useState, useEffect } from "react";
import styles from "./index.module.less";

interface IFilterData {
  condition: string;
  departmentId: string;
  endTime: string;
  startTime: string;
}

interface ILoadMore {
  sTime?: string;
  eTime?: string;
  departmentId?: string;
  condition?: string;
  workflowStatus?: number;
  sponsorOrApproval?: number;
  pageNo?: number;
  pageSize?: number;
}

enum SystemInfoEnumSZ {
  "系统消息" = 0,
  "领料",
  "入库",
  "维修计划",
  "维修立项申请",
  "购置计划" = 5,
  "新建计划",
  "二级保养计划",
  "维修申请单",
  "维修采购申请单",
  "成本预算" = 10,
  "工程配合",
  "外协单",
  "维修验收单",
  "维修完工单",
  "维修结算单" = 15,
  "保养申请单",
  "保养采购申请单",
  "保养工程配合单",
  "保养外协单",
  "保养记录表" = 20,
  "保养检查表",
  "保养结算单",
  "购置固定资产申购单",
  "购置固定资产验收报告",
  "购置固定资产入账单" = 25,
  "新建申请单",
  "新建固定资产验收报告",
  "新建完工单",
  "新建结算单",
  "新建固定资产入账单" = 30,
  "设备外借",
  "设备调拨",
  "设备盘点",
  "设备报废",
  "设备借用" = 35,
  "船舶年度修理计划",
  "船舶维保计划",
  "船舶修理预算计划",
  "船舶物料购置计划",
  "船舶修理申请单" = 40,
  "船舶修理工程单",
  "船舶备件申请单",
  "船舶物料申请单",
  "船舶采购申请单",
  "船舶报批申请单" = 45,
  "船舶外协申请单",
  "船舶工程配合申请单",
  "船舶验收申请单",
  "船舶完工申请单",
  "船舶结算申请单" = 50,
  "船舶入库",
  "燃、润滑油月度消耗统计表",
  "防台设备检查表",
  "消防救生器材、设备（含堵漏、防污）检查表",
  "定期检修、校验或更新的设备时间登记表" = 55,
  "应急、备用、关键性设备和系统定期检测记录表",
  "船舶备件季度统计清单",
  "船舶物料季度统计清单",
  "船舶燃油测量表",
  "待修改库存清单" = 60,
  "工具库报废单",
  "周转材料报废",
  "周转材料入库",
  "船舶立项申请单",
  "快捷维修" = 65,
}

enum SystemInfoEnumZS {
  "系统消息" = 0,
  "领料",
  "入库",
  "维修计划",
  "维修立项申请",
  "购置计划" = 5,
  "新建计划",
  "二级保养计划",
  "维修申请单",
  "维修采购申请单",
  "成本预算" = 10,
  "工程配合",
  "外协单",
  "维修验收单",
  "维修完工单",
  "维修结算单" = 15,
  "保养申请单",
  "保养采购申请单",
  "横向联系单",
  "保养外协单",
  "保养记录表" = 20,
  "保养检查表",
  "保养结算单",
  "购置固定资产申购单",
  "购置固定资产验收报告",
  "购置固定资产入账单" = 25,
  "内修、建造立项申请审批表",
  "新建固定资产验收报告",
  "新建完工单",
  "新建结算单",
  "新建固定资产入账单" = 30,
  "设备外借",
  "设备调拨",
  "设备盘点",
  "设备报废",
  "设备借用" = 35,
  "船舶年度修理计划",
  "船舶维保计划",
  "船舶修理预算计划",
  "船舶物料购置计划",
  "船舶修理申请单" = 40,
  "船舶修理工程单",
  "船舶备件申请单",
  "船舶物料申请单",
  "船舶采购申请单",
  "船舶报批申请单" = 45,
  "船舶外协申请单",
  "船舶工程配合申请单",
  "船舶验收申请单",
  "船舶完工申请单",
  "船舶结算申请单" = 50,
  "船舶入库",
  "燃、润滑油月度消耗统计表",
  "防台设备检查表",
  "消防救生器材、设备（含堵漏、防污）检查表",
  "定期检修、校验或更新的设备时间登记表" = 55,
  "应急、备用、关键性设备和系统定期检测记录表",
  "船舶备件季度统计清单",
  "船舶物料季度统计清单",
  "船舶燃油测量表",
  "待修改库存清单" = 60,
  "工具库报废单",
  "周转材料报废",
  "周转材料入库",
  "船舶立项申请单",
  "快捷维修" = 65,
}

enum SystemInfoEnumCB {
  "系统消息" = 0,
  "领料",
  "入库",
  "维修计划",
  "维修立项申请",
  "购置计划" = 5,
  "新建计划",
  "二级保养计划",
  "维修申请单",
  "维修采购申请单",
  "成本预算" = 10,
  "工程配合",
  "外协单",
  "维修验收单",
  "维修完工单",
  "维修结算单" = 15,
  "保养申请单",
  "保养采购申请单",
  "保养工程配合单",
  "保养外协单",
  "保养记录表" = 20,
  "保养检查表",
  "保养结算单",
  "购置固定资产申购单",
  "购置固定资产验收报告",
  "购置固定资产入账单" = 25,
  "新建申请单",
  "新建固定资产验收报告",
  "新建完工单",
  "新建结算单",
  "新建固定资产入账单" = 30,
  "设备外借",
  "设备调拨",
  "设备盘点",
  "设备报废",
  "设备借用" = 35,
  "船舶年度修理计划",
  "船舶维保计划",
  "船舶修理预算计划",
  "船舶物料购置计划",
  "船舶修理申请单" = 40,
  "船舶修理工程单",
  "船舶备件申请单",
  "船舶物料申请单",
  "船舶采购申请单",
  "船舶报批申请单" = 45,
  "船舶外协申请单",
  "船舶工程配合申请单",
  "船舶验收申请单",
  "船舶完工申请单",
  "船舶结算申请单" = 50,
  "船舶入库",
  "燃、润滑油月度消耗统计表",
  "防台设备检查表",
  "消防救生器材、设备（含堵漏、防污）检查表",
  "定期检修、校验或更新的设备时间登记表" = 55,
  "应急、备用、关键性设备和系统定期检测记录表",
  "船舶备件季度统计清单",
  "船舶物料季度统计清单",
  "船舶燃油测量表",
  "待修改库存清单" = 60,
  "工具库报废单",
  "周转材料报废",
  "周转材料入库",
  "船舶立项申请单",
  "快捷维修" = 65,
}

/** 水印参数 */
const WaterMarkProps = {
  content: "已驳回",
  gapX: 12,
  gapY: 24,
  fullPage: false,
  fontColor: "rgba(255, 255, 255, .9)",
};
const REACT_APP_Version = process.env.REACT_APP_Version
  ? process.env.REACT_APP_Version
  : "ShenZhenYouLian";

const Home: FC = () => {
  const GlobalStore: IGlobalStore | undefined =
    useContext(MobXProviderContext)?.GlobalStore;
  const [isOptional, setIsOptional] = useState(false);
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState<PickerColumn[]>([]);
  const [pageNo, setPageNo] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [list, setList] = useState<HomePage.IRecord[]>([]);
  const [activeKey, setActiveKey] = useState<string | null>(null);
  const [userName, setUserName] = useState("...");
  const [filterData, setFilterData] = useState<IFilterData>({
    condition: "",
    departmentId: "",
    endTime: "",
    startTime: "",
  });

  // 新需求--去除退出登入
  const toLogin = () => {
    Signout().then((res) => {
      if (typeof res?.value === "string" && res.value !== "") {
        push("/login", { isExit: true, openId: res.value });
      } else {
        push("/login", { isExit: true });
      }
    });
  };

  const goPage = (path: string, params: any = {}) => push(path, params);

  const filterDataF = (value: any) => {
    const req: IFilterData = {
      condition: value.condition ?? "",
      departmentId: (value.departmentId?.length && value.departmentId[0]) || "",
      endTime: Dateformat(value.endTime) ?? "",
      startTime: Dateformat(value.startTime) ?? "",
    };
    setFilterData(req);
    setList([]);
    setPageNo(1);
    setHasMore(true);
    setActiveKey(null);
  };

  useEffect(() => {
    // (window as any).parent.postMessage("Hello, world!", "*");

    // 获取 iframe 元素
    // const iframeElement = document.getElementById("myIframe");

    // // 检查 iframe 元素是否存在
    // if (iframeElement) {
    //   // 将 iframe 元素转换为 HTMLIFrameElement 类型
    //   const iframeElementTyped = iframeElement as any;

    //   // 获取 iframe 的 contentWindow
    //   const iframeWindow:any= iframeElementTyped.contentWindow;

    //   // 检查 contentWindow 是否存在
    //   if (iframeWindow) {
    //     console.log("ww",iframeElementTyped.contentWindow);
    //     // 使用 iframeWindow
    //     iframeWindow.postMessage("Hello, iframe测试测试菜市场!", "*");
    //   } else {
    //     console.error("iframe contentWindow is null");
    //   }
    // } else {
    //   console.error("iframe element not found");
    // }

    if (typeof GlobalStore?.user?.user?.name === "string") {
      setUserName(GlobalStore.user.user.name);
    }
    if (process.env.REACT_APP_Version === "ZhouShanYouLian") {
      document.title = "舟山友联设备管理系统";
    } else if (process.env.REACT_APP_Version === "ShenZhenYouLian") {
      document.title = "友联设备管理平台";
    }
  }, []);

  const reset = () => {
    if (typeof GlobalStore?.user?.user?.name === "string") {
      setUserName(GlobalStore.user.user.name);
    }
    setPageNo(1);
    setList([]);
    const option: ILoadMore = { pageNo: 1 };
    return loadMore(option);
  };

  const resetList = () => {
    const req: IFilterData = {
      condition: "",
      departmentId: "",
      endTime: "",
      startTime: "",
    };
    setFilterData(req);
    setList([]);
    setPageNo(1);
    setHasMore(true);
  };

  const handleClick = async (_e: any, datePickerRef: RefObject<PickerRef>) => {
    datePickerRef.current?.open();
    if (!columns.length && GlobalStore) {
      setColumns([
        GlobalStore.allDepartments.map((item: { key: any; title: any }) => {
          return {
            value: item.key,
            label: item.title,
          };
        }),
      ]);
      setLoading(false);
    }
  };

  const loadMore = (option?: ILoadMore) => {
    const reqData = {
      sTime: option?.sTime || filterData.startTime,
      eTime: option?.eTime || filterData.endTime,
      departmentId: option?.departmentId || filterData.departmentId,
      condition: option?.condition || filterData.condition,
      workflowStatus: option?.workflowStatus || 6,
      sponsorOrApproval: option?.sponsorOrApproval || 1,
      pageNo: option?.pageNo || pageNo,
      pageSize: 10,
    };

    return getWorkflowInstanceInfo(reqData)
      .then((res) => {
        if (res?.statusCode === 200) {
          setList(
            list.concat(
              res.value.data.map(function (item: any) {
                return Object.assign({}, item, { checked: false });
              })
            )
          );
          const pageNo = reqData.pageNo + 1;
          setPageNo(pageNo);
          if (pageNo >= Math.ceil(res.value.count / reqData.pageSize)) {
            setHasMore(false);
          }
        } else return Promise.reject(res);
      })
      .catch((e) => {
        setHasMore(false);
        Toast.show("getWorkflowInstanceInfo error");
      });
    // return getWorkflowInstanceInfo
  };

  const RedioCheck = (val: boolean, index: number) => {
    if (list[index]) {
      const newList = [...list];
      newList[index].checked = val;
      setList(newList);
    } else {
      Toast.show({
        icon: "fail",
        content: "选中出现问题请联系管理员处理",
      });
    }
  };

  const optionalEnable = () => {
    setIsOptional(true);
  };

  const optionalDisable = () => {
    setIsOptional(false);
    let isRefresh = false,
      index = 0,
      len = list.length;
    const newList = [...list];
    for (; index < len; index++) {
      if (newList[index].checked) {
        isRefresh = true;
        break;
      }
    }
    if (isRefresh) {
      for (; index < len; index++) {
        newList[index].checked = false;
        setList(newList);
      }
    }
  };

  const checkTime = (form: FormInstance, isStart: boolean, value: Date) => {
    let startTime, endTime;
    if (isStart) {
      startTime = value?.getTime();
      endTime = form.getFieldValue("endTime")?.getTime();
    } else {
      startTime = form.getFieldValue("startTime")?.getTime();
      endTime = value?.getTime();
    }

    if (startTime && endTime && startTime > endTime) {
      const text = isStart
        ? "起始时间不能超过结束时间"
        : "结束时间不能在起始时间之前";
      return Promise.reject(new Error(text));
    }
    return Promise.resolve();
  };
  const checkEndTime = (form: FormInstance, value: Date) => {
    return checkTime(form, false, value);
  };
  const checkStartTime = (form: FormInstance, value: Date) => {
    return checkTime(form, true, value);
  };

  const batchApproval = () => {
    const req = list
      .filter((item) => {
        return item.checked;
      })
      .map((item) => {
        return {
          formId: item.id,
          workflowFormId: item.workflowFormId,
          currentStepId: item.currentNodeInstanceId,
        };
      });
    if (!req.length) {
      return Toast.show("请选择需要通过的表单！");
    }
    postBatchApproval(req).then((res) => {
      if (res?.statusCode !== 200) {
        return console.error(res);
      }
      Toast.show({ content: "批量审批通过！", icon: "success" });
      setList([]);
      setPageNo(1);
      setHasMore(true);
    });
  };

  const toPane = (record: HomePage.IRecord) => {
    if (isOptional) return;
    record = { ...record };

    switch (record.workflowStatus) {
      case 5:
        if (record.systemInfoEnum === 65) {
          // 快捷维修不跳出
          break;
        } else {
          Toast.show("被驳回单请到PC端处理！");
          return;
        }
      case 2:
      case 6:
        record.workflowStatus = 6;
        break;
      default:
        Toast.show("数据异常！");
        return;
    }
    const formState = { formId: record.workflowFormId };
    switch (record.systemInfoEnum) {
      // 0: 系统消息
      case 1: // 领料
        push(
          "/basicinformation/maintenanceMaterials/receivingmaterials",
          formState
        );
        break;
      case 2: // 入库
        push("/basicinformation/maintenanceMaterials/warehousing", formState);
        break;
      case 4: // 维修立项申请
        if (REACT_APP_Version === "ShenZhenYouLian") {
          push("/program/projectForm", formState);
        } else if (REACT_APP_Version === "ZhouShanYouLian") {
          push("/program/projectForm2", formState);
        } else {
          console.log("REACT_APP_Version", REACT_APP_Version);
          Toast.show("未知");
        }
        break;
      case 3:
      case 5:
      case 6:
      case 7:
        push("/program/program", {
          formId: record.workflowFormId,
          systemInfoEnum: record.systemInfoEnum,
        });
        break;

      case 8: // 维修申请单
        push("/maintainWork/application", formState);
        break;
      case 9: // 维修采购申请单
        push("/maintainWork/sparePartPurchaseApplication", formState);
        break;
      case 10: // 成本预算
        push("/maintainWork/projectCostBudget", formState);
        break;
      case 11: // 深圳：工程配合 舟山：横向联系
        if (REACT_APP_Version === "ShenZhenYouLian") {
          push("/maintainWork/projectFitApplication", formState);
        } else if (REACT_APP_Version === "ZhouShanYouLian") {
          push("/maintainWork/ProductionEquipmentMaintenance2", formState);
        } else {
          console.log("REACT_APP_Version", REACT_APP_Version);
          Toast.show("未知");
        }
        break;
      case 12: // 外协单
        push("/maintainWork/outsourceProjectApplication", formState);
        break;
      case 16: // 保养申请单
        push("/upkeepWork/application", formState);
        break;
      case 17: // 保养采购申请单
        push("/upkeepWork/upkeepSparePartPurchaseApplication", formState);
        break;
      case 21: // 保养检查申请表
        push(`/upkeepWork/upkeepCheck`, formState);
        break;
      case 23: // 购置固定资产申购单
        push("/purchaseWork/assetRequisition", formState);
        break;
      case 25: // 购置固定资产申购单
        push("/purchaseWork/recorded", formState);
        break;
      case 26: // 新建申请单
        if (REACT_APP_Version === "ShenZhenYouLian") {
          push("/facilityBuildWork/buildApproval", formState);
        } else if (REACT_APP_Version === "ZhouShanYouLian") {
          push("/facilityBuildWork/buildApproval2", formState);
        } else {
          console.log("REACT_APP_Version", REACT_APP_Version);
          Toast.show("未知");
        }
        break;
      case 30: // 新建固定资产入账单
        push("/facilityBuildWork/recorded", formState);
        break;
      case 31: // 外借
        push("/management/waijieForm", formState);
        break;
      case 32: // 调拨
        push("/management/transfersForm", formState);
        break;
      case 33: // 盘点
        push("/management/inventoryApproval", formState);
        break;
      case 34: // 设备报废
        push("/management/scrapForm", formState);
        break;

      case 36:
      case 37:
      case 38:
      case 39:
        // 船舶计划汇总
        push("/shipProgram/shipProgram", {
          formId: record.workflowFormId,
          systemInfoEnum: record.systemInfoEnum,
        });
        break;
      case 40: // 船舶申请
        push("/shipWork/InspectionMaintenance", formState);
        break;
      case 42: // 船舶备件
        push("/shipWork/SparePart", {
          ...formState,
          currentModule: "SparePart",
        });
        break;
      case 43: // 船舶物料
        push("/shipWork/SparePart", {
          ...formState,
          currentModule: "Material",
        });
        break;
      case 46: // 船舶外协申请单
        push("/shipThirdParty/outsourceProjectApplications", formState);
        break;
      case 47: // 工程配合申请单
        push("/shipThirdParty/productionEquipmentMaintenance", formState);
        break;
      case 51: // 船舶入库
        push("/shipThirdParty/warehousing", formState);
        break;
      case 61: // 工具库报废单
        push("/basicinformation/toolLibrary/scrapSheet", formState);
        break;
      case 62: // 周转材料报废
        push(
          "/basicinformation/turnaroundMaterial/scrapApplication",
          formState
        );
        break;
      case 63: // 周转材料入库
        push("/basicinformation/turnaroundMaterial/inbound", formState);
        break;
      case 64: // 船舶立项申请单
        push("/shipThirdParty/projectApprovalSheet", formState);
        break;
      case 65: // 快捷维修
        push("/index.html/quickStart", formState);
        break;
      default:
        Toast.show("开发中");
    }
  };

  const gridNav = useMemo(() => {
    const workList: HomePage.IWorkList = {
      ZhouShanYouLian: {
        columns: 4,
        gap: 8,
        list: [
          {
            icon: <ScanQRCode color="#fa6400" />,
            text: "扫码维修",
            path: "/index.html/repaircontent",
          },
          {
            icon: <ScanQRCode color="#19aee8" />,
            text: "一级保养",
            path: "/maintain",
          },
          {
            icon: <ScanQRCode />,
            text: "二级保养",
            path: "/index.html/maintenanceWrok",
          },
          {
            icon: <ScanQRCode color="#7b62d7" />,
            text: "扫码盘点",
            path: "/index.html",
          },

          {
            icon: <MaintenanceRecords />,
            text: "维修记录",
            path: "/LedgerList/maintenance",
          },
          {
            icon: (
              <MaintenanceRecordsBaoYang
                linearGradient={[
                  {
                    color: "#48E8FF",
                    offset: 0,
                  },
                  {
                    color: "#2C7AFB",
                    offset: 100,
                  },
                ]}
              />
            ),
            text: "一级保养记录",
            path: "/LedgerList/oneMaintenance",
          },
          {
            icon: <MaintenanceRecordsBaoYang />,
            text: "二级保养记录",
            path: "/LedgerList/twoMaintenance",
          },
          {
            icon: <img src={Inventory} alt="error" />,
            text: "盘点记录",
            path: "/inventoryRecord",
          },
        ],
      },
      ShenZhenYouLian: {
        columns: 5,
        gap: 8,
        list: [
          {
            icon: <ScanQRCode color="#19aee8" />,
            text: "扫码工作",
            path: "/index.html",
          },
          {
            icon: <Quick />,
            text: "快捷维修记录",
            path: "/LedgerList/quickRepair",
          },
          {
            icon: (
              <MaintenanceRecordsBaoYang
                linearGradient={[
                  {
                    color: "#48E8FF",
                    offset: 0,
                  },
                  {
                    color: "#2C7AFB",
                    offset: 100,
                  },
                ]}
              />
            ),
            text: "一级保养记录",
            path: "/LedgerList/oneMaintenance",
          },
          {
            icon: <SpotCheck />,
            text: "点检记录",
            path: "/LedgerList/spotCheck",
          },
          {
            icon: <img src={Inventory} alt="error" />,
            text: "盘点记录",
            path: "/inventoryRecord",
          },
        ],
      },
      ShenZhenChuanBo: {
        columns: 4,
        gap: 8,
        list: [
          {
            icon: <ScanQRCode />,
            text: "预留扫码",
            path: "/404",
          },
          {
            icon: <MaintenanceRecords />,
            text: "维修记录",
            path: "/shipList/shipMaintenance",
          },
          {
            icon: (
              <MaintenanceRecordsBaoYang
                linearGradient={[
                  {
                    color: "#48E8FF",
                    offset: 0,
                  },
                  {
                    color: "#2C7AFB",
                    offset: 100,
                  },
                ]}
              />
            ),
            text: "维保工作",
            path: "/shipWarrantyWork",
          },
          {
            icon: <MaintenanceRecordsBaoYang />,
            text: "维保记录",
            path: "/shipList/shipUpkeep",
          },
        ],
      },
    };

    const nowList = workList[REACT_APP_Version];
    return (
      <Grid columns={nowList.columns} gap={nowList.gap}>
        {nowList.list.map((item) => {
          return (
            <Grid.Item key={item.path} onClick={() => goPage(item.path)}>
              <div className={styles["grid-item-block"]}>
                <div>{item.icon}</div>
                <span>{item.text}</span>
              </div>
            </Grid.Item>
          );
        })}
        {REACT_APP_Version === "ShenZhenYouLian" && (
          <Grid.Item
            key="/basicinformation/maintenanceMaterials/receivingmaterials"
            onClick={() => {
              goPage(
                "/basicinformation/maintenanceMaterials/receivingmaterials",
                { isSubmit: true }
              );
            }}
          >
            <div className={styles["grid-item-block"]}>
              <div>
                <GetMaterialsImg />
              </div>
              <span>领料</span>
            </div>
          </Grid.Item>
        )}
      </Grid>
    );
  }, []);

  return (
    <PullToRefresh onRefresh={reset}>
      <NavBar
        onBack={toLogin}
        right={userName}
        back={
          process.env.REACT_APP_Version === "ZhouShanYouLian"
            ? "返回登录"
            : null
        }
      >
        {/* <NavBar onBack={toLogin} right={userName} backArrow={false}> */}
        <span onClick={createConsole}>
          {process.env.REACT_APP_Version === "ZhouShanYouLian"
            ? "舟山友联设备管理系统"
            : "友联设备管理平台"}
        </span>
      </NavBar>
      <div className={styles["content"]}>
        <Collapse defaultActiveKey={["1"]}>
          <Collapse.Panel key="1" title="工作">
            {gridNav}
          </Collapse.Panel>
        </Collapse>
        <div>
          <div className={styles["list-head"]}>
            <div>待审批</div>
            <div>
              {isOptional ? (
                <Space>
                  <Button
                    size="mini"
                    color="primary"
                    fill="outline"
                    onClick={batchApproval}
                  >
                    通过
                  </Button>
                  <Button onClick={optionalDisable} size="mini">
                    取消
                  </Button>
                </Space>
              ) : (
                <Button size="mini" onClick={optionalEnable}>
                  批量审批
                </Button>
              )}
            </div>
          </div>
          <Dropdown
            className={styles["list-dropdown"]}
            closeOnClickAway={false}
            activeKey={activeKey}
            onChange={(newActiveKey: string | null) =>
              setActiveKey(newActiveKey)
            }
          >
            <Dropdown.Item key="filter" title="筛选">
              <Form
                layout="horizontal"
                onFinish={filterDataF}
                footer={
                  <Grid columns={2} gap={8}>
                    <Grid.Item>
                      <Button
                        block
                        type="reset"
                        color="danger"
                        shape="rounded"
                        onClick={resetList}
                      >
                        重置
                      </Button>
                    </Grid.Item>
                    <Grid.Item>
                      <Button
                        block
                        type="submit"
                        color="primary"
                        shape="rounded"
                      >
                        搜索
                      </Button>
                    </Grid.Item>
                  </Grid>
                }
              >
                <Form.Item name="condition" label="名称">
                  <Input placeholder="点击输入名称" />
                </Form.Item>
                <Form.Item
                  name="departmentId"
                  label="部门"
                  trigger="onConfirm"
                  onClick={handleClick}
                >
                  <Picker loading={loading} columns={columns}>
                    {(items) => {
                      return (
                        <span>
                          {items[0] === null
                            ? "点击选择部门"
                            : items[0]?.label ?? "点击选择部门"}
                        </span>
                      );
                    }}
                  </Picker>
                </Form.Item>
                <Form.Item
                  name="startTime"
                  label="起始时间"
                  trigger="onConfirm"
                  dependencies={["endTime"]}
                  rules={[
                    (form: FormInstance) => ({
                      required: Boolean(form.getFieldValue("endTime")),
                    }),
                    (form: FormInstance) => ({
                      validator: (_: any, value: Date) =>
                        checkStartTime(form, value),
                    }),
                  ]}
                  onClick={(e, datePickerRef: RefObject<DatePickerRef>) => {
                    datePickerRef.current?.open();
                  }}
                >
                  <DatePicker>
                    {(value) => Dateformat(value) ?? "点击选择起始时间"}
                  </DatePicker>
                </Form.Item>
                <Form.Item
                  name="endTime"
                  label="结束时间"
                  trigger="onConfirm"
                  dependencies={["startTime"]}
                  rules={[
                    (form: FormInstance) => ({
                      required: Boolean(form.getFieldValue("startTime")),
                    }),
                    (form: FormInstance) => ({
                      validator: (_: any, value: Date) =>
                        checkEndTime(form, value),
                    }),
                  ]}
                  onClick={(e, datePickerRef: RefObject<DatePickerRef>) => {
                    datePickerRef.current?.open();
                  }}
                >
                  <DatePicker>
                    {(value) => Dateformat(value) ?? "点击选择结束时间"}
                  </DatePicker>
                </Form.Item>
              </Form>
            </Dropdown.Item>
          </Dropdown>
          <div className={styles["list"]}>
            {list.map((item, index: number) => {
              const Rejected = item.workflowStatus === 5;
              // const IsQuick = item.systemInfoEnum === 65;
              const IsQuick = false;
              const className =
                styles["list-item"] +
                (Rejected && !IsQuick
                  ? " " + styles["list-item-rejected"]
                  : "");

              let formType = "";
              if (REACT_APP_Version === "ShenZhenYouLian") {
                formType = SystemInfoEnumSZ[item.systemInfoEnum] || "未知";
              } else if (REACT_APP_Version === "ZhouShanYouLian") {
                formType = SystemInfoEnumZS[item.systemInfoEnum] || "未知";
              } else if (REACT_APP_Version === "ShenZhenChuanBo") {
                formType = SystemInfoEnumCB[item.systemInfoEnum] || "未知";
              } else {
                formType = "未知";
              }
              return (
                <label htmlFor={item.id} key={item.id}>
                  <div className={className} onClick={() => toPane(item)}>
                    {Rejected && <WaterMark {...WaterMarkProps} />}
                    <div className={styles["head"]}>
                      {isOptional && (
                        <Checkbox
                          id={item.id}
                          checked={item.checked}
                          onChange={(val) => RedioCheck(val, index)}
                        />
                      )}
                      <div>
                        <span>{item.name}</span>
                        <span className={styles["form-type"]}>
                          --{formType}
                        </span>
                      </div>
                    </div>
                    <Grid columns={2} gap={8}>
                      <Grid.Item>
                        <span className="item-name">部门：</span>
                        <span className="item-value">
                          {item.departmentName}
                        </span>
                      </Grid.Item>
                      <Grid.Item>
                        <span className="item-name">流程进度：</span>
                        <span className="item-value">
                          {item.currentNodeInstance}
                        </span>
                      </Grid.Item>
                      <Grid.Item>
                        <span className="item-name">发起人：</span>
                        <span className="item-value">{item.sponsorName} </span>
                      </Grid.Item>
                      <Grid.Item>
                        <span className="item-name">发起时间：</span>
                        <span className="item-value">{item.sponsoredDate}</span>
                      </Grid.Item>
                    </Grid>
                  </div>
                </label>
              );
            })}
            <InfiniteScroll loadMore={() => loadMore()} hasMore={hasMore} />
          </div>
          {/* <div className={styles["scrollable-iframe"]}>
            <iframe
              id="myIframe"
              src={`http://120.79.89.249:2029`}
              width="1000px"
              height="500px"
              scrolling="auto"
            ></iframe>
          </div> */}
        </div>
      </div>
    </PullToRefresh>
  );
};

export default Home;
