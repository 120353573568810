import {
  GetInfo,
  GetMaintainWorkList,
  GetUpkeepWorkList,
  GetWorkMaintainContent,
  submitAssetInfoInventory,
  uploadImgFile,
  uploadImg,
} from "@axios";
import { FILE } from "@axios/config";
import {
  MaintenanceRecords,
  MaintenanceRecordsBaoYang,
  Quick,
  SpotCheck,
} from "@components/icon";
import Record from "@components/record";
import Inventory from "@imgs/Inventory.png";
import { MyUUIDValidateEasy, NetState, createConsole } from "@utils";
import { formatSearch, getImg, history, push } from "@utils/common";
import { scan } from "@utils/scan";
import {
  Button,
  InfiniteScroll,
  Modal,
  NavBar,
  Popup,
  SpinLoading,
  Tabs,
  Toast,
} from "antd-mobile";
import { RightOutline } from "antd-mobile-icons";
import { createRef, useEffect, useState, useContext } from "react";
import { MobXProviderContext } from "mobx-react";
import style from "./index.module.less";
import SpotCheckSubmit from "./spotCheckSubmit";
import type { IGlobalStore } from "@store/global";

interface custom {
  [key: string]: any;
}
/**
 * 页面标题
 */
const titles: custom = {
  repaircontent: "维修内容",
  repair: "维修记录",
  oneMaintenance: "一级保养记录",
  twoMaintenance: "二级保养记录",
  spotCheckSubmit: "点检",
  spotCheck: "点检记录",
  quickStart: "发起维修",
  quickEnd: "结束维修",
  quick: "快捷维修记录",
};
const REACT_APP_Version = process.env.REACT_APP_Version
  ? process.env.REACT_APP_Version
  : "ShenZhenYouLian";

/**
 * 设备信息
 * @param {string} infoType 设备信息类型 null默认work页面 |
 * repaircontent:维修内容 | repair:维修记录 |
 * maintenanceWrok:保养工作(二级保养) | oneMaintenance:一级保养记录 | twoMaintenance:二级保养记录 |
 * spotCheckSubmit:点检 | spotCheck:点检记录 |
 * quickEnd:结束快捷维修 | quick:快捷维修记录
 */
const EquipmentDetails = ({ infoType = "" }) => {
  const GlobalStore: IGlobalStore | undefined =
    useContext(MobXProviderContext)?.GlobalStore;
  // 设备信息
  const [info, setInfo] = useState<any>({});
  // 设备图片url
  const [upImgUrl, setUpImgUrl] = useState("");
  // 是否有更多数据
  const [hasMore, setHasMore] = useState(true);
  // 当前页码
  const [pageNo, setPageNo] = useState(1);
  // 维修类型
  const [projectTypeEnum, setProjectTypeEnum] = useState(0);
  // 维修数据
  const [workList, setWorkList] = useState<any[]>([]);
  // 保养数据
  const [maintenanceList, setMaintenanceList] = useState<any[]>([]);
  // 是否正在更改状态
  const [isChange, setIsChange] = useState(false);
  // 是否正在加载数据
  const [isload, setIsload] = useState(false);
  // 盘点图片url
  const [imgList, setImgList] = useState<any[]>([]);
  // 盘点弹窗
  const [inventoryVisible, setInventoryVisible] = useState(false);
  // 盘点状态
  const [inventoryStatus, setInventoryStatus] = useState("");
  // 快捷维修选择弹窗(发起/结束)
  const [quickVisible, setQuickVisible] = useState(false);
  // 点检选择弹窗(周期/使用)
  const [spotCheckVisible, setSpotCheckVisible] = useState(false);
  //管理员或同部门
  const [showQuick, setShowQuick] = useState(false);
  // 能否操作
  const [canOperate, setCanOperate] = useState(false);
  // 是否启用 state为 0启用
  const [enable, setEnable] = useState(true);
  var fileNode: any = createRef();
  var fileNodeTakeStock: any = createRef();

  /**
   * 工作按钮图标
   */
  const clickIcons: custom = {
    ShenZhenYouLian: (
      <>
        <div
          onClick={() => {
            if (enable) {
              setQuickVisible(true);
            } else {
              Toast.show("已报废！");
            }
          }}
        >
          <Quick />
          <div>快 捷 维 修</div>
          <Popup
            visible={quickVisible}
            onMaskClick={() => setQuickVisible(false)}
            className={style.quickSelect}
            bodyStyle={{
              overflow: "auto",
              height: "25vh",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            <div className={style.title}>工作选择</div>
            <div className={style.selectIcons}>
              {showQuick && (
                <div onClick={() => pageJump("quickStart")}>
                  <MaintenanceRecords />
                  发起维修
                </div>
              )}
              <div onClick={() => pageJump("quickEnd")}>
                <Quick />
                结束维修
              </div>
            </div>
          </Popup>
        </div>
        <div
          onClick={() => {
            if (enable) {
              pageJump("maintain");
            } else {
              Toast.show("已报废！");
            }
          }}
        >
          <MaintenanceRecordsBaoYang
            linearGradient={[
              {
                color: "#48E8FF",
                offset: 0,
              },
              {
                color: "#2C7AFB",
                offset: 100,
              },
            ]}
          />
          <div>一 级 保 养</div>
        </div>
        <div
          onClick={() => {
            if (enable) {
              setSpotCheckVisible(true);
            } else {
              Toast.show("已报废！");
            }
          }}
        >
          {/* <div onClick={() => pageJump("spotCheckSubmit")}> */}
          <SpotCheck />
          <div>点 检</div>
          <Popup
            visible={spotCheckVisible}
            onMaskClick={() => setSpotCheckVisible(false)}
            className={style.quickSelect}
            bodyStyle={{
              overflow: "auto",
              height: "25vh",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            <div className={style.title}>点检选择</div>
            <div className={style.selectIcons}>
              {/* {showQuick && (
                <div onClick={() => pageJump("spotCheckSubmitCycle")}>
                  <SpotCheck />
                  周期点检
                </div>
              )} */}
              <div onClick={() => pageJump("spotCheckSubmitUse")}>
                <SpotCheck />
                使用点检
              </div>
            </div>
          </Popup>
        </div>
        <div
          onClick={() => {
            if (enable) {
              canOperate ? setInventoryVisible(true) : canNotOperate();
            } else {
              Toast.show("已报废！");
            }
          }}
        >
          <img src={Inventory} alt="error" />
          <div>盘 点</div>
        </div>
      </>
    ),
    ZhouShanYouLian: (
      <>
        <div onClick={() => info.id && pageJump("repaircontent")}>
          <MaintenanceRecords />
          <div>维 修</div>
        </div>
        <div onClick={() => pageJump("maintain")}>
          <MaintenanceRecordsBaoYang
            linearGradient={[
              {
                color: "#48E8FF",
                offset: 0,
              },
              {
                color: "#2C7AFB",
                offset: 100,
              },
            ]}
          />
          <div>一 级 保 养</div>
        </div>
        <div onClick={() => pageJump("maintenanceWrok")}>
          <MaintenanceRecordsBaoYang />
          <div>二 级 保 养</div>
        </div>
        <div
          onClick={() =>
            canOperate ? setInventoryVisible(true) : canNotOperate()
          }
        >
          <img src={Inventory} alt="error" />
          <div>盘 点</div>
        </div>
      </>
    ),
    ShenZhenNoLogin: (
      <>
        <div onClick={() => pageJump("oneMaintenance")}>
          <MaintenanceRecordsBaoYang
            linearGradient={[
              {
                color: "#48E8FF",
                offset: 0,
              },
              {
                color: "#2C7AFB",
                offset: 100,
              },
            ]}
          />
          <div>一级保养记录</div>
        </div>
        <div onClick={() => pageJump("spotCheck")}>
          <SpotCheck />
          <div>点检记录</div>
        </div>
      </>
    ),
  };

  useEffect(() => {
    const obj: any = formatSearch();
    console.log('obj',obj);
    
    if (obj.id) {
      getInfo(obj.id);
    } else {
      // 没有传id则调用扫码获取设备id
      scan()
        .then((value) => {
          history.replace(history.location.pathname + "?id=" + value);
          getInfo(value);
        })
        .catch((error) => {
          Toast.show(error);
          setTimeout(() => {
            history.go(-1);
          }, 500);
        });
    }
  }, []);
  // 初始化值
  useEffect(() => {
    setHasMore(true);
    setPageNo(1);
    setWorkList([]);
    setIsChange(false);
  }, [projectTypeEnum, info]);
  // 获取设备维修内容列表
  useEffect(() => {
    if (isChange || !info.id || infoType !== "repaircontent") return;
    setIsload(true);
    GetMaintainWorkList(pageNo, 6, info.deviceName, projectTypeEnum)
      .then((res) => {
        const newWorkList: any = [];
        if (res?.statusCode !== NetState.Ok) {
          Toast.show(res.message);
          return;
        }
        if (!res.value.data || res.value.data.length < 6) setHasMore(false);
        const p: Promise<any>[] = [];
        res.value.data.forEach((d: any) => {
          p.push(
            GetWorkMaintainContent(d.id, info.deviceName, projectTypeEnum)
          );
        });
        setIsload(true);
        Promise.all(p)
          .then((values) => {
            values.forEach((r, i) => {
              if (r.statusCode === NetState.Ok)
                newWorkList.push(
                  ...r.value.map((d: object) => ({
                    ...d,
                    obj: res.value.data[i],
                  }))
                );
            });
            setWorkList((e) => [...e, ...newWorkList]);
          })
          .finally(() => setIsload(false));
      })
      .finally(() => setIsload(false));
  }, [infoType, info, isChange, pageNo, projectTypeEnum]);
  // 获取设备二级保养工作
  useEffect(() => {
    if (isChange || !info.id || infoType !== "maintenanceWrok") return;
    setIsload(true);
    GetUpkeepWorkList(pageNo, 6, info.deviceName)
      .then((res) => {
        if (res?.statusCode !== NetState.Ok) {
          Toast.show(res.message);
          return;
        }
        if (!res.value.data) {
          Toast.show("返回数据错误");
          setHasMore(false);
          return;
        }
        setMaintenanceList((e) => [...e, ...res.value.data]);
        if (!res.value.data || res.value.data.length < 6) setHasMore(false);
      })
      .finally(() => setIsload(false));
  }, [infoType, info, isChange, pageNo]);
  /**
   * 获取设备信息
   * @param id
   */
  const getInfo = (id: string) => {
    setIsload(true);
    GetInfo(id)
      .then((res) => {
        if (res?.statusCode !== NetState.Ok) {
          Toast.show(res.message);
          return;
        }
        const isAdmin = GlobalStore?.user?.user.userRoles.some(
          (x: any) => x.roleCode === "admin"
        );
        if (isAdmin) {
          setShowQuick(true);
          setCanOperate(true);
        } else {
          const ledgerDepartmentId = res.value.departmentId;
          const userDepartmentId = GlobalStore?.user?.user.departmentUsers.find(
            (d) => d?.departmentId === ledgerDepartmentId
          )?.departmentId;
          if (MyUUIDValidateEasy(userDepartmentId)) {
            setShowQuick(true);
            setCanOperate(true);
          } else {
            setShowQuick(false);
            canNotOperate();
          }
        }

        // 无授权登入，获取信息--操作
        if (REACT_APP_Version === "ShenZhenNoLogin") {
          setCanOperate(true);
        }

        setEnable(res.value.state === 0 ? true : false);
        setInfo(res.value);
      })
      .finally(() => setIsload(false));
  };

  const canNotOperate = () => {
    // 无授权登入，获取信息--操作
    if (REACT_APP_Version === "ShenZhenNoLogin") return;
    Toast.show({
      icon: "fail",
      content: "请扫自己部门的台账！",
    });
  };
  /**
   * 获取图片路径
   * @returns url
   */
  const getimgUrl = () => {
    return (
      upImgUrl ||
      (info.imgFilePath
        ? FILE + info.imgFilePath
        : require("@style/imgs/addImage.png"))
    );
  };
  /**
   * 触发上传
   */
  const onOpenFileChooser = () => {
    if (!!fileNode) fileNode.current.click();
  };
  /**
   * 上传图片
   */
  const showPicture = (e: any) => {
    getImg(e.target).then(({ data, file }) => {
      const formData = new FormData();
      formData.append("file", file);
      uploadImgFile(formData)
        .then((res) => {
          if (res?.statusCode !== NetState.Ok) {
            // Toast.show(res.message);
            return { stateCode: 4000, message: "上传图片失败", value: false };
          }
          setUpImgUrl(data);
          return uploadImg({ infoId: info.id, path: res.data.FrPath });
        })
        .then((res) => {
          if (res?.statusCode === 200) {
            Toast.show({ icon: "success", content: "图片绑定成功" });
          } else if (res?.statusCode === 4000) {
            Toast.show({ icon: "fail", content: res.message || "未知错误" });
          } else {
            Toast.show({ icon: "fail", content: "图片绑定失败" });
          }
        });
    });
  };
  /**
   * 是否有更多数据
   * @returns
   */
  const loadMore = async () => {
    if (!isload && hasMore) {
      setPageNo(pageNo + 1);
    }
  };
  /**
   * 跳转页面
   * @param type 类型
   * @returns
   */
  const pageJump = (type: any, data?: object) => {
    if (!canOperate) return canNotOperate();
    switch (type) {
      // 维修工作申请单
      case "maintainWork":
        push("/maintainWork/application", data);
        break;
      // 维修内容
      case "repaircontent":
        push("/index.html/repaircontent?id=" + info.id);
        break;
      // 一级保养
      case "maintain":
        push("/maintain?id=" + info.id);
        break;
      // 周期点检
      case "spotCheckSubmitCycle":
        setSpotCheckVisible(false);
        setInfo({ ...info, spotCheckInfoType: 0 });
        push("/index.html/spotCheckSubmit?id=" + info.id);
        break;
      // 使用点检
      case "spotCheckSubmitUse":
        // case "spotCheckSubmit":
        setSpotCheckVisible(false);
        setInfo({ ...info, spotCheckInfoType: 1 });
        push("/index.html/spotCheckSubmit?id=" + info.id);
        break;
      // 保养工作(二级保养)
      case "maintenanceWrok":
        push("/index.html/maintenanceWrok?id=" + info.id);
        break;
      // 二级保养申请单
      case "maintenance":
        push("/upkeepWork/application", data);
        break;
      // 开始快捷维修
      case "quickStart":
        setQuickVisible(false);
        push("/index.html/quickStart", { ledgerID: info.id });
        break;
      // 结束快捷维修
      case "quickEnd":
        setQuickVisible(false);
        push("/index.html/quickEnd?id=" + info.id);
        break;
      case "oneMaintenance":
        push("/index.html/oneMaintenance?id=" + info.id);
        return;
      case "spotCheck":
        push("/index.html/spotCheck?id=" + info.id);
        return;
    }
  };
  /**
   * 提交设备盘点状态
   */
  const submitInventory = () => {
    if (!info.id) {
      Toast.show("获取设备信息错误");
      return;
    }
    if (!inventoryStatus) {
      Toast.show("请选择盘点状态");
      return;
    }
    if (!imgList.length) {
      Toast.show("请上传文件");
      return;
    }
    setIsload(true);
    const req = {
      assetInfoId: info.id,
      useStatus: inventoryStatus,
    };

    const reqData: any[] = imgList;
    submitAssetInfoInventory(req, reqData)
      .then((res) => {
        if (res?.statusCode !== NetState.Ok) {
          Toast.show(res.message);
          return;
        }
        Toast.show({
          icon: "success",
          content: "盘点成功",
        });

        setImgList([]);
        setInventoryVisible(false);
      })
      .finally(() => {
        setIsload(false);
      });
  };

  const setBack = () => {
    // 无授权登入，获取信息--操作
    if (REACT_APP_Version === "ShenZhenNoLogin") {
      push("/index.html?id=" + info.id);
    } else {
      push("/home");
    }
  };

  /** 触发上传 */
  const onOpenFileChooserTakeStock = () => {
    if (!!fileNodeTakeStock) fileNodeTakeStock.current.click();
  };

  /**盘点-上传图片*/
  const showPictureTakeStock = (e: any) => {
    getImg(e.target, 0.7).then(({ data, file }) => {
      const formData = new FormData();
      let data2 = dataURLtoFile(data);
      let fileFormat = convertBlobToFile(data2);

      console.log("文件大小", (file as any).size, fileFormat.size);

      //如果原图小于压缩后的就长传压缩图
      if (fileFormat.size < (file as any).size) {
        formData.append("file", fileFormat);
      } else {
        formData.append("file", file);
      }

      uploadImgFile(formData)
        .then((res) => {
          if (res?.statusCode === NetState.Ok) {
            const newRecord = {
              name: res.data.FrName,
              filePath: res.data.FrPath,
            };

            setImgList((e) => [...e, newRecord]);
          } else {
            return Promise.reject(res?.message || "上传失败\n网络出错");
          }
        })
        .catch((error) => {
          Toast.show(error?.message || "上传失败\n网络出错");
        });
    });
  };
  /**base64字符串转为blob对象 */
  const dataURLtoFile = (base64: any) => {
    let binary = atob(base64.split(",")[1]);
    let array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: "image/png",
    });
  };
  /**blob对象转为file对象 */
  const convertBlobToFile = (blob: any) => {
    // 创建File对象
    const file = new File([blob], "test.jpg", { type: blob.type });
    return file;
  };

  const deleteImg = (filePath: string) => {
    setImgList(imgList.filter((e) => e.filePath !== filePath));
  };

  return (
    <div className={style.equipmentDetails}>
      <NavBar onBack={() => setBack()} className={style.navBar}>
        <span onClick={createConsole}>{titles[infoType] || "台账详情"}</span>
      </NavBar>
      <div className={style.content}>
        <div className={style.equipmentInfo}>
          <div className={style.name}>
            <input
              ref={fileNode}
              style={{ display: "none" }}
              id="fileInpBtn"
              type="file"
              onChange={showPicture}
              accept="image/*"
              multiple={false}
            />
            <img src={getimgUrl()} alt="" onClick={() => onOpenFileChooser()} />
            <div>
              <div className={style.bold}>{info.deviceName}</div>
              <div className={style.number}>设备编号:{info.deviceNumber}</div>
            </div>
          </div>
          {!infoType && (
            <div>
              资产编号<span className={style.bold}>: {info.assetNumber}</span>
            </div>
          )}
          <div>
            使用部门<span className={style.bold}>: {info.departmentName}</span>
          </div>
          <div>
            规格型号
            <span className={style.bold}>: {info.specificationType}</span>
          </div>
          {!infoType && (
            <div>
              购置时间
              <span className={style.bold}>: {info.acquisitionTimes}</span>
            </div>
          )}
        </div>
        {infoType === "maintenanceWrok" ? (
          // 保养工作
          <div className={style.repaircontent}>
            <div className={style.repairoutside}>
              <div className={style.head}>
                <div className={style.title}>
                  <MaintenanceRecords
                    linearGradient={[
                      { color: "#48E8FF", offset: "0%" },
                      { color: "#2C7AFB", offset: "100%" },
                    ]}
                  />
                  保养工作
                </div>
              </div>
              <div className={style.content}>
                {maintenanceList.map((d) => (
                  <div
                    key={d.id}
                    onClick={() => {
                      if (!info.id || !d.id) {
                        Toast.show("数据错误");
                        return;
                      }
                      pageJump("maintenance", {
                        deviceId: info.id,
                        planId: d.planId,
                      });
                    }}
                  >
                    {d.maintainContent}
                    <span>
                      执行时间<span className={style.bold}>:</span>
                      {d.planExecuteDate}
                      <RightOutline />
                    </span>
                  </div>
                ))}
              </div>
              <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
            </div>
          </div>
        ) : infoType === "repaircontent" ? (
          // 维修内容
          <div className={style.repaircontent}>
            <div
              className={style.unplanned}
              onClick={() =>
                info.id && pageJump("maintainWork", { deviceId: info.id })
              }
            >
              <div className={style.title}>
                <MaintenanceRecords />
                计划外维修
              </div>
              <RightOutline />
            </div>
            <div className={style.repairoutside}>
              <div className={style.head}>
                <div className={style.title}>
                  <MaintenanceRecords
                    linearGradient={[
                      { color: "#48E8FF", offset: "0%" },
                      { color: "#2C7AFB", offset: "100%" },
                    ]}
                  />
                  计划内维修
                </div>
                <Tabs
                  onChange={(v) => {
                    setIsChange(true);
                    setProjectTypeEnum(Number(v));
                  }}
                >
                  <Tabs.Tab title="大修" key={0} />
                  <Tabs.Tab title="中修" key={1} />
                  <Tabs.Tab title="小修" key={2} />
                </Tabs>
              </div>
              <div className={style.content}>
                {workList.map((d) => (
                  <div
                    key={d.id}
                    onClick={() => {
                      if (!info.id || !d.id) {
                        Toast.show("数据错误");
                        return;
                      }
                      pageJump("maintainWork", {
                        deviceId: info.id,
                        workMaintainContentId: d.id,
                        maintainContent: d.maintainContent,
                        applicationProjectTypeEnum:
                          projectTypeEnum === 0 ? 1 : 0,
                      });
                    }}
                  >
                    {d.maintainContent}
                    <span>
                      计划时间<span className={style.bold}>:</span> {d.planDate}
                      <RightOutline />
                    </span>
                  </div>
                ))}
              </div>
              <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
            </div>
          </div>
        ) : infoType === "spotCheckSubmit" ? (
          // 提交点检
          <SpotCheckSubmit
            assetInfoId={info?.id}
            spotCheckInfoType={info?.spotCheckInfoType}
          />
        ) : infoType ? (
          <Record infoType={infoType} info={info} />
        ) : (
          // 默认工作页面
          <div className={style.work}>
            <div className={style.title}>工作</div>
            <div className={style.icons}>{clickIcons[REACT_APP_Version]}</div>
            <Modal
              // closeOnMaskClick
              visible={inventoryVisible}
              onClose={() => setInventoryVisible(false)}
              content={
                <div className={style.modal}>
                  <div className={style.title}>使用状态</div>
                  <div className={style.content}>
                    <Tabs
                      defaultActiveKey=""
                      onChange={(key) => setInventoryStatus(key)}
                    >
                      <Tabs.Tab title="启用" key={0} />
                      <Tabs.Tab title="停用" key={1} />
                      <Tabs.Tab title="闲置" key={2} />
                      <Tabs.Tab title="拟报废" key={3} />
                      <Tabs.Tab title="报废" key={4} />
                    </Tabs>
                    <div className={style.imgs}>
                      <input
                        ref={fileNodeTakeStock}
                        onChange={showPictureTakeStock}
                        style={{ display: "none" }}
                        id="fileInpBtn"
                        type="file"
                        accept="image/*"
                        multiple={false}
                        capture="environment" //ios有效调用后置摄像头，use后置
                      />
                      <div
                        className={style.upImg}
                        onClick={() => onOpenFileChooserTakeStock()}
                      >
                        点击上传
                      </div>
                      {imgList.map(({ name, filePath }) => (
                        <div key={filePath}>
                          <div
                            className={style.close}
                            onClick={() => deleteImg(filePath)}
                          />
                          <img src={FILE + filePath} alt={name} />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={style.footer}>
                    <Button
                      color="primary"
                      fill="outline"
                      onClick={() => {
                        setInventoryVisible(false);
                        setImgList([]);
                      }}
                    >
                      取消
                    </Button>
                    <Button
                      color="primary"
                      fill="solid"
                      onClick={submitInventory}
                    >
                      提交
                    </Button>
                  </div>
                </div>
              }
            />
          </div>
        )}
      </div>
      {isload && (
        <div className={style.mask}>
          <SpinLoading color="primary" style={{ "--size": "3rem" }} />
        </div>
      )}
    </div>
  );
};
export default EquipmentDetails;
