import { useMyLocation } from "@/hooks";
import { gettoken, IsAppLogin, login, IsEDCAppLogin } from "@axios";
import type { IGlobalStore } from "@store/global";
import ylcc from "@style/imgs/ylcc.png";
import { createConsole } from "@utils";
import { encryptByDES, history, push } from "@utils/common";
import { getWXCode } from "@utils/scan";
import { Toast, Mask } from "antd-mobile";
import Cookies from "js-cookie";
import { MobXProviderContext } from "mobx-react";
import { useCallback, useContext, useEffect, useState } from "react";
import "./index.less";

const Login = () => {
  const { state: LocationState } = useMyLocation<any>();
  const GlobalStore: IGlobalStore | undefined =
    useContext(MobXProviderContext)?.GlobalStore;
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [openId, setOpenId] = useState("");
  const [error, setError] = useState(false);
  const [visible, setVisible] = useState(false);

  const loginFinish = useCallback(
    (res: IRes<any>) => {
      if (res?.statusCode !== 200) {
        Toast.show(res?.message ?? "登录失败");
        setVisible(false);
        return;
      }
      if (res.value.code === 5033) {
        setOpenId(res.value.openId);
        setVisible(false);
        Toast.show("自动授权成功，首次请手动登录");
        return;
      }
      Toast.show("登录成功");
      localStorage.setItem("user", JSON.stringify(res.value));
      localStorage.setItem("lastRefreshTime", Date.now().toString());
      Cookies.set(
        "user_token",
        `${res.value.token.tokenType} ${res.value.token.authToken}`
      );
      if (GlobalStore) GlobalStore.loginCallback(res.value);
      else Toast.show("仓库异常");
      gettoken({
        AppId: res.value.appId,
        AppKey: res.value.appKey,
      }).then((res) => {
        localStorage.setItem("token1", res.data.SkToken);
      });
      setTimeout(() => {
        const last_page = Cookies.get("last_page");
        const last_state = Cookies.get("last_page_state");
        if (last_page) {
          console.log("last_state", last_state);
          if (last_state) {
            try {
              const state = JSON.parse(last_state);
              history.push(last_page, { ...state, formLogin: true });
            } catch (error) {
              history.push(last_page);
            }
          } else history.push(last_page);
          Cookies.remove("last_page");
          Cookies.remove("last_page_state");
        } else {
          push("/home");
        }
      }, 1000);
    },
    [GlobalStore]
  );

  // useEffect(() => {
    // const vConsole = new VConsole();
    // vConsole.showSwitch();
    // localStorage.clear();
    // Cookies.remove("user_token");
    // Cookies.remove("last_page");
    // Cookies.remove("token1");
    //   setVisible(false);
    //   //清除edc登入
    //   // let code = getUrlValue(window.location.search, "code");
    //   console.log(
    //     "LocationState",
    //     LocationState,
    //     "-----",
    //     window.location.search,
    //   );
    //   // 无授权登入，获取信息
    //   if (process.env.REACT_APP_Version === "ShenZhenNoLogin") return;
    //   // if (code) {
    //   //   setVisible(true);
    //   //   IsEDCAppLogin(code).then(loginFinish);
    //   //   Toast.show("自动授权中...");
    //   // } else {
    //   //   Toast.show("自动登录失败，请手动登入");
    //   // }
  // }, []);

  useEffect(() => {
    //微信自动登入
    console.log(
      "LocationState124",
      window.location.search,
      LocationState,
      LocationState?.openId
    );
  // 无授权登入，获取信息
      if (process.env.REACT_APP_Version === "ShenZhenNoLogin") return;
    if (LocationState?.isExit) {
      // 退出登录
      if (LocationState?.openId) {
        setOpenId(LocationState.openId);
      } else {
        if (process.env.NODE_ENV === "development") return;
        // 获取openID
        setError(true);
        Toast.show({
          content: "系统退出登录有误!\n请手动刷新重试",
          icon: "fail",
        });
        setTimeout(() => push("/login", { isExit: false }), 500);
      }
    } else {
      // console.log('cheshi',123465,LocationState?.isExit);
      // return; // TODO: 测试环境跳过获取openID
      // if (process.env.NODE_ENV === "development") return;
      // return;
      // 尝试自动登录
      getWXCode()
        .then((result) => {
          if (result) {
            return IsAppLogin(result);
          } else {
            throw new Error("getWXCode code Not true");
          }
        })
        .then((res) => {
          if (res?.statusCode === 200) {
            if (res?.value?.code === 5033) {
              // 该状态返回 openid
              // 等待用户 输入账号密码登入
              if (
                typeof res.value?.openId === "string" &&
                res.value.openId !== ""
              ) {
                setOpenId(res.value.openId);
              } else {
                setError(true);
                Toast.show({
                  content: "系统获取用户信息有误!",
                  icon: "fail",
                });
                setTimeout(() => push("/login", { isExit: false }), 500);
              }
            } else {
              loginFinish(res);
            }
            // 自动登录成功
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [LocationState, loginFinish]);

  const getUrlValue = (url: string, key: string) => {
    try {
      const query = url.split("?");
      const vars = query[1].split("&");
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        if (pair[0] === key) {
          return pair[1];
        }
      }
    } catch (e) {
      console.error(e);
    }

    return false;
  };

  const onlogin = () => {
    console.log("cs2", openId);

    if (error) return;
    if (!userName) {
      Toast.show("请输入账号");
      return;
    } else if (!password) {
      Toast.show("请输入密码");
      return;
      // } else if (process.env.NODE_ENV !== "development" && !openId) {
      //   Toast.show("系统获取用户信息出错\n刷新重试或联系管理员处理");
      //   return;
    }
    login({ userName, password: encryptByDES(password), openId }).then(
      loginFinish
    );
  };

  return (
    <div className="login_container">
      <Mask visible={visible} />
      <div className="login-backgroud">
        <div className="lgoin-top">
          <img src={ylcc} alt="" />
          <div className="login-description" onClick={createConsole}>
            {process.env.REACT_APP_Version === "ZhouShanYouLian"
              ? "舟山友联设备管理系统"
              : "孖洲岛设备管理平台"}
          </div>
        </div>
        <div className="input-contain">
          <div className="email-input input">
            <input
              type="email"
              placeholder="账号"
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>
          <div className="password-input input">
            <input
              type="password"
              placeholder="密码"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="binding-btn">
            <div className="button" onClick={onlogin}>
              登录
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
