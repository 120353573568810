import { Dateformat, NetState, MyUUIDValidateEasy } from "@utils";
import { GetFinancialInfo, GetInfo } from "@axios";
import {
  GetUpkeepApplication,
  SubmitUpkeepApplicationWork,
  postUPWPlanApproval,
  DeleteUpkeepApplication,
  putUpdateUpkeepApplication,
} from "@axios/form";
import { formInformationUpdate } from "@utils/form";
import Process from "@components/from/Process";
import Tamplate from "@components/from/Tamplate";
import { useMyLocation } from "@hooks";
import { push } from "@utils/common";
import { getFormInputDom } from "@utils/formDom";
import {
  Button,
  DatePicker,
  Form,
  Picker,
  Radio,
  Space,
  Toast,
} from "antd-mobile";
import type {
  PickerColumn,
  PickerValue,
  PickerValueExtend,
} from "antd-mobile/es/components/picker";
import { find, isFunction, map } from "lodash";
import React, {
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from "react";
import styles from "./index.module.less";
import { GetAssetInfoDepartmentDto } from "@axios/form";
import { MobXProviderContext } from "mobx-react";
import type { IGlobalStore } from "@store/global";

const ToastFail = (content: string) => Toast.show({ icon: "fail", content });
const editMaintainFinanceNumber = [
  "admin",
  "sb_zg",
  "sb_tt",
  "sb_jl",
  "sb_ct",
  "sb_gc",
  "sb_jd",
];

const Application: React.FC = () => {
  const GlobalStore: IGlobalStore | undefined =
    useContext(MobXProviderContext)?.GlobalStore;
  const { state: LocationState } = useMyLocation<any>();
  const [pickerCol, setPickerCol] = useState<Array<PickerColumn>>([]);
  const [pickerOption, setPickerOption] = useState<Form.PickerOption>({});
  const [pickerValue, setPickerValue] =
    useState<MaintainWorkApplication.TPickerValue>({});
  const [datePickerScope, setDatePickerScope] =
    useState<MaintainWorkApplication.TDatePickerPcope>({});
  const [datePickerValue, setDatePickerValue] = useState<{
    [key: string]: Date;
  }>({});
  const [maintainFinance, setMaintainFinance] = useState<Array<IFinancialInfo>>(
    []
  );
  const [formReadonly, setFormReadonly] = useState<boolean>(false);
  const [fileList, setFileList] = useState<Array<File>>([]);
  const [formId, setFormId] = useState("");
  const [formDataLoading, setFormDataLoading] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [isExaminationApproval, setIsExaminationApproval] =
    useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const uploadRef = useRef<HTMLInputElement | null>(null);
  const formItems = useRef<Array<Form.FormItem<any>>>([
    { key: "deviceName", title: "设备名称", option: { type: "input" } },
    { key: "deviceNumber", title: "设备编号", option: { type: "input" } },
    { key: "upkeepNumber", title: "保养编号", option: { type: "input" } },
    {
      key: "upkeepFinanceNumber",
      title: "保养财务编号",
      option: { type: "picker", editable: true },
      rules: [{ required: true, message: "请选择维修财务编号" }],
      event: {
        onClick: () =>
          setPickerOption({
            visible: true,
            setingFaield: "upkeepFinanceNumber",
          }),
      },
    },
    {
      key: "upkeepFinanceName",
      title: "保养财务名称",
      rules: [{ required: true, message: "请选择维修财务名称" }],
      option: { type: "picker", editable: true },
      event: {
        onClick: () =>
          setPickerOption({ visible: true, setingFaield: "upkeepFinanceName" }),
      },
    },
    { key: "upkeepDate", title: "保养日期", option: { type: "datePicker" } },
    { key: "departmentName", title: "保养部门", option: { type: "input" } },
    {
      key: "maintenanceApplicantName",
      title: "保养申请人",
      option: { type: "input" },
    },
    {
      key: "completeDate",
      title: "要求完工时间",
      option: { type: "datePicker", editable: true },
      rules: [{ required: true, message: "请选择维修财务名称" }],
      event: {
        onClick: () =>
          setPickerOption({ dateVisible: true, setingFaield: "completeDate" }),
      },
    },
    { key: "addicn", title: "申请部门设备主管", option: { type: "input" } },
    { key: "sqbmfzrName", title: "申请部门负责人", option: { type: "input" } },
    { key: "msName", title: "设备部主管", option: { type: "input" } },
    {
      key: "upkeepWayEnum",
      title: "保养方式",
      option: {
        type: "radio",
        editable: true,
        datas: [
          { label: "本厂工人保养", value: 0 },
          { label: "外包单位保养", value: 1 },
          { label: "外协单位保养", value: 2 },
        ],
      },
    },
    {
      key: "isSparesPurchasing",
      title: "采购备件",
      option: { type: "none", editable: true },
      render: (item: Form.FormItem<any>) => (
        <Radio.Group disabled={item?.option?.readonly}>
          <Space style={{ "--gap": "1rem" }}>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
          </Space>
        </Radio.Group>
      ),
    },
    {
      key: "clearingFormEnum",
      title: "结算方式",
      option: {
        type: "radio",
        editable: true,
        datas: [
          { label: "按内修价格体系", value: 0 },
          { label: "按项目总包(附审核后的总包协议/合同)", value: 1 },
          { label: "按项目个人总包(附审核后的总包协议/合同)", value: 2 },
        ],
      },
    },
    {
      key: "planStartUpDate",
      title: "计划开工时间",
      option: { type: "datePicker", editable: true },
      rules: [{ required: true, message: "请选择维修财务名称" }],
      event: {
        onClick: () =>
          setPickerOption({
            dateVisible: true,
            setingFaield: "planStartUpDate",
          }),
      },
    },
    {
      key: "planCompleteDate",
      title: "计划完工时间",
      option: { type: "datePicker", editable: true },
      rules: [{ required: true, message: "请选择维修财务名称" }],
      event: {
        onClick: () =>
          setPickerOption({
            dateVisible: true,
            setingFaield: "planCompleteDate",
          }),
      },
    },
    {
      key: "sbbJlMc",
      title: "设备部经理",
      option: { type: "input", showPlaceholder: false },
    },
  ]);
  const [formRef] = Form.useForm();
  const upPickerDataType = useRef<string>("");

  useEffect(() => {
    if (LocationState?.formId) {
      const LState = LocationState as Form.IRouterLocationState;
      setFormId(LState.formId);
      getForm(LState.formId);
      setFormReadonly(true);
    } else if (
      MyUUIDValidateEasy(LocationState?.deviceId) &&
      MyUUIDValidateEasy(LocationState?.planId)
    ) {
      const LState = LocationState as Form.IRouterLocationStateApplication;
      GetAssetInfoDepartmentDto(LState.deviceId)
        .then((res) => {
          if (res?.statusCode !== NetState.Ok) {
            throw new Error(res?.message || "表单出错");
          }
          setCompanyId(res.value.companyId);
          formRef.setFieldsValue({
            companyId: res.value.companyId,
            companyName: res.value.companyName,
          });
          getFinancialTree(res.value.companyId);
        })
        .catch((error: Error) => {
          Toast.show(error.message);
          setTimeout(() => {
            push("/home");
          }, 500);
        });
      GetInfo(LState.deviceId)
        .then((res) => {
          if (res?.statusCode !== NetState.Ok) {
            Toast.show(res.message);
            setTimeout(() => {
              push("/home");
            }, 500);
            return;
          }
          const user = localStorage.getItem("user");
          if (!user) return Toast.show("获取用户信息失败");
          const { user: userObj } = JSON.parse(user);
          let applicationProjectTypeEnum = 2;
          if ("applicationProjectTypeEnum" in LState) {
            applicationProjectTypeEnum =
              LState.applicationProjectTypeEnum === 0 ? 1 : 0;
          }
          formRef.setFieldsValue({
            upkeepWayEnum: 0,
            applicationProjectTypeEnum: applicationProjectTypeEnum,
            assetInfoId: res.value.id,
            clearingFormEnum: 0,
            departmentId: userObj.departmentUsers[0].departmentId,
            departmentName: userObj.departmentUsers[0].departmentName,
            isSparesPurchasing: 1,
            deviceName: res.value.deviceName,
            deviceNumber: res.value.deviceNumber,
            repairWayEnum: 0,
            repairsDate: new Date().toISOString(),
            repairsUserId: userObj.id,
            repairsUserName: userObj.name,
            maintenanceApplicantId: userObj.id,
            maintenanceApplicantName: userObj.name,
            upkeepDate: Dateformat(new Date()),
          });
        })
        .catch((e: Error) => {
          console.error(e);
        });
    } else {
      Toast.show("跳转表单出错");
      setTimeout(() => push("/home"), 500);
    }
  }, []);

  const getForm = (formId: string) => {
    setFormDataLoading(true);
    GetUpkeepApplication(formId)
      .then((res) => {
        if (res?.statusCode !== 200) return Promise.reject();
        setFormDataLoading(false);
        setCompanyId(res.value.companyId);
        res.value.isSparesPurchasing = res.value.isSparesPurchasing ? 1 : 0;
        formRef.setFieldsValue(res.value);
        getFinancialTree(res.value.companyId);
      })
      .catch(() => {
        Toast.show("获取表单内容出错");
        setTimeout(() => {
          push("/home");
        }, 500);
      });
  };
  const getFormF = () => getForm(formId);

  //提交
  const submit = async (values: any) => {
    let value;
    if (!LocationState?.planId)
      try {
        value = await formRef.validateFields();
      } catch (e) {}
    if (MyUUIDValidateEasy(value.upkeepFinanceNumber)) {
      ToastFail("请选择财务编号");
      return;
    }
    value.isSparesPurchasing = value.isSparesPurchasing === 1 ? true : false;

    setSubmitLoading(true);
    const promiseResult = await SubmitUpkeepApplicationWork(
      value,
      LocationState.planId
    );
    if (promiseResult?.statusCode === 200) {
      Toast.show({ icon: "success", content: "提交成功" });
      setFormReadonly(true);
      if (promiseResult?.value) {
        setFormId(promiseResult.value);
        getForm(promiseResult.value);
      }
    }
    setSubmitLoading(false);
  };

  useEffect(() => {
    if (!maintainFinance || !pickerOption.setingFaield || !pickerOption.visible)
      return;
    if (upPickerDataType.current === pickerOption.setingFaield) return;
    let col: PickerColumn = [];
    switch (pickerOption.setingFaield) {
      case "upkeepFinanceNumber":
        col = map(maintainFinance, (f) => ({
          key: f.id,
          label: f.code,
          value: f.code,
        }));
        break;
      case "upkeepFinanceName":
        col = map(maintainFinance, (f) => ({
          key: f.id,
          label: f.name,
          value: f.name,
        }));
        break;
    }
    upPickerDataType.current = pickerOption.setingFaield;
    setPickerCol([col]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickerOption.visible]);

  /**
   * 获取财务编码信息
   * @param companyId 公司ID
   */
  const getFinancialTree = async (companyId: string) => {
    GetFinancialInfo(companyId).then((res: IRes<Array<IFinancialInfo>>) => {
      if (res?.statusCode === NetState.Ok) setMaintainFinance(res.value);
    });
  };

  /**
   * 选择器确定值
   * @param value 值
   */
  const pickerConfirm = (value: PickerValue[], _extend: PickerValueExtend) => {
    const { setingFaield } = pickerOption;
    if (!setingFaield) return;
    const tempPickerValue = { ...pickerValue };
    if (
      setingFaield === "upkeepFinanceNumber" ||
      setingFaield === "upkeepFinanceName"
    ) {
      const temp = find(
        maintainFinance,
        (f) => f.code === value[0] || f.name === value[0]
      );
      tempPickerValue.upkeepFinanceName = [temp?.name];
      tempPickerValue.upkeepFinanceNumber = [temp?.code];
      formRef.setFieldValue("upkeepFinanceName", temp?.name);
      formRef.setFieldValue("upkeepFinanceNumber", temp?.code);
    } else tempPickerValue[setingFaield] = value;
    setPickerValue(tempPickerValue);
  };

  /**
   * 日期选择器确定值
   * @param value 日期
   */
  const datePickerConfirm = (value: Date, setingFaield: string) => {
    const tempDatePickerScope = { ...datePickerScope };
    switch (setingFaield) {
      case "upkeepDate":
        tempDatePickerScope["completeDate"] = { min: value };
        break;
      case "completeDate":
        tempDatePickerScope["upkeepDate"] = { max: value };
        break;
      case "planStartUpDate":
        tempDatePickerScope["planCompleteDate"] = { min: value };
        break;
      case "planCompleteDate":
        tempDatePickerScope["planStartUpDate"] = { max: value };
        break;
    }
    const tempDatePickerValue = { ...datePickerValue };
    tempDatePickerValue[setingFaield] = value;
    setDatePickerValue(tempDatePickerValue);
    setDatePickerScope(tempDatePickerScope);
    formRef.setFieldValue(setingFaield, Dateformat(value));
  };

  /** 页面元素 */
  const formItemsDom = useMemo<ReactNode>(() => {
    const nodeList: Array<ReactNode> = [];
    /** 根据权限区分可编辑字段 */
    const arr = ["upkeepFinanceNumber", "upkeepFinanceName"];
    const auditEdit = [
      "upkeepWayEnum",
      "isSparesPurchasing",
      "clearingFormEnum",
      "planStartUpDate",
      "planCompleteDate",
    ];
    const userRoles =
      GlobalStore?.user?.user?.userRoles.map((item: any) => item.roleCode) ??
      [];
    const departmentUsers =
      GlobalStore?.user?.user?.departmentUsers.map(
        (item: any) => item.departmentCode
      ) ?? [];
    // 设备部审核时可编辑判定
    const isSB = isExaminationApproval ? departmentUsers.includes("SB") : false;

    // 财务编码可编辑判定
    const isEditNumber =
      formReadonly && userRoles.length
        ? userRoles.some((item) => editMaintainFinanceNumber.includes(item))
        : false;

    formItems.current.forEach((item) => {
      if (item?.option?.editable) item.option.readonly = formReadonly;
      const { key, title, titleRender, option, event, rules } = item;

      if (title === "设备部主管")
        nodeList.push(
          <div key={`${key}-form-separated`} className="form-separated"></div>
        );

      if (
        !option?.readonly &&
        (option?.type === "picker" || option?.type === "datePicker")
      )
        nodeList.push(
          <div style={{ width: "100%", height: "100%", position: "relative" }}>
            <Form.Item
              name={key}
              rules={rules}
              key={`${key}-form-item`}
              label={
                titleRender
                  ? isFunction(titleRender)
                    ? titleRender()
                    : titleRender
                  : title
                  ? `${title}:`
                  : ""
              }
            >
              {item.render ? item.render(item) : getFormInputDom(item)}
            </Form.Item>
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                zIndex: 3,
                left: 0,
                top: 0,
              }}
              {...event}
            ></div>
          </div>
        );
      else if (arr.includes(key)) {
        if (item?.option?.readonly) item.option.readonly = isEditNumber;
        nodeList.push(
          <Form.Item
            name={key}
            rules={rules}
            key={`${key}-form-item`}
            label={
              titleRender
                ? isFunction(titleRender)
                  ? titleRender()
                  : titleRender
                : title
                ? `${title}:`
                : ""
            }
          >
            {item.render ? item.render(item) : getFormInputDom(item)}
          </Form.Item>
        );
      } else if (auditEdit.includes(key)) {
        if (item?.option?.readonly) item.option.readonly = !isSB;
        nodeList.push(
          <Form.Item
            name={key}
            rules={rules}
            key={`${key}-form-item`}
            label={
              titleRender
                ? isFunction(titleRender)
                  ? titleRender()
                  : titleRender
                : title
                ? `${title}:`
                : ""
            }
          >
            {item.render ? item.render(item) : getFormInputDom(item)}
          </Form.Item>
        );
      } else if (option?.type === "fileList")
        nodeList.push(
          <Form.Item
            name={key}
            rules={rules}
            key={`${key}-form-item`}
            label={
              <div>
                <div>大修方案(附件):</div>
                <Button
                  fill="outline"
                  color="primary"
                  style={{ "--border-radius": "1px" }}
                  onClick={() => uploadRef.current?.click()}
                >
                  + 上传
                </Button>
                <input
                  type="file"
                  ref={uploadRef}
                  multiple={false}
                  style={{ display: "none" }}
                  onChange={(e) =>
                    e.target.files &&
                    setFileList([...fileList, e.target.files[0]])
                  }
                />
              </div>
            }
          >
            {map(fileList, (file, i) => (
              <div key={`file-list-${i}`} className={styles.fileListItem}>
                <span>{file.name}</span>
                <Space style={{ "--gap": "0.2rem" }}>
                  <button style={{ color: "#1677ff" }}>下载</button>
                  <button
                    style={{ color: "#ff3141" }}
                    onClick={() => {
                      const temp = [...fileList];
                      temp.splice(i, 1);
                      setFileList(temp);
                    }}
                  >
                    删除
                  </button>
                </Space>
              </div>
            ))}
          </Form.Item>
        );
      else
        nodeList.push(
          <Form.Item
            name={key}
            rules={rules}
            key={`${key}-form-item`}
            label={
              titleRender
                ? isFunction(titleRender)
                  ? titleRender()
                  : titleRender
                : title
                ? `${title}:`
                : ""
            }
          >
            {item.render ? item.render(item) : getFormInputDom(item)}
          </Form.Item>
        );
    });
    return nodeList;
  }, [formItems, fileList, formReadonly, isExaminationApproval]);

  const auditCallback = (params: Form.IAuditReq) => {
    // 判断是否通过
    if (params.nodeStatus === 1) {
      return formRef
        .validateFields()
        .catch((error) => {
          console.error("auditCallback error", error);
        })
        .then((value): any => {
          if (MyUUIDValidateEasy(value.upkeepFinanceNumber)) {
            return { message: "请选择财务编号" };
          }
          value.isSparesPurchasing =
            value.isSparesPurchasing === 1 ? true : false;
          value.id = formId;
          return putUpdateUpkeepApplication(value);
        })
        .then((res: any) => {
          if (res?.statusCode === 200) {
            return postUPWPlanApproval(params);
          }
          return res;
        });
    } else {
      return putUpdateUpkeepApplication(params);
    }
  };

  /** 获取表单流程等信息 */
  const formInformationUpdateF = (id: TFormID) => {
    return formInformationUpdate(id).then((data) => {
      setIsExaminationApproval(data.isExaminationApproval);

      return data;
    });
  };

  return (
    <Tamplate
      title="保养申请单"
      formDataLoading={formDataLoading}
      companyId={companyId}
    >
      <Form
        form={formRef}
        onFinish={submit}
        layout="horizontal"
        footer={
          !formReadonly && (
            <Button
              loading={submitLoading}
              type="submit"
              color="primary"
              style={{ "--border-radius": "2px", padding: "0.3rem 1.5rem" }}
            >
              提交审批
            </Button>
          )
        }
      >
        {formItemsDom}
      </Form>

      {formId && (
        <Process
          formId={formId}
          auditCallback={auditCallback}
          onUpdateForm={getFormF}
          deleteForm={DeleteUpkeepApplication}
          formInformationUpdateF={formInformationUpdateF}
        />
      )}
      <Picker
        columns={pickerCol}
        onConfirm={pickerConfirm}
        visible={pickerOption.visible}
        onClose={() => setPickerOption({})}
        value={pickerValue[pickerOption.setingFaield || ""]}
      />
      <DatePicker
        visible={pickerOption.dateVisible}
        onClose={() => setPickerOption({})}
        {...datePickerScope[pickerOption.setingFaield || ""]}
        value={datePickerValue[pickerOption.setingFaield || ""]}
        onConfirm={(value) =>
          datePickerConfirm(value, pickerOption.setingFaield || "")
        }
      />
    </Tamplate>
  );
};

export default Application;
