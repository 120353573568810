import { useEffect } from "react";
import { Toast } from "antd-mobile";
import { Provider } from "mobx-react";
import Cookies from "js-cookie";
import { history, push } from "@utils/common";
import Routes from "./routes";
import stores from "@store";
import "./App.css";

function App() {
  useEffect(() => {
     // 无授权登入，获取信息
     if (process.env.REACT_APP_Version === "ShenZhenNoLogin") return;
    if (
      history.location.pathname !== process.env.REACT_APP_PREFIX + "/login" &&
      !Cookies.get("user_token")
    ) {
      Toast.show("未登录");
      Cookies.set(
        "last_page",
        history.location.pathname + history.location.search
      );
      push("/login");
    }
  }, []);

  return (
    <Provider {...stores}>
      <Routes />
    </Provider>
  );
}

export default App;
