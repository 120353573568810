import { get, post } from "./tools";
import { MOCK_API, FILE_API } from "./config";

// 用户登录
export const login = (data: any) =>
  post({ url: `${MOCK_API}/Login/Login`, data });
/** app登录判断 */
export const IsAppLogin = (code: string) =>
  post({ url: `${MOCK_API}/Login/IsAppLogin`, config: { params: { code } } });

/** EDC登录判断 */
export const IsEDCAppLogin = (code: string) =>
  post({ url: `${MOCK_API}/Login/IsEDCAppLogin`, config: { params: { code } } });

// 获取token
export const gettoken = (params: any) =>
  get({ url: `${FILE_API}/gettoken`, config: { params } });
// 提交服务器要授权
export const authorizationWeChatQuery = (url = "") =>
  get({ url: `${MOCK_API}/App/GetJsAuth`, config: { params: { url } } });
// 刷新
export const RefreshToken = (data: {
  userName: string;
  refreshToken: string;
}) => post({ url: `${MOCK_API}/Login/RefreshToken`, data });
//退出登入
export const Signout = () => get({ url: `${MOCK_API}/Login/Signout` });
//获取设备信息
export const GetInfo = (id: string) =>
  get({ url: `${MOCK_API}/BasicInformation/GetInfo?id=${id}` });

// 获取部门
export const GetDepartments = () =>
  get({ url: `${MOCK_API}/Organization/GetDepartments` });
// 根据条件搜索流程信息
export const getWorkflowInstanceInfo = (params: any) =>
  get({
    url: `${MOCK_API}/Workflow/GetWorkflowInstanceInfo`,
    config: { params },
  });
interface IpostBatchApproval {
  formId: string;
  workflowFormId: string;
  currentStepId: string;
}
// 批量审批
export const postBatchApproval = (data: IpostBatchApproval[]) =>
  post({ url: `${MOCK_API}/Workflow/BatchApproval`, data });
// ledger
interface IPostLedgerTable {
  id?: string;
  condition: string; // 筛选符合名字的数据
  isAsc: boolean; // 排序true：升序；false：降序
  sTime: string;
  eTime: string;
  companyId: string[];
  departmentId: string[];
  pageNO: number;
  pageSize: number; // 当前页显示数据条数大小
}

/** 基本资料-台账 获取表格数据
 * @param {IPostLedgerTable} data IPostLedgerTable
 * @returns
 */
export const postLedgerTable = (data: IPostLedgerTable) =>
  post({ url: `${MOCK_API}/BasicInformation/GetAssetInfoList`, data });

/** 基本资料-台账 资产台账筛选条件 */
export const getLedgerTree = () =>
  get({ url: `${MOCK_API}/BasicInformation/GetLedgerTree` });
// ------ ledger - end ------

/**
 * 提交盘点状态
 * @param data assetInfoId:设备id,useStatus:盘点状态 0:启用 | 1:停用 | 2:闲着 | 3:拟报废 | 4:报废
 * @returns
 */
export const submitAssetInfoInventory = (params: {
  assetInfoId: string;
  useStatus: string;
}, data: any) => post({ url: `${MOCK_API}/App/AssetInfoInventory`, config: { params }, data });
/**
 * 获取盘点树
 * @returns
 */
export const getInventoryTree = () =>
  get({ url: `${MOCK_API}/App/GetInventoryTree` });
/**
 * 查询维修工作/项目列表(未开始维修)
 * @param pageNo 页码
 * @param pageSize 数量
 * @param condition 设备名|关键字
 * @param projectTypeEnum 维修类型 0大修 | 1中修 | 2小修
 * @returns
 */
export const GetMaintainWorkList = (
  pageNo: number,
  pageSize: number,
  condition: string,
  projectTypeEnum: number
) =>
  get({
    url: `${MOCK_API}/MaintainWork/GetMaintainWorkList?pageNo=${pageNo}&pageSize=${pageSize}&condition=${condition}&projectTypeEnum=${projectTypeEnum}&maintainPlanFinishStatusEnum=0`,
  });
/**
 * 根据维修工作/项目id获取维修工作/项目内容(未开始维修)
 * @param maintainWorkId 维修工作/项目id
 * @param condition 维修工作/项目名称
 * @param projectTypeEnum 维修类型 0大修 | 1中修 | 2小修
 * @returns 维修项目工程内容列表
 */
export const GetWorkMaintainContent = (
  maintainWorkId: string,
  condition: string,
  projectTypeEnum: number
) =>
  get({
    url: `${MOCK_API}/MaintainWork/GetWorkMaintainContent?maintainWorkId=${maintainWorkId}&condition=${condition}&projectTypeEnum=${projectTypeEnum}&maintainPlanFinishStatusEnum=0`,
  });
/**
 * 获取二级保养工作
 * @param pageNo 页码
 * @param pageSize 数量
 * @param condition 设备名|关键字
 * @returns
 */
export const GetUpkeepWorkList = (
  pageNo: number,
  pageSize: number,
  condition: string
) =>
  get({
    url: `${MOCK_API}/UpkeepWork/GetUpkeepWorkList?pageNo=${pageNo}&pageSize=${pageSize}&condition=${condition}&upkeepPlanFinishStatusEnum=0`,
  });
/**
 * 提交非计划维修申请单
 * @param projectType 项目维修类型 0大修 | 1中修 | 2小修 | 3非计划维修
 * @param data 申请单数据
 * @returns
 */
export const SubmitUnplannedMaintainApplication = (
  projectType = "2",
  data: MaintainWorkApplication.ApplicationData
) =>
  post({
    url: `${MOCK_API}/MaintainWork/UnplannedSubmitMaintainApplication?projectType=${projectType}`,
    data,
  });
/**
 * 通过台账id获取保养内容
 * @param infoId 设备id
 * @returns
 */
export const GetUpkeepContentDtoInfo = (infoId = "") =>
  get({ url: `${MOCK_API}/App/GetUpkeepContentDtoInfo?infoId=${infoId}` });
/**
 * 创建一级保养记录
 * @param data
 * @returns
 */
export const createStairStairUpkeepRecord = (data = {}) =>
  post({ url: `${MOCK_API}/App/CreateStairStairUpkeepRecord`, data });
/**
 * 获取设备维修记录
 * @param infoId 设备id
 * @param pageNO 页码
 * @param pageSize 数量
 * @returns
 */
export const GetInfoMtProjectSettlementList = (
  infoId: string,
  pageNO = 1,
  pageSize = 20
) =>
  get({
    url: `${MOCK_API}/MaintainWork/GetInfoMtProjectSettlementList?infoId=${infoId}&pageNO=${pageNO}&pageSize=${pageSize}`,
  });
/**
 * 获取设备一级保养记录
 * @param infoId 设备id
 * @param pageNO 页码
 * @param pageSize 数量
 * @returns
 */
export const GetStairStairUpkeepRecordList = (
  infoId: string,
  pageNO = 1,
  pageSize = 20
) =>
  get({
    url: `${MOCK_API}/app/GetStairStairUpkeepRecordList?infoId=${infoId}&pageNO=${pageNO}&pageSize=${pageSize}`,
  });
/**
 * 获取一级保养记录详细信息
 * @param id 一级保养记录id
 * @returns
 */
export const GetUpdateStairUpkeepRecordById = (id: string) =>
  get({
    url: `${MOCK_API}/app/GetUpdateStairUpkeepRecordById?id=${id}`,
  });
/**
 * 获取二级保养记录信息
 * @param id 设备id
 * @param pageNO 页码
 * @param pageSize 数量
 * @returns
 */
export const GetUkProjectSettlementList = (
  id: string,
  pageNO = 1,
  pageSize = 20
) =>
  get({
    url: `${MOCK_API}/UpkeepWork/GetUkProjectSettlementList?id=${id}&pageNO=${pageNO}&pageSize=${pageSize}`,
  });
/**
 * 查询财务编码信息
 * @param companyId 公司id
 * @returns
 */
export const GetFinancialInfo = (companyId: string) =>
  get({
    url: `${MOCK_API}/FinancialInfo/GetFinancialInfo?companyId=${companyId}`,
  });
/**
 * 查询表单流程相关信息
 * @param formId 表单id
 * @returns
 */
export const GetWorkInstanceByFormId = (formId: string) =>
  get({
    url: `${MOCK_API}/Workflow/GetWorkInstanceByFormId?formId=${formId}`,
  });
// 上传台账图片
export const uploadImg = (params: {
  infoId: string;
  path: string;
}): IAxios<boolean> =>
  post({
    url: `${MOCK_API}/App/ImgFilePath`,
    config: { params },
  });
// 上传图片请求
export const uploadImgFile = (params: any) => {
  const token = localStorage.getItem("token1");
  return post({
    url: `${FILE_API}/upload`,
    data: params,
    config: { params: { token, owner: "Ams" } },
  });
};

/** 文件服务器删除文件
 * @param token
 * @param path
 * @returns
 */
export const DeleteFile = (token: string, path: string) =>
  get({
    url: `${FILE_API}/delete`,
    config: { params: { token, path } },
  });

/** 获取公司 */
export const GetCompanyInfo = () =>
  get({ url: `${MOCK_API}/Organization/GetCompanyInfo` });

// ------ 船舶 ------

interface IGetShipInfoList {
  id?: string;
  condition: string; // 筛选符合名字的数据
  isAsc: boolean; // 排序true：升序；false：降序
  departmentId: string[];
  pageNO: number;
  pageSize: number; // 当前页显示数据条数大小
}
/** 基本资料-台账 获取船舶表格数据
 * @param {IPostLedgerTable} data IPostLedgerTable
 * @returns
 */
export const GetShipInfoList = (data: IGetShipInfoList) =>
  post({ url: `${MOCK_API}/ShipInfo/GetShipInfoList`, data });

/** 获取船舶详细信息
 * @param {string} shipInfoId 船ID
 * @returns
 */
export const GetShipInfo = (shipInfoId: string) =>
  get({
    url: `${MOCK_API}/ShipInfo/GetShipInfo`,
    config: { params: { shipInfoId } },
  });

/** 维修记录 时间默认今年 */
export const MaintenanceRecord = (
  params: NAxios.IMaintenanceRecordParams
): IAxios<NAxios.IMaintenanceRecordResult> =>
  get({
    url: `${MOCK_API}/ShipInfo/MaintenanceRecord`,
    config: { params },
  });

/** 船舶维保记录
 * 接口写 船舶维修记录
 * @param params
 * @returns
 */
export const ShipMaintenanceRecord = (
  params: NAxios.IShipMaintenanceRecordParams
): IAxios<NAxios.IShipMaintenanceRecordResult> =>
  get({
    url: `${MOCK_API}/ShipInfo/ShipMaintenanceRecord`,
    config: { params },
  });
