import { get, post, del, put } from "./tools";
import { MOCK_API } from "./config";

//#region 公共
/** 船舶快捷查询 */
export const GetShipInfoListQuick = (): IAxios<
  NShipForm.IGetShipInfoListQuickRes[]
> => get({ url: `${MOCK_API}/ShipInfo/GetShipInfoListQuick` });
//#endregion

/** 获取船舶维保工作列表表头 */
export const GetShipMaintenanceWorkTableHead =
  (): IAxios<NShipForm.IGetShipMaintenanceWorkTableHeadRes> =>
    get({
      url: `${MOCK_API}/ShipMaintenanceWork/GetShipMaintenanceWorkTableHead`,
    });

/** 获取船舶维保工作列表数据 */
export const GetShipMaintenanceWorkList = (params: AxiosForm.IPagination) =>
  get({
    url: `${MOCK_API}/ShipMaintenanceWork/GetShipMaintenanceWorkList`,
    config: { params },
  });

/** 获取维保记录
 * @param {IGetShipMaintenanceWorkContentList} params
 * @returns {IGetShipMaintenanceWorkContentListRes}
 */
export const GetShipMaintenanceWorkContentList = (
  params: NShipForm.IGetShipMaintenanceWorkContentList
): IAxios<NShipForm.IGetShipMaintenanceWorkContentListRes> =>
  get({
    url: `${MOCK_API}/ShipMaintenanceWork/GetShipMaintenanceWorkContentList`,
    config: { params },
  });

/** 保存维保记录 */
export const SaveShipMaintenanceWorkContent = (
  data: NShipForm.ISaveShipMaintenanceWorkContent
): IAxios<any> =>
  post({
    url: `${MOCK_API}/ShipMaintenanceWork/SaveShipMaintenanceWorkContent`,
    data,
  });

/* 获取结算金额 */
export const GetSettlementMoney = (
  params: NShipForm.IGetSettlementMoney
): IAxios<number> =>
  get({
    url: `${MOCK_API}/ShipMaintenanceWork/GetSettlementMoney`,
    config: { params },
  });

// ------ 船舶汇总 开始 ------
// 获取多条计划汇总信息
export const getShipPlanApprovalFormInfo = (formId: TFormID) =>
  get({
    url: `${MOCK_API}/Workflow/GetPlanApprovalFormInfo`,
    config: { params: { formId } },
  });

interface IRecordOptionsCB {
  dataIndex: string;
  title: string;
}
// 船舶年度计划 表头
export const GetShipYearPlanTableHead = (): IAxios<{
  columns: IRecordOptionsCB[];
}> => get({ url: `${MOCK_API}/ShipYearMaintainPlan/GetShipYearPlanTableHead` });

// 船舶维保计划 表头
export const GetShipMaintenancePlanTableHead = (): IAxios<{
  columns: IRecordOptionsCB[];
}> =>
  get({
    url: `${MOCK_API}/ShipMaintenancePlan/GetShipMaintenancePlanTableHead`,
  });

// 船舶修理预算计划 表头
export const GetShipRepairBudgetPlanTableHead = (): IAxios<{
  columns: IRecordOptionsCB[];
}> =>
  get({
    url: `${MOCK_API}/ShipRepairBudgetPlan/GetShipRepairBudgetPlanTableHead`,
  });

// 船舶物料购置计划 表头
export const GetShipOtherMaterialGzPlanTableHead = (): IAxios<{
  columns: IRecordOptionsCB[];
}> =>
  get({
    url: `${MOCK_API}/ShipOtherMaterialGzPlan/GetShipOtherMaterialGzPlanTableHead`,
  });

// 船舶年度修理计划节点审批
export const ShipYearMaintainPlanApprovalNextStep = (params: Form.IAuditReq) =>
  post({
    url: `${MOCK_API}/ShipYearMaintainPlan/ShipYearMaintainPlanApprovalNextStep`,
    config: { params },
  });

// 船舶维保计划审批
export const ShipMaintenancePlanApprovalNextStep = (params: Form.IAuditReq) =>
  post({
    url: `${MOCK_API}/ShipMaintenancePlan/ShipMaintenancePlanApprovalNextStep`,
    config: { params },
  });

// 船舶修理预算计划审批
export const ShipRepairBudgetPlanApprovalNextStep = (params: Form.IAuditReq) =>
  post({
    url: `${MOCK_API}/ShipRepairBudgetPlan/ShipRepairBudgetPlanApprovalNextStep`,
    config: { params },
  });

// 船舶物料购置计划审批
export const ShipOtherMaterialGzPlanApprovalNextStep = (
  params: Form.IAuditReq
) =>
  post({
    url: `${MOCK_API}/ShipOtherMaterialGzPlan/ShipOtherMaterialGzPlanApprovalNextStep?${params}`,
    config: { params },
  });

/** 获取船舶年度修理计划详情 */
export const GetShipYearMaintainPlanDetail = (id: string): IAxios<any> =>
  get({
    url: `${MOCK_API}/ShipYearMaintainPlan/GetShipYearMaintainPlanDetail`,
    config: { params: { id } },
  });
/** 获取船舶维保计划详情 */
  export const GetShipMaintenancePlanDetail = (id: string): IAxios<any> =>
  get({
    url: `${MOCK_API}/ShipMaintenancePlan/GetShipMaintenancePlanDetail`,
    config: { params: { id } },
  });
/** 获取船舶修理预算计划详情 */
  export const GetShipRepairBudgetPlanDetail = (id: string): IAxios<any> =>
  get({
    url: `${MOCK_API}/ShipRepairBudgetPlan/GetShipRepairBudgetPlanDetail`,
    config: { params: { id } },
  });
// ------ 船舶汇总 结束 ------

// ------ 入库 ------
/** 获取入库单
 * @param {string} shipStorageRequestId 表单ID
 * @returns
 */
export const GetInStorageInfo = (shipStorageRequestId: string) =>
  get({
    url: `${MOCK_API}/ShipInfoSpareTree/GetInStorageInfo`,
    config: { params: { shipStorageRequestId } },
  });
/** 入库审批
 * @param params 审核参数
 * @param data 表单数据
 * @returns
 */
export const ShipInStorageRequestApproval = (
  params: Form.IAuditReq,
  data: Object
) =>
  put({
    url: `${MOCK_API}/ShipInfoSpareTree/ShipInStorageRequestApproval`,
    data,
    config: { params },
  });
/** 删除入库单
 * @param {string} id 表单ID
 * @returns
 */
export const DeleteInStorageFormDto = (id: string) =>
  del({
    url: `${MOCK_API}/ShipInfoSpareTree/DeleteInStorageFormDto?id=${id}`,
    config: { params: { id } },
  });
/**获取船舶维修类型 */
export const GetRepairBudgetType = () =>
  get({ url: `${MOCK_API}/EnumKeyValue/GetRepairBudgetType` });

/** ---------- 船舶维修申请单 ---------- */
/** 获取维修申请单信息 */
export const GetShipMaintainApplication = (id: string) =>
  get({
    url: `${MOCK_API}/ShipMaintainApplication/GetShipMaintainApplication`,
    config: { params: { id } },
  });
/** 维修申请单 审批 */
export const ApprovalShipMaintainApplication = (
  params: Form.IAuditReq,
  data: any
) => {
  return post({
    url: `${MOCK_API}/ShipMaintainApplication/ApprovalShipMaintainApplication`,
    data,
    config: { params },
  });
};
/** 删除维修申请/工程数据 */
export const DeleteShipMaintainApplication = (id: TFormID) =>
  del({
    url: `${MOCK_API}/ShipMaintainApplication/DeleteShipMaintainApplication?id=${id}`,
  });

/** ---------- 船舶备件物料申请单 ---------- */
/** 获取件备件物料申请单信息 */
export const GetShipSparePartApplication = (id: TFormID) =>
  get({
    url: `${MOCK_API}/ShipSparePartApplication/GetShipSparePartApplication?id=${id}`,
  });
/**船舶对应备件信息 */
export const GetDeviceManagementName = (shipId: TFormID) =>
  get({
    url: `${MOCK_API}/ShipInfo/GetDeviceManagementName?shipId=${shipId}`,
  });
/** 备件物料申请单 审批 */
export const ApprovalShipSparePartApplication = (
  params: Form.IAuditReq,
  data: any
) => {
  return post({
    url: `${MOCK_API}/ShipSparePartApplication/ApprovalShipSparePartApplication`,
    data,
    config: { params },
  });
};
/** 备件物料申请单 删除 */
export const DeleteShipSparePartApplication = (id: TFormID) =>
  del({
    url: `${MOCK_API}/ShipSparePartApplication/DeleteShipSparePartApplication?id=${id}`,
  });

/** ---------- 船舶工程配合申请单 ---------- */
/** 获取工程配合申请单信息 */
export const GetShipGcPhApplication = (id: TFormID) =>
  get({
    url: `${MOCK_API}/ShipGcPhApplication/GetShipGcPhApplication?id=${id}`,
  });
/**根据工作内容id加载工程内容及要求 */
export const GetShipApplicationMaintainContents = (id: TFormID) =>
  get({
    url: `${MOCK_API}/ShipMaintainApplication/GetShipApplicationMaintainContents?workContentId=${id}`,
  });
/** 工程配合申请单 审批 */
export const ApprovalShipGcPhApplication = (
  params: Form.IAuditReq,
  data: any
) => {
  return post({
    url: `${MOCK_API}/ShipGcPhApplication/ApprovalShipGcPhApplication`,
    data,
    config: { params },
  });
};
/** 工程配合申请单 删除 */
export const DeleteShipGcPhApplication = (id: TFormID) =>
  del({
    url: `${MOCK_API}/ShipGcPhApplication/DeleteShipGcPhApplication`,
    config: { params: { id } },
  });

/** ---------- 船舶外协单申请单 ---------- */
/** 获取外协单申请数据 */
export const GetShipWxApplication = (id: TFormID) =>
  get({
    url: MOCK_API + "/ShipWxApplication/GetShipWxApplication",
    config: { params: { id } },
  });
/** 外协单申请节点审批
 * @param params 审核参数
 * @param data 表单数据
 * @returns
 */
export const ApprovalShipWxApplication = (
  params: Form.IAuditReq,
  data: Object
) =>
  put({
    url: `${MOCK_API}/ShipWxApplication/ApprovalShipWxApplication`,
    data,
    config: { params },
  });
/** 删除外协单申请数据
 * @param {string} id 表单ID
 * @returns
 */
export const DeleteShipWxApplication = (id: string) =>
  del({
    url: `${MOCK_API}/ShipWxApplication/DeleteShipWxApplication`,
    config: { params: { id } },
  });

/** ---------- 船舶报批单申请单 ---------- */
/** 获取报批单申请数据 */
export const GetShipBpdApplication = (id: string) =>
  get({
    url: MOCK_API + "/ShipBpdApplication/GetShipBpdApplication",
    config: { params: { id } },
  });
/** 报批单申请节点审批
 * @param params 审核参数
 * @param data 表单数据
 * @returns
 */
export const ApprovalShipBpdApplication = (
  params: Form.IAuditReq,
  data: Object
) =>
  put({
    url: `${MOCK_API}/ShipBpdApplication/ApprovalShipBpdApplication`,
    data,
    config: { params },
  });
/** 删除报批单申请数据
 * @param {string} id 表单ID
 * @returns
 */
export const DeleteShipBpdApplication = (id: string) =>
  del({
    url: `${MOCK_API}/ShipBpdApplication/DeleteShipBpdApplication`,
    config: { params: { id } },
  });
/** 获取报批单附带的维修申请单id
 * @param {uuid} lxId 船舶报批单 预算部分ID
 * @returns
 */
export const GetLxMaintainApplicationId = (lxId: string): IAxios<string> =>
  get({
    url: MOCK_API + "/ShipBpdApplication/GetLxMaintainApplicationId",
    config: { params: { lxId } },
  });
/** 获取维修工程数据 报批单 */
export const GetShipMaintainGcDApplication = (id: string) =>
  get({
    url: MOCK_API + "/ShipMaintainApplication/GetShipMaintainGcDApplication",
    config: { params: { id } },
  });
