import { useEffect, useState, createRef } from "react";
import { Toast, NavBar, TextArea } from "antd-mobile";
import { history, push, formatSearch, getImg, replace } from "@utils/common";
import Table from "@components/table";
import { scan } from "@utils/scan";
import { NetState } from "@utils";
import {
  GetUpkeepContentDtoInfo,
  uploadImgFile,
  createStairStairUpkeepRecord,
} from "@axios";
import { FILE } from "@axios/config";
import style from "./index.module.less";

/**
 * 一级保养
 */
const Maintain = () => {
  const [infoId, setInfoId] = useState("");
  const [remark, setRemark] = useState("");
  const [maintainList, setMaintainList] = useState<any[]>([]);
  const [imgList, setImgList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  var fileNode: any = createRef();
  const columns: tableType.columnsType[] = [
    { name: "序号", render: (data, i) => i + 1 },
    { name: "内容详情", dataIndex: "content" },
    {
      name: "是否已保养",
      render: (data, i) => (
        <input
          type="checkbox"
          checked={data.isCheck}
          onChange={() =>
            setMaintainList((data) => {
              if (data[i]) data[i].isCheck = !data[i].isCheck;
              return [...data];
            })
          }
        />
      ),
    },
  ];

  useEffect(() => {
    const obj: any = formatSearch();
    if (obj.id) {
      setInfoId(obj.id);
      getUpkeepContent(obj.id);
    } else {
      // 没有传id则调用扫码获取设备id
      scan()
        .then((value) => {
          setInfoId(value);
          getUpkeepContent(value);
          replace("/maintain?id=" + value);
        })
        .catch((error) => {
          Toast.show(error);
          setTimeout(() => {
            history.go(-1);
          }, 500);
        });
    }
  }, []);

  /**
   * 获取设备一级保养内容
   * @param id 设备id
   */
  const getUpkeepContent = (id: string) => {
    GetUpkeepContentDtoInfo(id).then((res) => {
      if (res?.statusCode !== NetState.Ok) {
        Toast.show(res.message);
        return;
      }
      setMaintainList(res.value.map((e: any) => ({ ...e, isCheck: false })));
    });
  };
  /**
   * 触发上传
   */
  const onOpenFileChooser = () => {
    if (!!fileNode) fileNode.current.click();
  };
  /**
   * 上传图片
   */
  const showPicture = (e: any) => {
    getImg(e.target).then(({ data, file }) => {
      const formData = new FormData();
      formData.append("file", file);
      uploadImgFile(formData).then((res) => {
        if (res?.statusCode !== NetState.Ok) {
          Toast.show(res.message);
          return;
        }
        setImgList([...imgList, res.data]);
      });
    });
  };
  /**
   * 提交保养
   */
  const submit = () => {
    if (loading) return;
    const stairStairUpkeepContents = maintainList.map(
      ({ content, isCheck }) => ({
        content,
        isCheck,
        stairStairUpkeepRecordId: infoId,
      })
    );
    const stairStairUpkeepFiles = imgList.map(
      ({ FrName: name, FrPath: filePath }) => ({
        name,
        filePath,
        stairStairUpkeepRecordId: infoId,
      })
    );
    const params = {
      assetInfoId: infoId,
      remark,
      stairStairUpkeepContents,
      stairStairUpkeepFiles,
    };
    if (!imgList.length) {
      Toast.show("上传图片未成功");
    }
    if (
      (stairStairUpkeepContents.length &&
        !!stairStairUpkeepContents.find((item) => item.isCheck)) ||
      !!remark
    ) {
      setLoading(true);
      createStairStairUpkeepRecord(params).then((res) => {
        setLoading(false);
        if (res?.statusCode !== NetState.Ok) {
          Toast.show(res.message);
          return;
        }
        Toast.show("保养提交成功");
        setTimeout(() => {
          push("/home");
        }, 500);
      });
    } else {
      Toast.show("请至少选择一种保养内容或填写备注");
    }
  };
  return (
    <div className={style.maintain}>
      <NavBar onBack={() => history.go(-1)} className={style.navBar}>
        一级保养
      </NavBar>
      <div className={style.content}>
        <Table
          className={style.table}
          columns={columns}
          dataSource={maintainList}
        />
        <div className={style.imgs}>
          <input
            ref={fileNode}
            style={{ display: "none" }}
            id="fileInpBtn"
            type="file"
            onChange={showPicture}
            accept="image/*"
            multiple={false}
            capture="environment" //ios有效调用后置摄像头，use后置
          />
          <div className={style.upImg} onClick={onOpenFileChooser}>
            上传图片
          </div>
          {imgList.map(({ FrName, FrPath }) => (
            <div key={FrName}>
              <img src={FILE + FrPath} alt="" />
            </div>
          ))}
        </div>
        <div className={style.remarks}>
          <span>保养备注：</span>
          <TextArea
            rows={4}
            placeholder="选填"
            onChange={(v) => setRemark(v)}
          />
        </div>
        <div className={style.submit}>
          <button className="button" onClick={submit}>
            确认提交
          </button>
        </div>
      </div>
    </div>
  );
};
export default Maintain;
