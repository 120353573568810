import {
  WorkflowInstanceExist,
  GetWorkflowInfoByFormId,
  // IsWithdrawalForm,
  IsDeleteForm,
  UnplannedSubmitMaintainApplication
} from '@axios/form';

/**
 * 表单信息更新
 * @param params AuditInterfaceCycleParams
 */
 export async function formInformationUpdate(formId: TFormID) {
  const result: Form.IFormInformation = {
    IsDeleteForm: false, // 能否删除
    // IsWithdrawalForm: false, // 能否撤回
    isExaminationApproval: false, // 能否审核
    isWorkflow: false, // 流程是否存在
    data: {
      value: {
        item1: 0,
        item2: '', // 流程id
        item3: '', // 当前步骤id
        item4: '', // 发起人id
      },
    }
  };
  const res: any = await WorkflowInstanceExist(formId);
  if (res?.statusCode !== 200) return Promise.reject(res);
  if (!res.value) return result;
  const data = await GetWorkflowInfoByFormId(formId);
  if (data?.statusCode !== 200) return Promise.reject(res);
  result.data = data;

  const arr = [
    IsDeleteForm(formId, data.value['item3']),
    // IsWithdrawalForm(formId),
    UnplannedSubmitMaintainApplication(data.value['item2']),
  ];
  const values: any = await Promise.all(arr);
  // 判断请求失败
  for (let index = 0; index < values.length; index++) {
    if (values[index]?.statusCode !== 200) {
      return Promise.reject(values);
    }
  }
  result['IsDeleteForm'] = values[0].value;
  // result['IsWithdrawalForm'] = values[1].value;
  result['isExaminationApproval'] = values[1].value;
  result['isWorkflow'] = true;
  return result;
}