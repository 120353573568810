import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import type { FC } from "react";
import { map, trim } from "lodash";
import {
  Button,
  Grid,
  TextArea,
  Steps,
  Toast,
  Dialog,
  Popup,
  Form,
  Radio,
  Space,
  DotLoading,
} from "antd-mobile";
import { CheckCircleFill, CloseCircleFill } from "antd-mobile-icons";
import { MyUUIDValidateEasy, NetState } from "@/utils";
import { formInformationUpdate } from "@/utils/form";
import { ProcessRejected } from "@components/icon";
import {
  GetWorkflowStepsPresentList,
  getWorkflowNodeInstanceList,
} from "@axios/form";
import styles from "./index.module.less";
import { history } from "@/utils/common";

const ProcessBtn: FC<ProcessBtn.IPorps> = ({
  formInformation,
  isLoading,
  isRejected,
  onClick,
  deleteForm,
}) => {
  const [rejectedVisible, setRejectedVisible] = useState<boolean>(false);
  const [rejectedSteps, setRejectedSteps] = useState<
    Array<Process.IRejectedStep>
  >([]);
  const [rejectedForm] = Form.useForm();
  const rejectedFormInitValues = useRef({});

  useEffect(() => {
    if (!formInformation || !rejectedVisible) return;
    getWorkflowNodeInstanceList(formInformation.data.value.item2).then(
      (res: IRes<Array<Process.IRejectedStep>>) => {
        if (res?.statusCode !== NetState.Ok)
          return Toast.show({
            icon: "fail",
            content: res?.message || "获取审核驳回节点失败",
          });
        rejectedFormInitValues.current = {
          nextStepId: res.value[res.value.length - 1].currentNodeInstanceId,
        };
        setRejectedSteps(res.value);
        rejectedForm.resetFields();
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectedVisible]);

  useEffect(() => {
    if (!rejectedSteps.length) rejectedFormInitValues.current = {};
    else
      rejectedFormInitValues.current = {
        nextStepId:
          rejectedSteps[rejectedSteps.length - 1].currentNodeInstanceId,
      };
    rejectedForm.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectedSteps]);

  // const formStatus = () => {
  // switch (status) {
  //   case 0:// 完成审核
  //     break;
  //   case 2: // 审核中
  //     break;
  //   case 5: // 驳回
  //     break;
  //   case 6: // 待审批
  //     break;
  //   case 7: // 终止
  //     break;
  //   case 8: // 发起审批
  //     break;
  //   default:
  //     break;
  // }
  // }

  /** 点击删除按钮 */
  const handleClick = (props: ProcessBtn.IBtnClickProps) => {
    const {
      content,
      okText = "确定",
      cancelText = "取消",
      okDanger = true,
      callback,
      callbackParam,
    } = props;
    Dialog.show({
      content,
      closeOnAction: true,
      actions: [
        [
          {
            key: "cancel",
            text: cancelText,
          },
          {
            bold: true,
            text: okText,
            key: "delete",
            danger: okDanger,
            onClick() {
              if (callback) callback(callbackParam);
            },
          },
        ],
      ],
    });
  };

  /** 审核 */
  const audit = (data: Form.IAuditReq) => {
    if (onClick) onClick(data);
    setRejectedVisible(false);
  };

  const Rejected = (data: any) => {
    audit({ ...data, nodeStatus: 0 });
  };

  return isRejected ? (
    <Grid className={styles.btnWrap} columns={1} gap={8}>
      <Grid.Item>
        <Button
          disabled={isLoading}
          color="success"
          onClick={() => audit({ nodeStatus: 1 })}
        >
          重新提交审批
        </Button>
      </Grid.Item>
    </Grid>
  ) : (
    <>
      <Grid className={styles.btnWrap} columns={4} gap={8}>
        <Grid.Item>
          <Button
            disabled={isLoading}
            color="success"
            onClick={() => audit({ nodeStatus: 1 })}
          >
            通过
          </Button>
        </Grid.Item>
        <Grid.Item>
          <Button
            disabled={isLoading}
            color="danger"
            onClick={() => setRejectedVisible(true)}
          >
            驳回
          </Button>
        </Grid.Item>
        <Grid.Item>
          <Button
            color="primary"
            disabled={isLoading}
            onClick={() =>
              handleClick({
                okText: "终止",
                callback: audit,
                content: "是否确定要终止此表单!",
                callbackParam: { nodeStatus: 3 },
              })
            }
          >
            终止
          </Button>
        </Grid.Item>
        <Grid.Item>
          <Button
            color="primary"
            disabled={isLoading || !formInformation?.IsDeleteForm}
            onClick={() =>
              handleClick({
                content: "是否确定要删除此表单!",
                okText: "删除",
                callback: deleteForm,
              })
            }
          >
            删除
          </Button>
        </Grid.Item>
      </Grid>

      <Popup
        visible={rejectedVisible}
        className={styles.rejectedPopup}
        onMaskClick={() => setRejectedVisible(false)}
        bodyStyle={{ height: "60vh", padding: "0.5rem 1rem" }}
      >
        <div className={styles.header}>
          <Space>
            <Button color="primary" onClick={() => setRejectedVisible(false)}>
              取消
            </Button>
            <Button color="danger" onClick={() => rejectedForm.submit()}>
              驳回
            </Button>
          </Space>
        </div>
        <Form
          form={rejectedForm}
          className={styles.rejectedForm}
          initialValues={rejectedFormInitValues.current}
          onFinish={Rejected}
        >
          <Form.Item
            name="remark"
            label="审批意见"
            rules={[{ required: true, message: "审批意见不能为空" }]}
          >
            <TextArea placeholder="请输入审批意见" />
          </Form.Item>
          <Form.Item
            name="nextStepId"
            label="驳回步骤选择"
            rules={[{ required: true, message: "驳回步骤选择必须选择" }]}
          >
            <Radio.Group>
              {map(rejectedSteps, (step, i) => {
                return (
                  <Radio
                    key={`ProcessBtn-rejected-step-${i}`}
                    block
                    value={step.currentNodeInstanceId}
                    className={styles.rejectedStep}
                  >
                    <div className={styles.title}>{step.auditPersonName}</div>
                    <div>{step.auditRoleName}</div>
                  </Radio>
                );
              })}
            </Radio.Group>
          </Form.Item>
        </Form>
      </Popup>
    </>
  );
};

interface IProcessBtnQuickStart {
  formInformation: Form.IFormInformation;
  isLoading: boolean;
  isRejected: boolean;
  auditCallback: (
    isZX: boolean,
    data: {
      nodeStatus: number;
      nextStepId?: string;
      remark?: string;
    }
  ) => void;
}

const ProcessBtnQuickStart: FC<IProcessBtnQuickStart> = ({
  formInformation,
  isLoading,
  auditCallback,
  isRejected,
}) => {
  const [rejectedVisible, setRejectedVisible] = useState<boolean>(false);
  const [rejectedSteps, setRejectedSteps] = useState<
    Array<Process.IRejectedStep>
  >([]);
  const [rejectedForm] = Form.useForm();
  const rejectedFormInitValues = useRef({});

  useEffect(() => {
    if (!formInformation || !rejectedVisible) return;
    getWorkflowNodeInstanceList(formInformation.data.value.item2).then(
      (res: IRes<Array<Process.IRejectedStep>>) => {
        if (res?.statusCode !== NetState.Ok)
          return Toast.show({
            icon: "fail",
            content: res?.message || "获取审核驳回节点失败",
          });
        rejectedFormInitValues.current = {
          nextStepId: res.value[res.value.length - 1].currentNodeInstanceId,
        };
        setRejectedSteps(res.value);
        rejectedForm.resetFields();
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectedVisible]);

  useEffect(() => {
    if (!rejectedSteps.length) rejectedFormInitValues.current = {};
    else
      rejectedFormInitValues.current = {
        nextStepId:
          rejectedSteps[rejectedSteps.length - 1].currentNodeInstanceId,
      };
    rejectedForm.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectedSteps]);

  const Rejected = (data: any) => {
    setRejectedVisible(false);
    auditCallback(false, { ...data, nodeStatus: 0 });
  };

  return isRejected ? (
    <Grid className={styles.btnWrap} columns={1} gap={8}>
      <Grid.Item>
        <Button
          disabled={isLoading}
          color="success"
          onClick={() => auditCallback(true, { nodeStatus: 1 })}
        >
          重新提交审批
        </Button>
      </Grid.Item>
    </Grid>
  ) : (
    <>
      <Grid className={styles.btnWrap} columns={3} gap={8}>
        <Grid.Item>
          <Button
            disabled={isLoading}
            color="success"
            onClick={() => auditCallback(true, { nodeStatus: 1 })}
          >
            自修
          </Button>
        </Grid.Item>
        <Grid.Item>
          <Button
            color="primary"
            disabled={isLoading}
            onClick={() => auditCallback(false, { nodeStatus: 1 })}
          >
            报设备部
          </Button>
        </Grid.Item>
        <Grid.Item>
          <Button
            disabled={isLoading}
            color="danger"
            onClick={() => setRejectedVisible(true)}
          >
            驳回
          </Button>
        </Grid.Item>
      </Grid>
      <Popup
        visible={rejectedVisible}
        className={styles.rejectedPopup}
        onMaskClick={() => setRejectedVisible(false)}
        bodyStyle={{ height: "60vh", padding: "0.5rem 1rem" }}
      >
        <div className={styles.header}>
          <Space>
            <Button color="primary" onClick={() => setRejectedVisible(false)}>
              取消
            </Button>
            <Button color="danger" onClick={() => rejectedForm.submit()}>
              驳回
            </Button>
          </Space>
        </div>
        <Form
          form={rejectedForm}
          className={styles.rejectedForm}
          initialValues={rejectedFormInitValues.current}
          onFinish={Rejected}
        >
          <Form.Item
            name="remark"
            label="审批意见"
            rules={[{ required: true, message: "审批意见不能为空" }]}
          >
            <TextArea placeholder="请输入审批意见" />
          </Form.Item>
          <Form.Item
            name="nextStepId"
            label="驳回步骤选择"
            rules={[{ required: true, message: "驳回步骤选择必须选择" }]}
          >
            <Radio.Group>
              {map(rejectedSteps, (step, i) => {
                return (
                  <Radio
                    key={`ProcessBtn-rejected-step-${i}`}
                    block
                    value={step.currentNodeInstanceId}
                    className={styles.rejectedStep}
                  >
                    <div className={styles.title}>{step.auditPersonName}</div>
                    <div>{step.auditRoleName}</div>
                  </Radio>
                );
              })}
            </Radio.Group>
          </Form.Item>
        </Form>
      </Popup>
    </>
  );
};

const Process: FC<Process.IProps> = (props) => {
  const { formId, auditCallback, onUpdateForm, deleteForm, type } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [stepDatas, setStepDatas] = useState<Array<Process.Step>>([]);
  const [formInformation, setFormInformation] =
    useState<Form.IFormInformation>();
  const [processTitle, setProcessTitle] = useState("");

  useEffect(() => {
    if (formId) getFormInformationUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formInformation) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInformation]);

  /** 获取步骤 */
  const getData = () => {
    if (!formInformation || !formInformation.data.value.item2)
      return setLoading(false);
    GetWorkflowStepsPresentList(formInformation.data.value.item2).then(
      (res: IRes<Array<Process.Step>>) => {
        setLoading(false);
        if (res?.statusCode !== NetState.Ok)
          return Toast.show({
            icon: "fail",
            content: res?.message || "获取流程信息失败",
          });
        const last = res.value[res.value.length - 1];

        if (last?.workflowNodeStatusCode === 1) {
          setProcessTitle("流程结束");
        } else {
          setProcessTitle(last.auditRoleName);
        }
        setStepDatas(res.value);
      }
    );
  };

  /** 获取表单信息 */
  const getFormInformationUpdate = () => {
    setLoading(true);
    const req = props?.formInformationUpdateF
      ? props?.formInformationUpdateF(formId)
      : formInformationUpdate(formId);
    req.then((information: Form.IFormInformation) => {
      setFormInformation(information);
    });
  };

  /**
   * 获取步骤状态信息Dom
   * @param item 步骤
   * @param index 步骤索引
   * @returns 步骤状态信息
   */
  const getStepStatusInfo = (
    item: Process.Step,
    index: number
  ): Process.StepStatusInfo => {
    const stepStatusInfo: Process.StepStatusInfo = {
      icon: "",
      color: "",
      statusText: "",
    };
    if (index === 0)
      stepStatusInfo.statusText = <span style={{ color: "#000" }}>发起</span>;
    switch (item.workflowNodeStatusCode) {
      case 1:
        if (index !== 0) stepStatusInfo.statusText = "通过";
        stepStatusInfo.icon = <CheckCircleFill />;
        stepStatusInfo.color = "#52c41a";
        break;
      case 0:
        stepStatusInfo.statusText = "驳回";
        stepStatusInfo.icon = <ProcessRejected />;
        stepStatusInfo.color = "#ff4d4f";
        break;
      case 3:
        stepStatusInfo.statusText = "终止";
        stepStatusInfo.icon = <CloseCircleFill />;
        stepStatusInfo.color = "#ff4d4f";
        break;
      default:
        stepStatusInfo.icon = <CheckCircleFill />;
        stepStatusInfo.color = "gray";
        break;
    }

    return stepStatusInfo;
  };

  /** 审批按钮点击 */
  const handleClick = (data: Form.IAuditReq) => {
    data.formId = formId;
    data.workflowId = formInformation?.data.value.item2;
    data.currentStepId = formInformation?.data.value.item3;

    if (auditCallback) {
      setLoading(true);
      auditCallback(data)
        .then((res: IRes<any>) => {
          if (res?.statusCode !== NetState.Ok) {
            return Toast.show({
              icon: "fail",
              content: res?.message || "审批表单失败",
            });
          }
          Toast.show({ icon: "success", content: "审批成功" });
          getData();
          getFormInformationUpdate();
          onUpdateForm();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      Toast.show("开发中");
    }
  };

  /** 删除表单
   * @param data
   */
  const handleDelete = () => {
    if (deleteForm) {
      deleteForm(formId).then((res: IRes<any>) => {
        console.log("deleteForm(formId).then((res", res);

        if (res?.statusCode !== NetState.Ok)
          return Toast.show({
            icon: "fail",
            content: res?.message || "删除表单失败",
          });
        console.log("????ASD");

        Toast.show({ icon: "success", content: "删除成功" });
        history.back();
      });
    }
  };

  const handleClickQuickStart = (
    isZX: boolean,
    data: {
      nodeStatus: number;
      nextStepId?: string;
      remark?: string;
    }
  ) => {
    const requestBody: Form.IAuditReq = {
      formId: formId,
      workflowId: formInformation?.data.value.item2,
      currentStepId: formInformation?.data.value.item3,
      nodeStatus: data.nodeStatus,
    };
    if (data.nodeStatus === 0) {
      const remark = trim(data.remark);
      if (MyUUIDValidateEasy(data?.nextStepId) && remark) {
        requestBody.nextStepId = data.nextStepId;
        requestBody.remark = remark;
      } else {
        Toast.show("提交审核信息缺失\n系统出错");
        return;
      }
    }

    if (auditCallback) {
      setLoading(true);
      auditCallback(isZX, requestBody)
        .then((res: IRes<any>) => {
          if (res?.statusCode !== NetState.Ok)
            return Toast.show({
              icon: "fail",
              content: res?.message || "审批表单失败",
            });
          Toast.show({ icon: "success", content: "审批成功" });
          getData();
          getFormInformationUpdate();
          onUpdateForm();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      Toast.show("开发中");
    }
  };

  /** 获取步骤 */
  const getStep = useMemo<ReactElement[]>(() => {
    return map(stepDatas, (item, i) => {
      const { icon, color, statusText } = getStepStatusInfo(item, i);
      return (
        <Steps.Step
          key={`Step-${i}`}
          title={
            <span style={{ color: "#19aee8" }}>{item.auditPersonName}</span>
          }
          status="finish"
          icon={
            <div className={styles.stepIconWrap} style={{ color }}>
              <div className={styles.stepIconText}>{statusText}</div>
              {icon}
            </div>
          }
          description={
            <>
              <div>{item.auditRoleName}</div>
              <div>时间: {item.auditDate}</div>
              {item.workflowNodeStatusCode === 0 && (
                <div>处理意见: {item.remark}</div>
              )}
            </>
          }
        />
      );
    });
  }, [stepDatas]);

  /** 当前流程是否被驳回 */
  const isRejected = formInformation?.data.value.item1 === 5;

  return (
    <div className={styles.wrap}>
      <div className={styles.processTitle}>{processTitle}</div>
      {formInformation?.isExaminationApproval ? (
        type === "QuickStart" ? (
          <ProcessBtnQuickStart
            formInformation={formInformation}
            isLoading={loading}
            isRejected={isRejected}
            auditCallback={handleClickQuickStart}
          />
        ) : (
          <ProcessBtn
            formInformation={formInformation}
            onClick={handleClick}
            isLoading={loading}
            isRejected={isRejected}
            deleteForm={handleDelete}
          />
        )
      ) : null}
      <div>
        <div className={styles.title}>审批进程</div>
        {loading && (
          <div style={{ textAlign: "center" }}>
            <DotLoading color="primary" />
          </div>
        )}
        {!loading && (
          <Steps
            current={1}
            direction="vertical"
            className={styles.steps}
            style={{
              "--icon-size": "1.3rem",
              "--title-font-size": "1.1rem",
              "--description-font-size": "0.8rem",
              "--indicator-margin-right": "0.8rem",
              paddingLeft: "2.8rem",
            }}
          >
            {getStep}
          </Steps>
        )}
      </div>
    </div>
  );
};

export default Process;
