import React, {
  ReactNode,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import { isFunction, find, map } from "lodash";
import { MobXProviderContext } from "mobx-react";
import { Form, Input, Radio, Space, Toast } from "antd-mobile";
import { useMyLocation } from "@/hooks";
import {
  GetMaintainProjectFitApplications,
  MaintainProjectFitApplicationNextStep,
  DeleteMaintainProjectFitApplication,
} from "@axios/form";
import { FILE } from "@axios/config";
import { NetState, download, downloadBlobUrl } from "@/utils";
import { getFormInputDom } from "@utils/formDom";
import Tamplate from "@components/from/Tamplate";
import Process from "@components/from/Process";
import styles from "./index.module.less";
import { push } from "@utils/common";

const ProjectFitApplication: React.FC = () => {
  const { GlobalStore } = useContext(MobXProviderContext);
  const { state: LocationState } = useMyLocation<Form.IRouterLocationState>();
  const [formItems] = useState<
    Array<Form.FormItem<ProjectFitApplication.ProjectFitApplicationData>>
  >([
    { key: "projectName", title: "工程名称", option: { type: "input" } },
    { key: "maintainNumber", title: "维修编号", option: { type: "input" } },
    {
      key: "maintainFinanceNumber",
      title: "维修财务编号",
      option: { type: "input" },
    },
    {
      key: "projectContentAskFor",
      title: "工程内容及要求",
      option: { type: "textArea" },
    },
    {
      key: "completeDate",
      title: "要求完工日期",
      option: { type: "datePicker" },
    },
    {
      key: "undertakeDepartmentId",
      title: "承接部门",
      render: (record) => {
        const d: Department = find(
          GlobalStore.allDepartments,
          (dep: Department) => dep.key === record?.undertakeDepartmentId
        );
        return <span>{d?.title}</span>;
      },
    },
    { key: "typeOfWork", title: "工种", option: { type: "input" } },
    { key: "departmentName", title: "申请部门", option: { type: "input" } },
    {
      key: "applicationPersonName",
      title: "申请人",
      option: { type: "input" },
    },
    {
      key: "applicationDate",
      title: "申请日期",
      option: { type: "datePicker" },
    },
    {
      key: "earningsDepartmentId",
      title: "受益部门",
      render: (record) => {
        const d: Department = find(
          GlobalStore.allDepartments,
          (dep: Department) => dep.key === record?.earningsDepartmentId
        );
        return <span>{d?.title}</span>;
      },
    },
    {
      key: "hocdName",
      title: "施工部门负责人",
      option: { type: "input", showPlaceholder: true, placeholderText: "---" },
    },
    {
      key: "hocdDate",
      title: "日期",
      option: { type: "input", showPlaceholder: true, placeholderText: "---" },
    },
    {
      key: "hoedName",
      title: "设备部负责人",
      option: { type: "input", showPlaceholder: true, placeholderText: "---" },
    },
    {
      key: "hoedDate",
      title: "日期",
      option: { type: "input", showPlaceholder: true, placeholderText: "---" },
    },
  ]);
  const [constructionProject] = useState([
    {
      title: "1.是否有施工方案",
      yesText: "附施工方案",
      radioKey: "isConstructionProject",
      noKey: "noConstructionProjectReason",
      fileType: 0,
    },
    {
      title: "2.是否有施工计划",
      yesText: "附施工计划",
      radioKey: "isConstructionPlan",
      noKey: "noConstructionPlanReason",
      fileType: 1,
    },
    {
      title: "3.是否有安全评估",
      yesText: "附安全评估",
      radioKey: "isSafetyAssessment",
      noKey: "noSafetyAssessmentReason",
      fileType: 2,
    },
    {
      title: "4.是否有安全作业许可证",
      yesText: "附安全作业许可证",
      radioKey: "isSafeWorkPermit",
      fileType: 3,
    },
    {
      title: "5.是否需要品质部检查",
      yesText: "附质检报告",
      radioKey: "isQA",
      fileType: 4,
    },
  ]);
  const [formData, setFormData] = useState<
    ProjectFitApplication.ProjectFitApplicationData | undefined
  >();
  const [formDataLoading, setFormDataLoading] = useState(false);
  const [formId, setFormId] = useState("");
  const [formRef] = Form.useForm();

  useEffect(() => {
    if (LocationState?.formId) {
      setFormId(LocationState.formId);
      getForm(LocationState.formId);
    } else {
      Toast.show("跳转表单出错");
      setTimeout(() => {
        push("/home");
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (formData) formRef.resetFields();
  }, [formData, formRef]);

  const getForm = (formId: string) => {
    setFormDataLoading(true);
    GetMaintainProjectFitApplications(formId)
      .then((res: IRes<ProjectFitApplication.ProjectFitApplicationData>) => {
        setFormDataLoading(false);
        if (res?.statusCode !== NetState.Ok)
          return Toast.show({
            icon: "fail",
            content: res?.message || "获取表单数据失败",
          });
        setFormData(res.value);
      })
      .catch(() => {
        Toast.show("获取表单内容出错");
        setTimeout(() => {
          push("/home");
        }, 500);
      });
  };
  const getFormF = () => getForm(formId);

  /**
   * 下载文件
   * @param file 下载的文件
   */
  const downloadFile = (
    file: ProjectFitApplication.TProjectFitApplicationData
  ) => {
    const { filePath, name } = file;
    if (filePath.split("/")[0] === "http:")
      return downloadBlobUrl(filePath, name);
    return download(FILE + filePath, name);
  };

  /** 表单Dom */
  const formItemsDom = useMemo<ReactNode>(() => {
    const items: Array<ReactNode> = [];
    if (formItems.length)
      formItems.forEach((item) => {
        const { key, title, titleRender } = item;
        if (title === "施工部门负责人") {
          items.push(
            <div
              key={`${key}-separated-start`}
              className="form-separated"
            ></div>
          );
          items.push(
            <div key={`${key}-separated-title`} className="form-separated-text">
              项目施工
            </div>
          );
          formData &&
            constructionProject.forEach((p, i) =>
              items.push(
                <div key={`${p.radioKey}-wrap`}>
                  <div className="form-separated-text">{p.title}</div>
                  <Form.Item
                    label={
                      <Radio checked={formData[p.radioKey]} value={1}>
                        是({p.yesText}) 附件:
                      </Radio>
                    }
                  >
                    {map(formData.maintainProjectFitApplicationFiles, (f) => (
                      <Space key={f.filePath} justify="between">
                        <span>{f.name}</span>
                        <button
                          className={styles.downloadBtn}
                          onClick={() => downloadFile(f)}
                        >
                          下载
                        </button>
                      </Space>
                    ))}
                  </Form.Item>
                  <Form.Item
                    name={p.noKey}
                    label={
                      <Radio checked={!formData[p.radioKey]} value={0}>
                        否
                        {i < constructionProject.length - 2 &&
                          "(输入理由) 理由:"}
                      </Radio>
                    }
                  >
                    <Input readOnly />
                  </Form.Item>
                </div>
              )
            );
          items.push(
            <div key={`${key}-separated-end`} className="form-separated"></div>
          );
        }
        items.push(
          <Form.Item
            key={key}
            name={key}
            label={
              titleRender
                ? isFunction(titleRender)
                  ? titleRender()
                  : titleRender
                : title
                ? `${title}:`
                : ""
            }
          >
            {item.render ? item.render(formData) : getFormInputDom(item)}
          </Form.Item>
        );
      });
    return items;
  }, [formItems, constructionProject, formData]);
  return (
    <Tamplate
      title="工程配合单"
      showHeaderCompany={false}
      formDataLoading={formDataLoading}
    >
      <Form
        form={formRef}
        layout="horizontal"
        initialValues={formData ? formData : {}}
      >
        {formItemsDom}
      </Form>
      <Process
        formId={formId}
        auditCallback={MaintainProjectFitApplicationNextStep}
        onUpdateForm={getFormF}
        deleteForm={DeleteMaintainProjectFitApplication}
      />
    </Tamplate>
  );
};

export default ProjectFitApplication;
