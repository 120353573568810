// import { AnyAaaaRecord } from "dns";
import { FILE } from "@/axios/config";
import { isDate } from "lodash";
import { NIL, validate as uuidValidate } from "uuid";
import VConsole from "vconsole";

export enum NetState {
  Ok = 200,
  Unthorization = 401,
  ConditionsChange = 412,
}

/**
 * @description
 * @author 卿光扬 angus_qing168@163.com
 */
export class EventDispatcher {
  private static func_store: (isLoading: boolean) => void;

  private static startTime = 0;

  private static timeout: any = undefined;

  private static interval = 500;

  public static registerGlobalLoading(
    func: (isLoading: boolean) => void
  ): void {
    this.func_store = func;
  }

  public static unRegisterGlobalLoading() {
    this.func_store = (_) => _;
  }

  public static TriggerGlobalLoading(isLoading: boolean): void {
    if (!!this.func_store) {
      const curTime = Date.now();
      clearTimeout(this.timeout);
      if (curTime - this.startTime <= this.interval) {
        //小于规定时间间隔时，用setTimeout在指定时间后再执行
        this.timeout = setTimeout(() => {
          if (!!this.func_store) this.func_store(isLoading);
        }, this.interval);
      } else {
        //重新计时并执行函数
        this.startTime = curTime;
        this.func_store(isLoading);
      }
    }
  }
}

/** Date转文字
 * type:
 * "spotCheck": YYYY/MM/DD hh:mm
 * "daxie": YYYY年MM月DD日
 * default: YYYY-MM-DD
 *
 * @param {any} date 期望Date对象
 * @param type 处理方式
 * @returns
 */
export function Dateformat(date: any, type?: string) {
  if (isDate(date)) {
    const zeroize = function (value: number | string, length: number = 2) {
      value = String(value);

      for (var i = 0, zeros = ""; i < length - value.length; i++) {
        zeros += "0";
      }

      return zeros + value;
    };
    let result;
    switch (type) {
      case "spotCheck":
        result = [
          date.getFullYear(),
          zeroize(date.getMonth() + 1),
          `${zeroize(date.getDate())} ${zeroize(date.getHours())}:${zeroize(
            date.getMinutes()
          )}`,
        ];
        return result.join("/");
      case "daxie":
        return [
          date.getFullYear(),
          "年",
          zeroize(date.getMonth() + 1),
          "月",
          zeroize(date.getDate()),
          "日",
        ].join("");

      default:
        result = [
          date.getFullYear(),
          zeroize(date.getMonth() + 1),
          zeroize(date.getDate()),
        ];
    }

    return result.join("-");
  } else {
    return "";
  }
}

/**
 * 下载
 * @param  {String} url 目标文件地址
 * @param  {String} filename 想要保存的文件名称
 */
export function download(url: string, filename: string) {
  const xhr = new XMLHttpRequest();

  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = () => {
    if (xhr.status === 200) {
      if ((window.navigator as any).msSaveOrOpenBlob) {
        (navigator as any).msSaveBlob(xhr.response, filename);
      } else {
        const link = document.createElement("a"); // 生成一个a元素
        let event = new MouseEvent("click"); // 创建一个单击事件
        link.download = filename;

        link.href = window.URL.createObjectURL(xhr.response); // 将生成的URL设置为a.href属性
        link.dispatchEvent(event); // 触发a的单击事件
        window.URL.revokeObjectURL(link.href);
      }
    }
  };
  xhr.send();
}

/**
 * 一期附加按接口下载
 */
export function downloadBlobUrl(blobUrl: string, filename: string) {
  const eleLink = document.createElement("a");
  eleLink.style.display = "none";
  // let filename = new Date().getTime() + '.xls';
  eleLink.href = blobUrl;
  eleLink.download = filename;
  document.body.appendChild(eleLink);
  eleLink.click();
  document.body.removeChild(eleLink);
  window.URL.revokeObjectURL(blobUrl);
}

// 递归优化
export function tco(f: Function) {
  let active = false;
  const accumulated: any[] = [];
  return function accumulator(this: any, ...param: any[]) {
    // 这里accumulated将形参入栈
    accumulated.push(param);
    // 这里保证只有第一次调用才会进入
    if (!active) {
      active = true;
      while (accumulated.length) {
        f.apply(this, accumulated.shift());
      }
      active = false;
    }
  };
}

/**
 * 小数乘法
 * @param {number} arg1 相乘的第一个数
 * @param {number} arg2 乘的第二个数
 * @returns {number} 返回乘积
 */
export const accMul = (arg1: number, arg2: number) => {
  let m = 0,
    s1 = arg1.toString(),
    s2 = arg2.toString();
  try {
    m += s1.split(".")[1].length;
  } catch (e) {}
  try {
    m += s2.split(".")[1].length;
  } catch (e) {}
  return (
    (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
    Math.pow(10, m)
  );
};

/**
 * 取数字指定小数位
 * @param {number} num 要取值的数字
 * @param {number} digits 要取的小数位数 默认为2
 * @returns {number} 结果
 */
export const toDelimal = (num: number, digits: number = 2) => {
  try {
    var strNum = num.toString();
    var delimalIndex = strNum.indexOf(".");
    if (delimalIndex === -1) {
      return num;
    } else {
      return Number.parseFloat(strNum.substring(0, delimalIndex + digits + 1));
    }
  } catch (error) {
    console.error(error);
  }
};

/** 验证UUID v4
 * 为空 则返回 false
 * RFC 4122
 * @param uuid
 * @returns {boolean}
 */
export function MyUUIDValidate(uuid: any): boolean {
  return typeof uuid === "string" && uuid !== NIL && uuidValidate(uuid);
}

/** 验证UUID easy
 * 为空 则返回 false
 * @param uuid
 * @returns {boolean}
 */
export function MyUUIDValidateEasy(uuid: any): boolean {
  if (typeof uuid === "string" && uuid !== NIL) {
    const reg = new RegExp(
      /[0123456789abcdef]{8}-[0123456789abcdef]{4}-4[0123456789abcdef]{3}-[0123456789abcdef]{4}-[0123456789abcdef]{12}/
    );

    return reg.test(uuid);
  } else {
    return false;
  }
}

const vConsole = new VConsole();
vConsole.hideSwitch();
let clickCount = 0;
let show = false;

export function createConsole() {
  if (clickCount > 4) {
    clickCount = 0;

    if (show) {
      show = false;
      vConsole.hideSwitch();
    } else {
      show = true;
      vConsole.showSwitch();
    }
  } else {
    clickCount++;
  }
}

/**
 * 下载文件
 * @param file 下载的文件
 */
export const downloadFile = (file: IFile) => {
  const { filePath, name } = file;
  if (filePath.split("/")[0] === "http:")
    return downloadBlobUrl(filePath, name);
  return download(FILE + filePath, name);
};
