import { useMyLocation } from "@/hooks";
import { push, replace } from "@/utils/common";
import { formInformationUpdate } from "@utils/form";
import { GetFinancialInfo, GetInfo, uploadImgFile, DeleteFile } from "@axios";
import {
  DeleteMaintainApplication,
  GetAssetInfoDepartmentDto,
  GetMaintainApplication,
  SubmitUnplannedMaintainApplication,
  SubmitMaintainApplication,
  putUpdateMaintainApplication,
  postMaintainApplicationNextStep,
} from "@axios/form";
import Process from "@components/from/Process";
import Tamplate from "@components/from/Tamplate";
import type { IGlobalStore } from "@store/global";
import { Dateformat, MyUUIDValidateEasy, NetState } from "@utils";
import { getFormInputDom } from "@utils/formDom";
import { Button, DatePicker, Form, Picker, Space, Toast } from "antd-mobile";
import type {
  PickerColumn,
  PickerValue,
  PickerValueExtend,
} from "antd-mobile/es/components/picker";
import { find, isFunction, map } from "lodash";
import { MobXProviderContext } from "mobx-react";
import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./index.module.less";

/** 维修申请单
 * 公司id
 * 计划内从计划拿
 * 计划外拿台账公司id
 */

const ToastFail = (props: object | string) =>
  typeof props === "string"
    ? Toast.show({ icon: "fail", content: props })
    : Toast.show(Object.assign({}, props, { icon: "fail" }));
const editMaintainFinanceNumber = [
  "admin",
  "sb_zg",
  "sb_tt",
  "sb_jl",
  "sb_ct",
  "sb_gc",
  "sb_jd",
];

interface IFileList {
  id?: string;
  name: string;
  filePath: string;
}

const Application: React.FC = (props) => {
  const GlobalStore: IGlobalStore | undefined =
    useContext(MobXProviderContext)?.GlobalStore;
  const { state: LocationState } = useMyLocation<any>();
  const [formId, setFormId] = useState("");
  const [pickerCol, setPickerCol] = useState<Array<PickerColumn>>([]);
  const [pickerOption, setPickerOption] = useState<Form.PickerOption>({});
  const [pickerValue, setPickerValue] =
    useState<MaintainWorkApplication.TPickerValue>({});
  const [datePickerScope, setDatePickerScope] =
    useState<MaintainWorkApplication.TDatePickerPcope>({});
  const [datePickerValue, setDatePickerValue] = useState<{
    [key: string]: Date;
  }>({});
  const [maintainFinance, setMaintainFinance] = useState<Array<IFinancialInfo>>(
    []
  );
  const [formReadonly, setFormReadonly] = useState<boolean>(false);
  const [fileList, setFileList] = useState<Array<IFileList>>([]);
  const [formDataLoading, setFormDataLoading] = useState(false);
  const [isPlan, setIsPlan] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [isExaminationApproval, setIsExaminationApproval] =
    useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const fileNode = useRef<HTMLInputElement | null>(null);
  const [formRef] = Form.useForm();
  const formItems = useRef<Array<Form.FormItem<any>>>([
    { key: "deviceName", title: "设备名称", option: { type: "input" } },
    { key: "deviceNumber", title: "设备编号", option: { type: "input" } },
    { key: "maintainNumber", title: "维修编号", option: { type: "input" } },
    {
      key: "applicationProjectTypeEnum",
      title: "工程类型",
      option: {
        type: "radio",
        editable: true,
        datas: [
          { label: "计划内常规维修", value: 0, disabled: true },
          { label: "计划内大修", value: 1, disabled: true },
          { label: "故障性应急修理", value: 2, disabled: isPlan },
          { label: "事故性维修", value: 3, disabled: isPlan },
        ],
      },
    },
    {
      key: "maintainFinanceNumber",
      title: "维修财务编号",
      rules: [{ required: true, message: "请选择维修财务编号" }],
      option: { type: "picker", editable: true },
      event: {
        onClick: () =>
          setPickerOption({
            visible: true,
            setingFaield: "maintainFinanceNumber",
          }),
      },
    },
    {
      key: "maintainFinanceName",
      title: "维修财务名称",
      rules: [{ required: true, message: "请选择维修财务名称" }],
      option: { type: "picker", editable: true },
      event: {
        onClick: () =>
          setPickerOption({
            visible: true,
            setingFaield: "maintainFinanceName",
          }),
      },
    },
    { key: "departmentName", title: "报修部门", option: { type: "input" } },
    { key: "repairsUserName", title: "报修人", option: { type: "input" } },
    {
      key: "repairsDate",
      title: "报修时间",
      option: { type: "datePicker", editable: true },
      event: {
        onClick: () =>
          setPickerOption({ dateVisible: true, setingFaield: "repairsDate" }),
      },
    },
    {
      key: "completeDate",
      title: "要求完工时间",
      option: { type: "datePicker", editable: true },
      event: {
        onClick: () =>
          setPickerOption({ dateVisible: true, setingFaield: "completeDate" }),
      },
    },
    {
      key: "maintainContent",
      title: "计划维修内容",
      option: { type: "input" },
    },
    {
      key: "deviceFaultDescribe",
      title: "维修故障描述及维修方案",
      rules: [{ required: true, message: "请输入维修故障描述及维修方案" }],
      option: { type: "textArea", editable: true },
    },
    {
      key: "addicn",
      title: "申请部门设备主管",
      option: { type: "input", showPlaceholder: false },
    },
    {
      key: "adpn",
      title: "申请部门负责人",
      option: { type: "input", showPlaceholder: false },
    },
    {
      key: "deviceFaultAnalysisPlan",
      title: "设备故障分析及维修方案(大修方案另附)",
      option: { type: "textArea", editable: true },
    },
    { key: "", title: "大修方案(附件)", option: { type: "fileList" } },
    {
      key: "msName",
      title: "设备部主管",
      option: { type: "input", showPlaceholder: false },
    },
    {
      key: "repairWayEnum",
      title: "修理方式",
      rules: [{ required: true, message: "请选择修理方式" }],
      option: {
        type: "radio",
        editable: true,
        datas: [
          { label: "本厂工人维修", value: 0 },
          { label: "外包单位维修", value: 1 },
          { label: "外协单位维修", value: 2 },
        ],
      },
    },
    {
      key: "isSparesPurchasing",
      title: "采购备件",
      rules: [{ required: true, message: "请选择采购备件" }],
      option: {
        type: "radio",
        editable: true,
        datas: [
          { label: "是", value: true },
          { label: "否", value: false },
        ],
      },
    },
    {
      key: "clearingFormEnum",
      title: "结算方式",
      rules: [{ required: true, message: "请选择结算方式" }],
      option: {
        type: "radio",
        editable: true,
        datas: [
          { label: "按内修价格体系", value: 0 },
          { label: "按项目总包(附审核后的总包协议/合同)", value: 1 },
          { label: "按项目个人总包(附审核后的总包协议/合同)", value: 2 },
        ],
      },
    },
    {
      key: "planStartUpDate",
      title: "计划开工时间",
      option: { type: "datePicker", editable: true },
      event: {
        onClick: () =>
          setPickerOption({
            dateVisible: true,
            setingFaield: "planStartUpDate",
          }),
      },
    },
    {
      key: "planCompleteDate",
      title: "计划完工时间",
      option: { type: "datePicker", editable: true },
      event: {
        onClick: () =>
          setPickerOption({
            dateVisible: true,
            setingFaield: "planCompleteDate",
          }),
      },
    },
    {
      key: "sbbJlMc",
      title: "设备部经理",
      option: { type: "input", showPlaceholder: false },
    },
  ]);
  const upPickerDataType = useRef<string>("");

  useEffect(() => {
    if (MyUUIDValidateEasy(LocationState?.formId)) {
      setFormReadonly(true);
      const LState = LocationState as Form.IRouterLocationState;
      setFormId(LState.formId);
      getForm(LState.formId);
    } else if (MyUUIDValidateEasy(LocationState?.deviceId)) {
      if (MyUUIDValidateEasy(LocationState?.workMaintainContentId)) {
        if (typeof LocationState?.applicationProjectTypeEnum === "number") {
          setIsPlan(true);
        } else {
          Toast.show("跳转表单出错");
          setTimeout(() => {
            push("/home");
          }, 500);
          return;
        }
      }
      setFormDataLoading(true);
      const LState = LocationState as Form.IRouterLocationStateApplication;
      GetAssetInfoDepartmentDto(LState.deviceId)
        .then((res) => {
          if (res?.statusCode !== NetState.Ok) {
            throw new Error(res?.message || "表单出错");
          }
          formRef.setFieldsValue({
            companyId: res.value.companyId,
            companyName: res.value.companyName,
          });
          setCompanyId(res.value.companyId);
          getFinancialTree(res.value.companyId);
        })
        .catch((error: Error) => {
          Toast.show(error.message);
          setTimeout(() => {
            push("/home");
          }, 500);
        });
      GetInfo(LState.deviceId).then((res) => {
        if (res?.statusCode !== NetState.Ok) {
          Toast.show(res.message);
          setTimeout(() => {
            push("/home");
          }, 500);
          return;
        }
        const user = localStorage.getItem("user");
        if (!user) return Toast.show("获取用户信息失败");
        const { user: userObj } = JSON.parse(user);
        const applicationProjectTypeEnum =
          typeof LState?.applicationProjectTypeEnum === "number"
            ? LState.applicationProjectTypeEnum
            : 2;
        formRef.setFieldsValue({
          maintainContent: LocationState?.maintainContent || "",
          applicationProjectTypeEnum: applicationProjectTypeEnum,
          assetInfoId: res.value.id,
          clearingFormEnum: 0,
          departmentId: userObj.departmentUsers[0].departmentId,
          departmentName: userObj.departmentUsers[0].departmentName,
          isSparesPurchasing: true,
          deviceName: res.value.deviceName,
          deviceNumber: res.value.deviceNumber,
          repairWayEnum: 0,
          repairsDate: new Date().toISOString(),
          repairsUserId: userObj.id,
          repairsUserName: userObj.name,
        });
        setFormDataLoading(false);
      });
    } else {
      Toast.show("跳转表单出错");
      setTimeout(() => {
        push("/home");
      }, 500);
      return;
    }
  }, [LocationState]);

  useEffect(() => {
    datePickerConfirm(new Date(), "repairsDate");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!maintainFinance || !pickerOption.setingFaield || !pickerOption.visible)
      return;
    if (upPickerDataType.current === pickerOption.setingFaield) return;
    let col: PickerColumn = [];
    switch (pickerOption.setingFaield) {
      case "maintainFinanceNumber":
        col = map(maintainFinance, (f) => ({
          key: f.id,
          label: f.code,
          value: f.code,
        }));
        break;
      case "maintainFinanceName":
        col = map(maintainFinance, (f) => ({
          key: f.id,
          label: f.name,
          value: f.name,
        }));
        break;
    }
    upPickerDataType.current = pickerOption.setingFaield;
    setPickerCol([col]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickerOption.visible]);

  const getForm = (formId: string) => {
    setFormDataLoading(true);
    GetMaintainApplication(formId).then(
      (res: IRes<MaintainWorkApplication.ApplicationData>) => {
        if (res?.statusCode !== NetState.Ok)
          return Toast.show({
            icon: "fail",
            content: res?.message || "获取表单数据失败",
          });
        res.value.isSparesPurchasing = res.value.isSparesPurchasing || false;
        if (res.value.companyId) {
          getFinancialTree(res.value.companyId);
          setCompanyId(res.value.companyId);
        }
        if (res.value.overhaulFileUpload)
          setFileList(res.value.overhaulFileUpload);
        formRef.setFieldsValue(res.value);
        setFormDataLoading(false);
      }
    );
  };
  const getFormF = () => getForm(formId);

  /**
   * 获取财务编码信息
   * @param companyId 公司ID
   */
  const getFinancialTree = async (companyId: string) => {
    GetFinancialInfo(companyId).then((res: IRes<Array<IFinancialInfo>>) => {
      if (res?.statusCode === NetState.Ok) setMaintainFinance(res.value);
    });
  };

  /**
   * 选择器确定值
   * @param value 值
   */
  const pickerConfirm = (value: PickerValue[], _extend: PickerValueExtend) => {
    const { setingFaield } = pickerOption;
    if (!setingFaield) return;
    const tempPickerValue = { ...pickerValue };
    if (
      setingFaield === "maintainFinanceName" ||
      setingFaield === "maintainFinanceNumber"
    ) {
      const temp = find(
        maintainFinance,
        (f) => f.code === value[0] || f.name === value[0]
      );
      tempPickerValue.maintainFinanceName = [temp?.name];
      tempPickerValue.maintainFinanceNumber = [temp?.code];
      formRef.setFieldValue("maintainFinanceName", temp?.name);
      formRef.setFieldValue("maintainFinanceNumber", temp?.code);
    } else tempPickerValue[setingFaield] = value;
    setPickerValue(tempPickerValue);
  };

  /**
   * 日期选择器确定值
   * @param value 日期
   */
  const datePickerConfirm = (value: Date, setingFaield: string) => {
    const tempDatePickerScope = { ...datePickerScope };
    switch (setingFaield) {
      case "repairsDate":
        tempDatePickerScope["completeDate"] = { min: value };
        break;
      case "completeDate":
        tempDatePickerScope["repairsDate"] = { max: value };
        break;
      case "planStartUpDate":
        tempDatePickerScope["planCompleteDate"] = { min: value };
        break;
      case "planCompleteDate":
        tempDatePickerScope["planStartUpDate"] = { max: value };
        break;
    }
    const tempDatePickerValue = { ...datePickerValue };
    tempDatePickerValue[setingFaield] = value;
    setDatePickerValue(tempDatePickerValue);
    setDatePickerScope(tempDatePickerScope);
    formRef.setFieldValue(setingFaield, Dateformat(value));
  };

  /** 页面元素 */
  const formItemsDom = useMemo<ReactNode>(() => {
    /** 触发上传 */
    const onOpenFileChooser = () => {
      if (fileNode.current) fileNode.current.click();
    };
    /** 根据权限区分可编辑字段 */
    const financeArray = ["maintainFinanceNumber", "maintainFinanceName"];
    const auditEdit = [
      "deviceFaultAnalysisPlan",
      "repairWayEnum",
      "isSparesPurchasing",
      "clearingFormEnum",
      "planStartUpDate",
      "planCompleteDate",
    ];
    const userRoles =
      GlobalStore?.user?.user?.userRoles.map((item: any) => item.roleCode) ??
      [];
    const departmentUsers =
      GlobalStore?.user?.user?.departmentUsers.map(
        (item: any) => item.departmentCode
      ) ?? [];
    // 设备部审核时可编辑判定
    const isSB = isExaminationApproval ? departmentUsers.includes("SB") : false;

    // 财务编码可编辑判定
    const isEditNumber =
      formReadonly && userRoles.length
        ? userRoles.some((item) => editMaintainFinanceNumber.includes(item))
        : false;

    const nodeList: Array<ReactNode> = [];

    formItems.current.forEach((item) => {
      if (item?.option?.editable) item.option.readonly = formReadonly;
      const { key, title, titleRender, option, event, rules } = item;
      if (title === "设备部主管")
        nodeList.push(
          <div key={`${key}-form-separated`} className="form-separated"></div>
        );
      if (key === "applicationProjectTypeEnum") {
        if (item.option)
          item.option.datas = [
            { label: "计划内常规维修", value: 0, disabled: true },
            { label: "计划内大修", value: 1, disabled: true },
            { label: "故障性应急修理", value: 2, disabled: isPlan },
            { label: "事故性维修", value: 3, disabled: isPlan },
          ];
        nodeList.push(
          <Form.Item
            name={key}
            rules={rules}
            key={`${key}-form-item`}
            label={
              titleRender
                ? isFunction(titleRender)
                  ? titleRender()
                  : titleRender
                : title
                ? `${title}:`
                : ""
            }
          >
            {item.render ? item.render(item) : getFormInputDom(item)}
          </Form.Item>
        );
      } else if (financeArray.includes(key)) {
        if (item?.option?.readonly) item.option.readonly = isEditNumber;
        nodeList.push(
          <Form.Item
            name={key}
            rules={rules}
            key={`${key}-form-item`}
            label={
              titleRender
                ? isFunction(titleRender)
                  ? titleRender()
                  : titleRender
                : title
                ? `${title}:`
                : ""
            }
          >
            {item.render ? item.render(item) : getFormInputDom(item)}
          </Form.Item>
        );
      } else if (auditEdit.includes(key)) {
        // 设备部可编辑
        if (item?.option?.readonly) item.option.readonly = !isSB;
        nodeList.push(
          <Form.Item
            name={key}
            rules={rules}
            key={`${key}-form-item`}
            label={
              titleRender
                ? isFunction(titleRender)
                  ? titleRender()
                  : titleRender
                : title
                ? `${title}:`
                : ""
            }
          >
            {item.render ? item.render(item) : getFormInputDom(item)}
          </Form.Item>
        );
      } else if (
        !option?.readonly &&
        (option?.type === "picker" || option?.type === "datePicker")
      )
        nodeList.push(
          <div style={{ width: "100%", height: "100%", position: "relative" }}>
            <Form.Item
              name={key}
              rules={rules}
              key={`${key}-form-item`}
              label={
                titleRender
                  ? isFunction(titleRender)
                    ? titleRender()
                    : titleRender
                  : title
                  ? `${title}:`
                  : ""
              }
            >
              {item.render ? item.render() : getFormInputDom(item)}
            </Form.Item>
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                zIndex: 3,
                left: 0,
                top: 0,
              }}
              {...event}
            ></div>
          </div>
        );
      else if (option?.type === "fileList")
        nodeList.push(
          <Form.Item
            name={key}
            key={`${key}-form-item`}
            label={
              <div>
                <div>大修方案(附件):</div>
                {(!formReadonly || isSB) && (
                  <Button
                    fill="outline"
                    color="primary"
                    style={{ "--border-radius": "1px" }}
                    onClick={onOpenFileChooser}
                  >
                    + 上传
                  </Button>
                )}
              </div>
            }
          >
            {map(fileList, (file, i) => (
              <div key={`file-list-${i}`} className={styles.fileListItem}>
                <span>{file.name}</span>
                <Space style={{ "--gap": "0.2rem" }}>
                  <button style={{ color: "#1677ff" }}>下载</button>
                  {isSB && (
                    <button
                      style={{ color: "#ff3141" }}
                      onClick={() => deleteImg(file)}
                    >
                      删除
                    </button>
                  )}
                </Space>
              </div>
            ))}
          </Form.Item>
        );
      else
        nodeList.push(
          <Form.Item
            name={key}
            rules={rules}
            key={`${key}-form-item`}
            label={
              titleRender
                ? isFunction(titleRender)
                  ? titleRender()
                  : titleRender
                : title
                ? `${title}:`
                : ""
            }
          >
            {item.render ? item.render(item) : getFormInputDom(item)}
          </Form.Item>
        );
    });
    return nodeList;
  }, [
    formItems,
    fileList,
    isPlan,
    formReadonly,
    isExaminationApproval,
    fileNode,
  ]);

  const submit = async (value: MaintainWorkApplication.ApplicationData) => {
    if (MyUUIDValidateEasy(value.maintainFinanceNumber)) {
      ToastFail("请选择财务编号");
      return;
    }
    value.companyId = companyId;
    if (GlobalStore) {
      value["companyName"] =
        GlobalStore.companys.find((item) => item.id === value.companyId)
          ?.name || "未知";
    } else value["companyName"] = "未知";

    if (typeof value["applicationProjectTypeEnum"] !== "number") {
      ToastFail(isPlan ? "表单提交出现异常：工程类型" : "请选择工程类型");
      return;
    }

    value["assetInfoId"] = value["assetInfoId"] || LocationState?.deviceId;
    value["overhaulFileUpload"] = fileList;

    setSubmitLoading(true);
    let res;
    if (isPlan) {
      value["workMaintainContentId"] =
        value["workMaintainContentId"] || LocationState.workMaintainContentId;
      res = await SubmitMaintainApplication(value);
    } else {
      res = await SubmitUnplannedMaintainApplication(
        value["applicationProjectTypeEnum"],
        value
      );
    }

    try {
      if (res?.statusCode === 200) {
        Toast.show({ content: "提交成功", icon: "success" });
        setFormReadonly(true);
        setIsExaminationApproval(false);

        setFormId(res.value.id);
        replace("/maintainWork/application", { formId: res.value.id });
      } else {
        throw new Error("submit is error");
      }
    } catch (error) {
      ToastFail("提交失败");
    }
    setSubmitLoading(false);
  };

  const submitFail = (errorValue: any) => {
    ToastFail(
      `当前表单存在${
        errorValue?.errorFields?.length
          ? errorValue?.errorFields?.length + "个"
          : ""
      }问题！`
    );
  };

  const auditCallback = (params: Form.IAuditReq) => {
    // 判断是否通过
    if (params.nodeStatus === 1) {
      return formRef
        .validateFields()
        .catch((error) => {
          console.error("auditCallback error", error);
        })
        .then((value): any => {
          if (MyUUIDValidateEasy(value.maintainFinanceNumber)) {
            return { message: "请选择财务编号" };
          }
          value.companyId = companyId;
          if (GlobalStore) {
            value["companyName"] =
              GlobalStore.companys.find((item) => item.id === value.companyId)
                ?.name || "未知";
          } else value["companyName"] = "未知";

          if (typeof value["applicationProjectTypeEnum"] !== "number") {
            return {
              message: isPlan ? "表单提交出现异常：工程类型" : "请选择工程类型",
            };
          }
          value.id = formId;
          value.overhaulFileUpload = fileList;
          console.log(
            "workMaintainContentId",
            value,
            LocationState,
            LocationState.workMaintainContentId
          );

          value["workMaintainContentId"] =
            value["workMaintainContentId"] ||
            LocationState.workMaintainContentId;
          return putUpdateMaintainApplication(value);
        })
        .then((res: IRes<any>) => {
          if (res?.statusCode === 200) {
            return postMaintainApplicationNextStep(params);
          }
          return res;
        });
    } else {
      return postMaintainApplicationNextStep(params);
    }
  };

  /** 获取表单流程等信息 */
  const formInformationUpdateF = (id: TFormID) => {
    return formInformationUpdate(id).then((data) => {
      setIsExaminationApproval(data.isExaminationApproval);

      return data;
    });
  };

  /** 上传附件 */
  const showPicture = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return;
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    uploadImgFile(formData)
      .then((res) => {
        if (res?.statusCode === NetState.Ok) {
          const newRecord = {
            name: res.data.FrName,
            filePath: res.data.FrPath,
          };

          setFileList((e) => [...e, newRecord]);
        } else {
          return Promise.reject(res?.message || "上传失败\n网络出错");
        }
      })
      .catch((error) => {
        Toast.show(error?.message || "上传失败\n网络出错");
      });
  };

  /** 删除附件 */
  const deleteImg = (fileRecord: IFileList) => {
    const filePath = fileRecord.filePath;
    if (!formReadonly && !fileRecord?.id) {
      const token = localStorage.getItem("token1");
      if (token) DeleteFile(token, filePath);
    }
    setFileList((imgList) => imgList.filter((e) => e.filePath !== filePath));
  };

  return (
    <Tamplate
      title="维修申请单"
      companyId={companyId}
      formDataLoading={formDataLoading}
    >
      <Form
        form={formRef}
        layout="horizontal"
        onFinish={submit}
        onFinishFailed={submitFail}
        footer={
          !formId && (
            <Button
              loading={submitLoading}
              type="submit"
              color="primary"
              style={{ "--border-radius": "2px", padding: "0.3rem 1.5rem" }}
            >
              提交审批
            </Button>
          )
        }
      >
        <Form.Item name="departmentId" hidden />
        <Form.Item name="workMaintainContentId" hidden />
        {formItemsDom}
      </Form>

      {formId && (
        <Process
          formId={formId}
          onUpdateForm={getFormF}
          deleteForm={DeleteMaintainApplication}
          auditCallback={auditCallback}
          formInformationUpdateF={formInformationUpdateF}
        />
      )}
      <input
        ref={fileNode}
        style={{ display: "none" }}
        id="fileInpBtn"
        type="file"
        onChange={showPicture}
        multiple={false}
      />
      <Picker
        columns={pickerCol}
        onConfirm={pickerConfirm}
        visible={pickerOption.visible}
        onClose={() => setPickerOption({})}
        value={pickerValue[pickerOption.setingFaield || ""]}
      />
      <DatePicker
        visible={pickerOption.dateVisible}
        onClose={() => setPickerOption({})}
        {...datePickerScope[pickerOption.setingFaield || ""]}
        value={datePickerValue[pickerOption.setingFaield || ""]}
        onConfirm={(value) =>
          datePickerConfirm(value, pickerOption.setingFaield || "")
        }
      />
    </Tamplate>
  );
};

export default Application;
