/**
 * 查找台账
 */
import { useEffect, useMemo, useState } from "react";
import type { FC } from "react";
import { NavBar, Button, Toast, Cascader, InfiniteScroll } from "antd-mobile";
import { history } from "@/utils/common";
import style from "./index.module.less";
import { FirstCatalogImg, EquipmentImg } from "@components/icon";
import "./index.less";
import { postLedgerTable, getLedgerTree, GetShipInfoList } from "@axios";
import { tco } from "@utils";
import { push } from "@/utils/common";
import SearchBar from "./searchBar";
import { omit } from "lodash";

interface ITableData {
  id: string;
  name: string;
  [propName: string]: any;
}

const LedgerList: FC<NLedgerList.IProps> = (props) => {
  const [selectID, setSelectID] = useState<NLedgerList.selectID>({
    id: "",
    label: "",
    value: [],
  });
  const [visible, setVisible] = useState(false);
  const [treeData, setTreeData] = useState<NLedgerList.TreeRecord[]>([]);
  const [tableData, setTableData] = useState<ITableData[]>([]);
  const [tablePagination, setTablePagination] = useState({
    pageNO: 1,
    pageSize: 20,
    hasMore: false,
  });
  const [condition, setCondition] = useState("");
  const [isShip, setIsShip] = useState(false);
  const back = () => history.back();

  useEffect(() => {
    getTreeData();
  }, []);

  const title = useMemo(() => {
    switch (props.infoType) {
      case "quickRepair":
        return "快捷维修记录";
      case "maintenance":
        return "维修记录";
      case "oneMaintenance":
        return "一级保养记录";
      case "twoMaintenance":
        return "二级保养记录";
      case "spotCheck":
        return "点检记录";
      case "shipMaintenance":
        setIsShip(true);
        return "维修记录";
      case "shipUpkeep":
        setIsShip(true);
        return "保养记录";
      default:
        return "未知";
    }
  }, [props.infoType]);

  const cascaderOpen = () => setVisible(true);
  const cascaderClose = () => setVisible(false);

  /** 级联选择器点击 */
  const confirmHandle: NLedgerList.TselectHandle = (value, extend) => {
    let values: string[] = [];
    if (value.every((i) => typeof i === "string")) {
      values = value as string[];
    } else {
      Toast.show("选择节点程序出错\n请联系管理员处理！");
    }

    if (extend.items.length && values.length) {
      const id = values[values.length - 1];
      setSelectID({
        id,
        label: extend.items
          .map((item) => {
            return item?.label || "未知类名";
          })
          .join(" > "),
        value: values,
      });
      setTablePagination((state) =>
        Object.assign({}, state, { pageNO: 1, hasMore: true })
      );
      setTableData([]);
    } else {
      setSelectID({
        id: "",
        label: "",
        value: [],
      });
    }
  };

  /** 获取台账类型树形
   * @param {string} id
   * @returns
   */
  const getTableData = (id: string) => {
    const reqBody = {
      condition: condition,
      isAsc: true, // 排序true：升序；false：降序
      sTime: "",
      eTime: "",
      companyId: [],
      departmentId: [],
      pageNO: tablePagination.pageNO,
      pageSize: tablePagination.pageSize, // 当前页显示数据条数大小
    };

    return new Promise<
      IRes<{
        data?: ITableData[];
        count?: number;
      }>
    >((resolve) => {
      if (isShip) {
        const shipBody = condition
          ? reqBody
          : Object.assign(
              { id },
              omit(reqBody, ["sTime", "eTime", "companyId"])
            );
        resolve(GetShipInfoList(shipBody));
      } else {
        const requestBody = condition
          ? reqBody
          : Object.assign({ id }, reqBody);
        resolve(postLedgerTable(requestBody));
      }
    }).then((res) => {
      if (res?.statusCode !== 200) {
        setTablePagination((state) =>
          Object.assign({}, state, { hasMore: false })
        );
        return;
      }

      const hasMore = res?.value?.count
        ? reqBody.pageNO <
          (Math.floor(res.value.count / tablePagination.pageSize) !== 1
            ? Math.floor(res.value.count / tablePagination.pageSize)
            : Math.ceil(res.value.count / tablePagination.pageSize))
        : false;

      const pagination = {
        pageNO: reqBody.pageNO + 1,
        pageSize: tablePagination.pageSize,
        hasMore,
      };
      setTablePagination(pagination);
      if (Array.isArray(res?.value?.data)) {
        const isShipMapF = (item: any) => {
          return {
            id: item.id,
            name: item.name,
          };
        };
        const notShipMapF = (item: any) => {
          return {
            id: item.id,
            name: item.deviceName,
          };
        };

        const d = isShip
          ? res.value.data.map(isShipMapF)
          : res.value.data.map(notShipMapF);
        setTableData((state) => state.concat(d));
      }
    });
  };

  const loadMore = () => {
    return getTableData(selectID.id);
  };

  const getTreeData = async () => {
    const res: IRes<any> = await getLedgerTree();
    if (res?.statusCode !== 200) {
      Toast.show({
        content: "请求获取台账类型失败！",
        icon: "fail",
      });
      return;
    }
    const deepClone = tco(function (
      Original: any,
      data: NLedgerList.TreeRecord[]
    ) {
      if (Array.isArray(Original)) {
        const len = Original.length;
        for (let index = 0; index < len; index++) {
          const element = Original[index];

          if (!(element?.key && element?.value)) {
            continue;
          }
          const record: NLedgerList.TreeRecord = {
            label: element.value,
            value: element.key,
          };
          data.push(record);
          if (Array.isArray(element?.children) && element.children.length) {
            record.children = [];
            deepClone(element.children, record.children);
          }
        }
      }
    });
    const data: NLedgerList.TreeRecord[] = [];
    deepClone(res?.value, data);
    setTreeData(data);
  };

  /** 跳转对应页面
   * @param {string} id 台账ID
   * @returns
   */
  const ledgerClick = (id: string) => {
    switch (props.infoType) {
      case "quickRepair":
        push("/index.html/quick?id=" + id);
        return;
      case "maintenance":
        push("/index.html/repair?id=" + id);
        return;
      case "oneMaintenance":
        push("/index.html/oneMaintenance?id=" + id);
        return;
      case "twoMaintenance":
        push("/index.html/twoMaintenance?id=" + id);
        return;
      case "spotCheck":
        push("/index.html/spotCheck?id=" + id);
        return;
      case "shipMaintenance":
        push("/shipDetail/shipMaintenance?id=" + id);
        return;
      case "shipUpkeep":
        push("/shipDetail/shipUpkeep?id=" + id);
        return;
      default:
        Toast.show("跳转开发中");
        return;
    }
  };

  const onSearch = (value: string) => {
    setCondition(value);
    setSelectID({ id: "", label: "", value: [] });
    setTableData([]);
    setTablePagination({
      pageNO: 1,
      pageSize: 20,
      hasMore: true,
    });
    setVisible(false);
  };
  const onClear = () => {
    setCondition("");
    setSelectID({ id: "", label: "", value: [] });
    setTableData([]);
    setTablePagination({
      pageNO: 1,
      pageSize: 20,
      hasMore: false,
    });
    setVisible(false);
  };

  const toQuickRepairPage = () => push("/LedgerList/quickRepairSinglePage");

  return (
    <div>
      <NavBar onBack={back}>{title}</NavBar>
      <div className={style.body}>
        <SearchBar
          onSearch={onSearch}
          onClear={onClear}
          infoType={props.infoType}
        />
        {!condition && (
          <div className={style.category}>
            <div className={style.categoryIcon}>
              {props.infoType === "quickRepair" && (
                <Button onClick={toQuickRepairPage}>部门报修</Button>
              )}
              <FirstCatalogImg />
            </div>
            <span>{selectID.label || "请选择类型"}</span>
            <Button onClick={cascaderOpen}>选择类型</Button>
          </div>
        )}
        <Cascader
          options={treeData}
          onConfirm={confirmHandle}
          visible={visible}
          onClose={cascaderClose}
        />
        {tableData.map((item) => {
          return (
            <div
              className={style["list-item"]}
              onClick={() => ledgerClick(item.id)}
            >
              <EquipmentImg className={style["icon-centre"]} />
              {item.name}
            </div>
          );
        })}
        <InfiniteScroll loadMore={loadMore} hasMore={tablePagination.hasMore} />
      </div>
    </div>
  );
};
export default LedgerList;
