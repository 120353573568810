import { useState, useEffect, useRef } from "react";
import {
  InfiniteScroll,
  Toast,
  Button,
  Popup,
  Form,
  Input,
  TextArea,
  Radio,
  DatePicker,
} from "antd-mobile";
import dayjs from "dayjs";
import { NetState, Dateformat, MyUUIDValidateEasy } from "@utils";
import {
  GetInfoMtProjectSettlementList,
  GetStairStairUpkeepRecordList,
  GetUpdateStairUpkeepRecordById,
  GetUkProjectSettlementList,
} from "@axios";
import {
  GetAppSpotCheckApplicationRecord,
  GetSpotCheckApplication,
  GetQuickMaintainApplicationRecord,
  GetQuickMaintainApplication,
  GetQuickMaintainApplicationContent,
  FormComplete,
} from "@axios/form";
import { FILE } from "@axios/config";
import Table from "@components/table";
import style from "./index.module.less";
import { clone } from "lodash";

interface IHeads {
  [key: string]: tableType.columnsType[];
}
interface custom {
  [key: string]: any;
}
interface propsType {
  infoType: string;
  info: any;
}
const rowKeys: custom = {
  repair: "number",
  oneMaintenance: "id",
  twoMaintenance: "id",
};
/**
 * 维修类别
 */
const projectTypeEnums: custom = {
  0: "大修",
  1: "中修",
  2: "小修",
  3: "非计划维修",
};
/**
 * 点检状态
 */
const spotCheckEnums: custom = {
  0: "开始点检",
  1: "结束点检",
};
/**
 * 快捷维修状态
 */
const quickEnums: custom = {
  0: "未开始",
  1: "执行中",
  2: "已完成",
};
/**
 * 日期选择渲染
 * @param type
 * @param data
 * @returns
 */
const labelRenderer = (type: string, data: any) => {
  switch (type) {
    case "year":
      return data + "年";
    case "month":
      return data + "月";
    case "day":
      return data + "日";
    case "hour":
      return data + "时";
    case "minute":
      return data + "分";
    default:
      return data;
  }
};

interface ISpotCheckStatistics {
  startCount: number;
  endPoinCount: number;
  spotCheckRate: string;
}
interface ISpotCheckRecord {
  /** 点检状态 */
  spotCheck: string;
  /** 描述 */
  description: string;
  /** 点检异常附件 */
  appSpotCheckApplicationFiles: {
    id: string;
    /** 附件名称 */
    name: string;
    /** 文件路径 */
    filePath: string;
  }[];
  /** 开始/结束点检时间 */
  time: string;
  /** 点检人 */
  spotCheckUserName?: string;
}

interface IQuickData {
  id: string;
  /** 报修日期 */
  repairsDate: string;
  /** 工程编号 */
  maintainNumber: string;
  /** 财务编码 */
  maintainFinanceNumber: string;
  /** 是否需要备件 */
  isNeedSparePare: 0 | 1;
  /** 维修内容 */
  deviceFaultDescribe: string;
  /** 维修计划内容 */
  maintainContent: string;
  /** 相关照片 */
  overhaulFileUpload: {
    id: string;
    /** 附件名称 */
    name: string;
    /** 文件路径 */
    filePath: string;
  }[];
  /** 发起人 */
  originator: string;
  /** 发起日期 */
  initiationTime: string;
}

const Record = (props: propsType) => {
  const { infoType, info } = props;

  // 是否有更多数据
  const [hasMore, setHasMore] = useState(false);
  // 当前页码
  const [pageNo, setPageNo] = useState(1);
  // 是否正在加载数据
  const [isload, setIsload] = useState(false);
  // 详细信息弹窗层
  const [popupVisible, setPopupVisible] = useState(false);
  // 详细信息弹窗层数据
  const [popupData, setPopupData] = useState<any>({});
  // 表格数据(记录数据)
  const [dataSource, setDataSource] = useState<any[]>([]);
  // 点检内容
  const [spotCheckDataSource, setSpotCheckDataSource] = useState<any[]>([]);
  /** 快捷维修详情 */
  const [quickData, setQuickData] = useState<IQuickData>({
    id: "",
    repairsDate: "",
    maintainNumber: "",
    maintainFinanceNumber: "",
    isNeedSparePare: 0,
    deviceFaultDescribe: "",
    maintainContent: "",
    overhaulFileUpload: [],
    originator: "",
    initiationTime: "",
  });
  //#region 点检
  // 时间段
  const [spotCheckTime, setspotCheckTime] = useState({ start: "", end: "" });
  // 时间选择弹窗
  const [timeVisible, setTimeVisible] = useState(false);
  /** 点检数据统计 */
  const [spotCheckStatistics, setSpotCheckStatistics] =
    useState<ISpotCheckStatistics>({
      startCount: 0,
      endPoinCount: 0,
      spotCheckRate: "0.00%",
    });
  const pageSize = 10;
  /** 点检数据查看详情 */
  const [spotCheckRecord, setSpotCheckRecord] = useState<ISpotCheckRecord>({
    spotCheckUserName: "",
    spotCheck: "",
    description: "",
    appSpotCheckApplicationFiles: [],
    time: "",
  });
  /** 表格选中 */
  const selectedRows = useRef<any[]>([]);
  const tableRef = useRef<{ cleanSelect: () => void }>();

  //#endregion

  /**
   * 页面标题
   */
  const popupTitles: custom = {
    repaircontent: "维修内容",
    repair: "维修记录",
    oneMaintenance: (
      <>
        <div>
          <span>保养时间: {popupData.createdTime}</span>
          <span>保养人: {popupData.upkeepPerson}</span>
        </div>
        <p>备注: {popupData.remark}</p>
      </>
    ),
    twoMaintenance: "二级保养记录",
    spotCheckSubmit: "点检",
    spotCheck: popupData.spotCheckTime,
    quickEnd: "结束维修",
    quick: "快捷维修记录",
  };
  // 记录表头
  const heads: IHeads = {
    //维修记录
    repair: [
      { name: "序号", render: (data, i) => i + 1 },
      { name: "工程日期", dataIndex: "engineeringDate" },
      {
        name: "类型",
        render: (data) => projectTypeEnums[data.projectTypeEnum],
      },
      {
        name: "操作",
        render: (data) => (
          <a
            href="#!"
            onClick={() => {
              setPopupData(data);
              setPopupVisible(true);
            }}
          >
            查看
          </a>
        ),
      },
    ],
    //一级保养记录
    oneMaintenance: [
      { name: "序号", render: (data, i) => i + 1 },
      { name: "保养日期", dataIndex: "createdTime" },
      { name: "保养人", dataIndex: "upkeepPerson" },
      {
        name: "操作",
        render: (data) => (
          <a href="#!" onClick={() => getoneMaintenanceInfo(data.id)}>
            查看
          </a>
        ),
      },
    ],
    //二级保养记录
    twoMaintenance: [
      { name: "序号", render: (data, i) => i + 1 },
      { name: "工程日期", dataIndex: "engineeringDate" },
      {
        name: "操作",
        render: (data) => (
          <a
            href="#!"
            onClick={() => {
              setPopupData(data);
              setPopupVisible(true);
            }}
          >
            查看
          </a>
        ),
      },
    ],
    //点检记录
    spotCheck: [
      { name: "序号", dataIndex: "key" },
      {
        name: "点检时间",
        dataIndex: "time",
        render: (data) => (
          <span>
            {data.time
              ? dayjs(
                  new Date(data.time.split("-").join("/")),
                  "YYYY-MM-DD HH"
                ).format("YY-MM-DD HH")
              : ""}
          </span>
        ),
      },
      { name: "点检人", dataIndex: "spotCheckUserName" },
      {
        name: "类别",
        dataIndex: "spotCheckTypeName",
      },
      {
        name: "结论",
        dataIndex: "isNormal",
        render: (data) => (
          <span className={data?.isNormal ? style.normal : style.abnormal}>
            {data?.isNormal ? "正常" : "异常"}
          </span>
        ),
      },
      {
        name: "内容",
        render: (data) => (
          <a
            href="#!"
            onClick={() => {
              if (data?.id) getSpotCheckRecord(data);
            }}
          >
            查看
          </a>
        ),
      },
    ],
    //点检记录-点检内容
    spotCheckContent: [
      {
        name: "序号",
        dataIndex: "key",
        render: (data: any, index: any) => <span>{index + 1}</span>,
      },
      { name: "内容详情", dataIndex: "content" },
      {
        name: "正常",
        dataIndex: "isNormal",
        render: (data: any) => (
          <div className={style.checkboxWarn}>
            <input
              className={style["my-checkbox-input"]}
              type="checkbox"
              checked={data.isNormal}
              onChange={(e) => console.log(e)}
            />
            <span
              className={style["my-checkbox-core-normal"]}
              onChange={(e) => console.log(e)}
            ></span>
          </div>
        ),
      },
      {
        name: "异常",
        dataIndex: "isNormal",
        render: (data: any) => (
          <div className={style.checkboxWarn}>
            <input
              className={style["my-checkbox-input"]}
              type="checkbox"
              checked={!data.isNormal}
              onChange={(e) => console.log(e)}
            />
            <span className={style["my-checkbox-core"]}></span>
          </div>
        ),
      },
    ],
    // 结束快速维修
    quickEnd: [
      { name: "维修内容", dataIndex: "data2" },
      {
        name: "操作",
        render: (data) => (
          <a
            href="#!"
            onClick={() => {
              if (data?.id) getQuickRecord(data.id);
            }}
          >
            查看
          </a>
        ),
      },
    ],
    // 快速维修记录
    quick: [
      { name: "序号", dataIndex: "key" },
      { name: "维修时间", dataIndex: "data1" },
      {
        name: "完成状态",
        dataIndex: "data2",
        // render: (data) => quickEnums[data.quickPerson],
      },
      {
        name: "操作",
        render: (data) => (
          <a
            href="#!"
            onClick={() => {
              if (data?.id) getQuickRecord(data.id);
            }}
          >
            查看
          </a>
        ),
      },
    ],
  };
  /**
   * 一级保养详情表头
   */
  const oneMaintenanceColumns: tableType.columnsType[] = [
    { name: "保养内容", dataIndex: "content" },
    {
      name: "是否保养",
      render: (data) => (data.isCheck ? "是" : "否"),
    },
  ];

  /**
   * 获取一级保养详细信息
   * @param id
   * @returns
   */
  const getoneMaintenanceInfo = (id: string) => {
    if (!id) {
      Toast.show("保养信息错误");
      return;
    }
    GetUpdateStairUpkeepRecordById(id).then((res) => {
      if (res?.statusCode !== NetState.Ok) {
        Toast.show(res.message);
        return;
      }
      setPopupData(res.value);
      setPopupVisible(true);
    });
  };

  useEffect(() => {
    if (info.id) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  }, [info.id]);

  /**
   * 是否有更多数据
   * @returns
   */
  const loadMore = async () => {
    if (!info.id) return;
    let getRequest;
    switch (infoType) {
      case "repair":
        getRequest = GetInfoMtProjectSettlementList;
        break;
      case "oneMaintenance":
        getRequest = GetStairStairUpkeepRecordList;
        break;
      case "twoMaintenance":
        getRequest = GetUkProjectSettlementList;
        break;
      case "spotCheck":
        return getSpotCheck();
      case "quick":
        return getQuick();
      case "quickEnd":
        return getQuickEnd();
      default:
        setHasMore(false);
        return;
    }
    setIsload(true);
    return getRequest(info.id, pageNo, pageSize)
      .then((res) => {
        if (res?.statusCode !== NetState.Ok) {
          Toast.show(res.message);
          setHasMore(false);
          return;
        }
        setDataSource((e) => [...e, ...res.value.data]);
        setPageNo(pageNo + 1);
        if (!res || !res.value || !res.value.data || res.value.data.length < 10)
          setHasMore(false);
      })
      .catch(() => setHasMore(false))
      .finally(() => {
        setIsload(false);
      });
  };

  //#region 快捷维修 完工
  /** 获取快捷维修 待完工 */
  const getQuickEnd = () => {
    setIsload(true);
    const RequestBody = {
      deviceId: info.id,
      pageNo,
      pageSize,
    };
    return GetQuickMaintainApplicationContent(RequestBody)
      .then((res) => {
        if (res?.statusCode !== NetState.Ok) {
          Toast.show(res?.message || "加载快捷维修记录失败");
          setHasMore(false);
          return;
        }
        setDataSource((e) => e.concat(res.value.item1));
        const pageNo = RequestBody.pageNo + 1;
        if (pageNo >= Math.ceil(res.value.item2 / RequestBody.pageSize)) {
          setHasMore(false);
        }
      })
      .catch(() => setHasMore(false))
      .finally(() => {
        setIsload(false);
      });
  };

  /** 快捷维修 批量完工 */
  const quickMaintainFormComplete = () => {
    let arr: string[] = [];
    if (popupVisible) {
      if (MyUUIDValidateEasy(quickData.id)) {
        arr.push(quickData.id);
      } else {
        Toast.show({
          icon: "fail",
          content: "系统出现未知错误\nQMFC-01",
        });
        return;
      }
    } else {
      const keys = selectedRows.current
        .filter((array) => array?.id)
        .map((array) => array.id);
      if (keys.length) {
        arr = keys;
      } else {
        Toast.show({
          icon: "fail",
          content: "请选择需要完工的数据",
        });
        return;
      }
    }
    setIsload(true);
    FormComplete(arr)
      .then((res) => {
        if (res?.statusCode === 200 && res.value) {
          Toast.show({
            icon: "success",
            content: "完工成功",
          });
          setDataSource([]);
          setHasMore(true);
          setPageNo(1);
          setPopupVisible(false);
          tableRef.current?.cleanSelect();
          selectedRows.current = [];
        } else {
          return res;
        }
      })
      .catch((error) => {
        if (error) console.error(error);
        Toast.show(error?.message || "完工快捷维修记录失败");
      })
      .finally(() => {
        setIsload(false);
      });
  };
  //#endregion

  //#region 快捷维修
  const getQuick = () => {
    setIsload(true);
    const RequestBody = {
      deviceId: info.id,
      pageNo,
      pageSize,
    };
    return GetQuickMaintainApplicationRecord(RequestBody)
      .then((res) => {
        if (res?.statusCode !== NetState.Ok) {
          Toast.show(res?.message || "加载快捷维修记录失败");
          setHasMore(false);
          return;
        }
        setDataSource((e) => e.concat(res.value.item1));
        const pageNo = RequestBody.pageNo + 1;
        if (pageNo >= Math.ceil(res.value.item2 / RequestBody.pageSize)) {
          setHasMore(false);
        }
      })
      .catch(() => setHasMore(false))
      .finally(() => {
        setIsload(false);
      });
  };

  const getQuickRecord = (id: string) => {
    setIsload(true);
    GetQuickMaintainApplication(id)
      .then((res) => {
        if (res?.statusCode === 200) {
          const item1 = res?.value.item1;
          const item2 =
            Array.isArray(res?.value.item2) && res.value.item2.length
              ? res.value.item2[0]
              : {
                  auditDate: "",
                  auditPersonName: "",
                };
          setQuickData({
            id: item1.id,
            repairsDate: item1.repairsDate || "",
            maintainNumber: item1.maintainNumber || "",
            maintainFinanceNumber: item1.maintainFinanceNumber || "",
            isNeedSparePare: item1.isNeedSparePare ? 1 : 0,
            deviceFaultDescribe: item1.deviceFaultDescribe || "",
            maintainContent: item1.maintainContent || "",
            overhaulFileUpload: item1.overhaulFileUpload || [],
            originator: item2.auditPersonName || "",
            initiationTime: item2.auditDate || "",
          });
          setPopupVisible(true);
        } else {
          return Promise.reject(res);
        }
      })
      .catch((error) => {
        Toast.show({
          icon: "fail",
          content: error?.message || "获取快捷维修表单信息失败",
        });
      })
      .finally(() => {
        setIsload(false);
      });
  };
  //#endregion

  //#region 点检

  /** 获取点检记录表格数据 */
  const getSpotCheck = () => {
    setIsload(true);
    const params = {
      id: info.id,
      sTime: spotCheckTime.start,
      eTime: spotCheckTime.end,
      pageNo,
      pageSize: 10,
    };
    return GetAppSpotCheckApplicationRecord(params)
      .then((res) => {
        if (res?.statusCode !== NetState.Ok) {
          Toast.show(res?.message || "加载点检记录失败");
          setHasMore(false);
          return;
        }
        setDataSource((e) => e.concat(res.value.item1));
        const pageNo = params.pageNo + 1;
        setSpotCheckStatistics({
          startCount: res.value.item3,
          endPoinCount: res.value.item4,
          spotCheckRate: res.value.item5,
        });
        if (pageNo >= Math.ceil(res.value.item2 / params.pageSize)) {
          setHasMore(false);
        }
      })
      .catch(() => setHasMore(false))
      .finally(() => {
        setIsload(false);
      });
  };

  /** 获取点检记录表格数据
   * @param {any}data
   */
  const getSpotCheckRecord = (data: any) => {
    setIsload(true);
    GetSpotCheckApplication(data.id)
      .then((res) => {
        if (res?.statusCode === 200) {
          setSpotCheckRecord({
            spotCheck: spotCheckEnums[res.value.spotCheckType],
            description: res.value.description || "无",
            appSpotCheckApplicationFiles:
              res.value.appSpotCheckApplicationFiles,
            time: data.time,
            spotCheckUserName: res.value.spotCheckUserName,
          });
          setPopupVisible(true);
          setSpotCheckDataSource(res.value.appSpotCheckApplicationContents);
        }
      })
      .finally(() => {
        setIsload(false);
      });
  };

  /** 设置点检搜索时间段 */
  const changeSpotCheckTime = (date: Date) => {
    const newSpotCheckTime = clone(spotCheckTime);
    if (newSpotCheckTime.start) {
      newSpotCheckTime.end = Dateformat(date, "spotCheck");
      setTimeVisible(false);
      setDataSource([]);
      setHasMore(true);
      setPageNo(1);
    } else {
      newSpotCheckTime.start = Dateformat(date, "spotCheck");
    }

    setspotCheckTime(newSpotCheckTime);
  };
  /** 清空点检搜索时间段 */
  const cleanSpotCheckTime = () => {
    if (spotCheckTime.end) {
      setDataSource([]);
      setHasMore(true);
      setPageNo(1);
    }
    setspotCheckTime({
      start: "",
      end: "",
    });
  };

  //#endregion

  return (
    // 记录
    <>
      {infoType === "spotCheck" && (
        // 点检时间段选择和总数
        <>
          <div className={style.spotCheckTime}>
            <span>
              时间段<span className={style.bold}>:</span>
            </span>
            <Button onClick={() => setTimeVisible(true)}>
              {spotCheckTime.end
                ? `${spotCheckTime.start} - ${spotCheckTime.end}`
                : "点击选择时间"}
            </Button>
            <Button color="primary" onClick={cleanSpotCheckTime}>
              清空
            </Button>
            <DatePicker
              visible={timeVisible}
              title={spotCheckTime.start ? "结束时间" : "开始时间"}
              min={
                spotCheckTime.start ? new Date(spotCheckTime.start) : undefined
              }
              max={new Date()}
              precision="minute"
              renderLabel={labelRenderer}
              onCancel={() => setTimeVisible(false)}
              onConfirm={changeSpotCheckTime}
            />
          </div>
          <div className={style.spotCheckTotal}>
            <div>
              开始点检数<span className={style.bold}>: </span>
              <span style={{ color: "#1299ff" }}>
                {spotCheckStatistics.startCount}
              </span>
            </div>
            <div>
              结束点检数<span className={style.bold}>: </span>
              <span style={{ color: "#7a6bee" }}>
                {spotCheckStatistics.endPoinCount}
              </span>
            </div>
            <div>
              点检率<span className={style.bold}>: </span>
              <span style={{ color: "#2dd46c" }}>
                {spotCheckStatistics.spotCheckRate}
              </span>
            </div>
          </div>
        </>
      )}
      <Table
        rowKey={rowKeys[infoType] ? rowKeys[infoType] : "id"}
        key={infoType + "table"}
        columns={heads[infoType]}
        dataSource={dataSource}
        rowSelection={infoType === "quickEnd" && { type: "checkbox" }}
        onSelection={(d) => {
          selectedRows.current = d;
        }}
        ref={tableRef}
      />
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
      {infoType === "quickEnd" && (
        <Button
          className={style.CheckButton}
          onClick={quickMaintainFormComplete}
        >
          一键完工
        </Button>
      )}
      <Popup
        showCloseButton
        visible={popupVisible}
        onMaskClick={() => setPopupVisible(false)}
        onClose={() => setPopupVisible(false)}
        bodyStyle={{
          overflow: "auto",
          // minHeight: "40vh",
          // maxHeight: "80vh",
          minHeight: "80vh",
          maxHeight: "90vh",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        <div className={style.popup}>
          {popupTitles[infoType] && (
            <div className={style.title}>{popupTitles[infoType]}</div>
          )}
          <div className={style.content}>
            {infoType === "repair" ? (
              // 维修详情
              <Form layout="horizontal">
                <Form.Item label="工程日期 :">
                  <Input disabled value={popupData.engineeringDate || ""} />
                </Form.Item>
                <Form.Item label="工程编号 :">
                  <Input disabled value={popupData.number || ""} />
                </Form.Item>
                <Form.Item label="维修类型 :">
                  <Input
                    disabled
                    value={projectTypeEnums[popupData.projectTypeEnum] || ""}
                  />
                </Form.Item>
                <Form.Item label="使用部门 :">
                  <Input disabled value={popupData.departmentName || ""} />
                </Form.Item>
                <Form.Item label="工程结算费(元) :">
                  <Input disabled value={popupData.closingCost || 0} />
                </Form.Item>
                <Form.Item label="内容 :">
                  <Input disabled value={popupData.content || ""} />
                </Form.Item>
                <Form.Item label="工程耗材 :">
                  <a href="#!">查看详情</a>
                </Form.Item>
              </Form>
            ) : infoType === "oneMaintenance" ? (
              // 一级保养详情
              <>
                <Table
                  rowKey="id"
                  columns={oneMaintenanceColumns}
                  dataSource={popupData.stairStairUpkeepContents}
                />
                <div className={style.ImgsTitle}>此次保养图片</div>
                <div className={style.Imgs}>
                  {popupData.stairStairUpkeepFiles &&
                    popupData.stairStairUpkeepFiles.map((f: any) => (
                      <img key={f.id} src={FILE + f.filePath} alt="error" />
                    ))}
                </div>
              </>
            ) : infoType === "twoMaintenance" ? (
              // 二级保养详情
              <Form layout="horizontal">
                <Form.Item label="工程日期 :">
                  <Input disabled value={popupData.engineeringDate || ""} />
                </Form.Item>
                <Form.Item label="工程编号 :">
                  <Input disabled value={popupData.projectNumber || ""} />
                </Form.Item>
                <Form.Item label="收益部门 :">
                  <Input disabled value={popupData.departmentName || ""} />
                </Form.Item>
                <Form.Item label="工程结算费(元) :">
                  <Input disabled value={popupData.totalSum || 0} />
                </Form.Item>
                <Form.Item label="保养内容 :">
                  <TextArea
                    disabled
                    value={
                      popupData.maintainContent &&
                      popupData.maintainContent.reduce(
                        (total: string, currentValue: string) =>
                          total ? total + "," + currentValue : currentValue,
                        ""
                      )
                    }
                  />
                </Form.Item>
                <Form.Item label="工程耗材 :">
                  <a href="#!">查看详情</a>
                </Form.Item>
              </Form>
            ) : infoType === "spotCheck" ? (
              // 点检详情
              <div>
                <p className={style.fontTitleAlign}>{spotCheckRecord.time}</p>
                <p
                  className={style.fontTitle}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>点检类别: {spotCheckRecord.spotCheck}</span>
                  <span>
                    点检人: {spotCheckRecord.spotCheckUserName || "--"}
                  </span>
                </p>
                <p className={style.fontTitle}>
                  <span>点检时间: {spotCheckRecord.time}</span>
                </p>
                <div>
                  {/* 需求变更，添加点检内容 */}
                  <Table
                    rowKey={"id"}
                    key={"spotCheck-table"}
                    currentModule="pointCheckContent"
                    columns={heads["spotCheckContent"]}
                    dataSource={spotCheckDataSource}
                    // dataSource={[
                    //   {
                    //     key: 1,
                    //     id: "11",
                    //     content: "情节",
                    //     isNormal: true,
                    //   },
                    //   {
                    //     key: 2,
                    //     id: "12",
                    //     content: "机器",
                    //     isNormal: false,
                    //   },
                    // ]}
                    rowSelection={false}
                  />
                </div>
                <p className={style.fontTitle}>缺陷描述</p>
                <p>{spotCheckRecord.description}</p>
                <p className={style.fontTitle}>相关图片</p>
                <div className={style.Imgs}>
                  {spotCheckRecord.appSpotCheckApplicationFiles.map((f) => (
                    <img key={f.id} src={FILE + f.filePath} alt={f.name} />
                  ))}
                </div>
              </div>
            ) : (
              (infoType === "quickEnd" || infoType === "quick") && (
                // 快捷维修记录详情(结束快捷维修/快捷维修记录)
                <Form layout="horizontal">
                  <Form.Item label="工程日期 :">
                    <Input disabled value={quickData.repairsDate} />
                  </Form.Item>
                  <Form.Item label="工程编号 :">
                    <Input disabled value={quickData.maintainNumber} />
                  </Form.Item>
                  <Form.Item label="财务编码 :">
                    <Input disabled value={quickData.maintainFinanceNumber} />
                  </Form.Item>
                  <Form.Item label="是否需要备件 :">
                    <Radio.Group disabled value={quickData.isNeedSparePare}>
                      <Radio value={1}>是</Radio>
                      <Radio value={0}>否</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item label="维修计划内容 :">
                    <Input disabled value={quickData.maintainContent} />
                  </Form.Item>
                  <Form.Item label="维修内容 :">
                    <Input disabled value={quickData.deviceFaultDescribe} />
                  </Form.Item>
                  <Form.Item label="相关照片 :">
                    <div className={style.Imgs}>
                      {quickData.overhaulFileUpload.map((f) => (
                        <img key={f.id} src={FILE + f.filePath} alt={f.name} />
                      ))}
                    </div>
                  </Form.Item>
                  <Form.Item label="发起人 :">
                    <Input disabled value={quickData.originator} />
                  </Form.Item>
                  <Form.Item label="发起时间 :">
                    <Input disabled value={quickData.initiationTime} />
                  </Form.Item>
                </Form>
              )
            )}
            {infoType === "quickEnd" && (
              <Button
                className={style.CheckButton}
                onClick={quickMaintainFormComplete}
              >
                维修完工
              </Button>
            )}
          </div>
        </div>
      </Popup>
    </>
  );
};
export default Record;
