import React, { ReactNode, useMemo, useState, useEffect } from "react";
import { map, isFunction } from "lodash";
import { Form, Toast, Button } from "antd-mobile";
import { useMyLocation } from "@/hooks";
import {
  GetOutsourceProjectApplications,
  DeleteOutsourceProjectApplication,
  postOutsourceProjectAppl,
} from "@axios/form";
import { NetState } from "@/utils";
import { getFormInputDom } from "@utils/formDom";
import Tamplate from "@components/from/Tamplate";
import Process from "@components/from/Process";
import styles from "./index.module.less";
import { push } from "@utils/common";

const isShenZhenYouLian = process.env.REACT_APP_Version
  ? process.env.REACT_APP_Version === "ShenZhenYouLian"
  : true;

const OutsourceProjectApplication: React.FC = () => {
  const { state: LocationState } = useMyLocation<Form.IRouterLocationState>();
  const [formItems] = useState<Array<Form.FormItem<any>>>([
    { key: "deviceName", title: "设备名称", option: { type: "input" } },
    { key: "jobNumber", title: "工程编号", option: { type: "input" } },
    { key: "departmentName", title: "申请单位", option: { type: "input" } },
    {
      key: "earningsDepartmentName",
      title: "受益部门",
      option: { type: "input" },
    },
    { key: "projectNumber", title: "项目编号", option: { type: "input" } },
    {
      key: "outSouReason",
      title: "外协原因",
      option: {
        type: "radio",
        datas: [
          {
            value: 0,
            label: "设备能力不足",
          },
          {
            value: 1,
            label: "产能不足",
          },
          {
            value: 2,
            label: "技术工艺不足",
          },
          {
            value: 3,
            label: "其他",
          },
        ],
      },
    },
    { key: "name", title: "名称", option: { type: "input" } },
    { key: "specification", title: "规格", option: { type: "input" } },
    { key: "quantity", title: "数量", option: { type: "inputNumber" } },
    {
      key: "projectContent",
      title: "工程/加工内容简述",
      option: { type: "textArea" },
    },
    { key: "finishDate", title: "要求完工日期", option: { type: "input" } },
    {
      key: "detailScienceAskFor",
      title: "详细技术要求",
      option: { type: "textArea" },
    },
    {
      key: "applicationPersonName",
      title: "申请人",
      option: { type: "input" },
    },
    { key: "applicationDate", title: "日期", option: { type: "input" } },
    { key: "picotaName", title: "申请单位负责人", option: { type: "input" } },
    { key: "phone", title: "联系电话", option: { type: "input" } },
    { key: "fgfzName", title: "分管副总", option: { type: "input" } },
    { key: "zjlName", title: "总经理", option: { type: "input" } },
    { key: "outsourceName", title: "外协单位", option: { type: "input" } },
    { key: "quotation", title: "报价(元)", option: { type: "inputNumber" } },
    {
      key: "transportation",
      title: "运输费(元)",
      option: { type: "inputNumber" },
    },
    { key: "rooName", title: "经营方代表确认", option: { type: "input" } },
    { key: "acceptor", title: "验收人", option: { type: "input" } },
    {
      key: "bottom",
      title: "bottom",
      option: { type: "none" },
      render: () => (
        <div style={{ fontSize: "0.6rem", display: "flex" }}>
          <div>注：</div>
          <div>
            1.申请方验收后须在验收人一栏签字，并将此单归档保存
            2.本单一式四联，第一联工程管理部外协主管存档，第二联交经营代表，第三联交财务部，第四联申请单位存档
          </div>
        </div>
      ),
    },
  ]);
  const [formData, setFormDate] =
    useState<OutsourceProjectApplication.FormData | null>(null);
  const [formId, setFormId] = useState("");
  const [formRef] = Form.useForm();

  useEffect(() => {
    if (LocationState?.formId) {
      setFormId(LocationState.formId);
      getForm(LocationState.formId);
    } else {
      Toast.show("跳转表单出错");
      setTimeout(() => {
        push("/home");
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (formRef && formData) formRef.resetFields();
  }, [formData, formRef]);

  const getForm = (formId: string) => {
    GetOutsourceProjectApplications(formId)
      .then((res: IRes<OutsourceProjectApplication.FormData>) => {
        if (res?.statusCode !== NetState.Ok)
          return Toast.show({
            icon: "fail",
            content: res?.message || "获取表单数据失败",
          });
        setFormDate(res.value);
      })
      .catch((error) => {
        Toast.show("获取表单内容出错");
        setTimeout(() => {
          push("/home");
        }, 500);
      });
  };
  const getFormF = () => getForm(formId);

  /** 表单主体 */
  const formItemsDom = useMemo<ReactNode>(() => {
    return map(formItems, (item) => {
      const { key, title, titleRender, option } = item;
      if (key === "outSouReason" && !isShenZhenYouLian) {
        return null;
      }
      if (option?.type === "none")
        return item.render ? item.render(formData) : null;
      else
        return (
          <Form.Item
            key={`form-item-${key}`}
            name={key}
            label={
              titleRender
                ? isFunction(titleRender)
                  ? titleRender()
                  : titleRender
                : title
                ? `${title}:`
                : ""
            }
          >
            {item.render ? item.render(formData) : getFormInputDom(item)}
          </Form.Item>
        );
    });
  }, [formItems]);

  return (
    <Tamplate title="维修外协申请单">
      <Form
        form={formRef}
        layout="horizontal"
        initialValues={formData ? formData : {}}
      >
        {formItemsDom}
      </Form>

      <table
        cellSpacing={0}
        className={`borderTable ${styles.attachmentTable}`}
      >
        <thead>
          <tr>
            <td colSpan={3}>附件列表</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>序号</td>
            <td>附件名称</td>
            <td>操作</td>
          </tr>
          {formData &&
            map(formData.outsourceProjectApplicationFiles, (f, i) => (
              <tr key={f.id}>
                <td>{i + 1}</td>
                <td>{f.name}</td>
                <td>
                  <Button color="primary" fill="none">
                    下载
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {formId && (
        <Process
          formId={formId}
          onUpdateForm={getFormF}
          auditCallback={postOutsourceProjectAppl}
          deleteForm={DeleteOutsourceProjectApplication}
        />
      )}
    </Tamplate>
  );
};

export default OutsourceProjectApplication;
