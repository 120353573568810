import { useEffect, useState } from "react";
import { history } from "@utils/common";
import { NavBar } from "antd-mobile";
import Table from "@components/table";
import { getInventoryTree } from "@axios";
import style from "./index.module.less";

/**
 * 盘点记录
 * @returns
 */
const InventoryRecord = () => {
  // 盘点记录数据
  const [recordList, setRecordList] = useState([]);
  // 已盘点总数和状态
  const [info, setInfo] = useState<any>({});
  const columns = [
    { name: "设备名称", dataIndex: "name" },
    { name: "上期盘点日期", dataIndex: "inventoryDate" },
  ];
  useEffect(() => {
    getInventoryTree().then((res: any) => {
      setRecordList(res.value[0].children);
      setInfo(res.value[0] || {});
    });
  }, []);
  return (
    <div className={style.inventoryRecord}>
      <NavBar onBack={() => history.go(-1)} className={style.navBar}>
        盘点记录
      </NavBar>
      <div className={style.content}>
        <div className={style.title}>
          <div>
            <p>
              <span>盘点时间段：</span>
              <span></span>
            </p>
            <p>
              <span>已盘点台账总数：</span>
              <span>{info.totalInventoryCount}</span>
            </p>
          </div>
          <div
            className={`${style.inventory_state} ${
              (info.inventoryStatus === 0 || info.inventoryStatus === 3) &&
              style.inventory_state_not
            }`}
          >
            {info.inventoryStatusCode}
          </div>
        </div>
        <Table columns={columns} dataSource={recordList} overflow />
      </div>
    </div>
  );
};
export default InventoryRecord;
