import { FC, useRef, useContext } from "react";
import { MobXProviderContext } from "mobx-react";
import { Radio, Space, NavBar, Popover, SpinLoading } from "antd-mobile";
import type { Action } from "antd-mobile/es/components/popover/popover-menu";
import { MoreOutline } from "antd-mobile-icons";
import { history, push } from "@utils/common";
import styles from "./index.module.less";
import { map } from "lodash";
import { useMyLocation } from "@hooks";

const FormTemplate: FC<FormTemplate.Iprops> = (props) => {
  const {
    title,
    showHeaderCompany = true,
    children,
    companyId,
    formDataLoading,
  } = props;

  const { state: LocationState } = useMyLocation<any>();
  const {
    GlobalStore: { companys },
  } = useContext(MobXProviderContext);
  const wrapRef = useRef<HTMLDivElement | null>(null);

  /**
   * 下拉菜单点击
   * @param node 点击的菜单
   */
  const handleClickPopoverMenu = (node: Action) => {
    switch (node.key) {
      case "jump":
        const form = wrapRef.current?.getElementsByClassName("adm-form");
        if (
          wrapRef.current &&
          form &&
          form[0] &&
          wrapRef.current.scrollTop < (form[0] as any).offsetHeight
        ) {
          wrapRef.current.scrollTop = (form[0] as any).offsetHeight;
        }
        break;
    }
  };

  return (
    <div className={styles.wrap}>
      <NavBar
        right={
          <Popover.Menu
            trigger="click"
            placement="bottom-start"
            onAction={handleClickPopoverMenu}
            actions={[{ key: "jump", text: "跳转到审批" }]}
          >
            <MoreOutline />
          </Popover.Menu>
        }
        onBack={() => {
          if (LocationState?.formLogin) {
            push("/home");
          } else {
            history.back();
          }
        }}
      >
        {title}
      </NavBar>
      <div ref={wrapRef} className={styles.content}>
        {showHeaderCompany && (
          <Radio.Group value={companyId}>
            <Space style={{ "--gap": "12px" }}>
              {map(companys, (c) => (
                <Radio key={c.id} value={c.id}>
                  {c.name}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        )}
        {children}
      </div>
      {formDataLoading && (
        <div className={styles.mask}>
          <SpinLoading color="primary" style={{ "--size": "3rem" }} />
        </div>
      )}
    </div>
  );
};

export default FormTemplate;
