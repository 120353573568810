import React, { Suspense, lazy } from "react";
// import { BrowserRouter, HashRouter, Routes, Route } from "react-router-dom";
import {
  Routes,
  Route,
  unstable_HistoryRouter as HistoryRouter,
} from "react-router-dom";
import { history } from "@utils/common";
import Login from "@page/login";
import Home from "@page/home";
import NotFoundImg from "./style/imgs/404.png";
import Maintain from "@page/maintain";
import EquipmentDetails from "@page/equipmentDetails";
import MaintainWorkApplication from "@maintainWorkForm/Application";
import MaintainWorkProjectCostBudget from "@maintainWorkForm/ProjectCostBudget";
import MaintainWorkProjectFitApplication from "@maintainWorkForm/ProjectFitApplication";
import MaintainWorkOutsourceProjectApplication from "@maintainWorkForm/OutsourceProjectApplication";
import MaintainWorkHorizontalContact from "@maintainWorkForm/HorizontalContact";
import UpkeepWorkApplication from "@upkeepWorkForm/Application";
import UpkeepCheck from "@upkeepWorkForm/UpkeepCheck";
import LedgerList from "@page/ledgerList";
import InventoryRecord from "@page/inventoryRecord";

const AssetRequisition = React.lazy(
  () => import("@page/form/purchaseWork/assetRequisition")
);
const Recorded = lazy(() => import("@page/form/purchaseWork/recorded"));
const BuildApproval = lazy(
  () => import("@/page/form/facilityBuildWork/buildApproval")
);
const Warehousing = lazy(
  () => import("@page/form/basicinformation/maintenanceMaterials/warehousing")
);
const Receivingmaterials = lazy(
  () =>
    import(
      "@page/form/basicinformation/maintenanceMaterials/receivingmaterials"
    )
);
const ScrapSheet = lazy(
  () => import("@page/form/basicinformation/toolLibrary/scrapSheet")
);
const Inbound = lazy(
  () => import("@/page/form/basicinformation/turnaroundMaterial/inbound")
);
const ScrapApplication = lazy(
  () =>
    import("@page/form/basicinformation/turnaroundMaterial/scrapApplication")
);
const UpkeepSparePartPurchaseApplication = lazy(
  () => import("@page/form/upkeepWork/upkeepSparePartPurchaseApplication")
);
const SparePartPurchaseApplication = lazy(
  () => import("@page/form/maintainWork/sparePartPurchaseApplication")
);
const ProductionEquipmentMaintenance2 = lazy(
  () => import("@page/form/maintainWork/ProductionEquipmentMaintenance2")
);
const BuildApproval2 = lazy(
  () => import("@/page/form/facilityBuildWork/buildApproval2")
);
const FRecorded = lazy(() => import("@/page/form/facilityBuildWork/recorded"));

const ProjectForm = lazy(() => import("@page/form/program/projectForm"));
const ProjectForm2 = lazy(() => import("@page/form/program/projectForm2"));
const Program = lazy(() => import("@/page/form/program/program"));
const MaintenanceForm = lazy(
  () => import("@/page/form/program/maintenanceForm")
);
const UpkeepForm = lazy(() => import("@/page/form/program/upkeepForm"));
const NewForm = lazy(() => import("@/page/form/program/newForm"));
const PurchaseForm = lazy(() => import("@/page/form/program/purchaseForm"));

const ScrapForm = lazy(() => import("@/page/form/management/scrapForm"));
const WaijieForm = lazy(() => import("@/page/form/management/waijieForm"));
const TransfersForm = lazy(
  () => import("@/page/form/management/transfersForm")
);
const InventoryApproval = lazy(
  () => import("@/page/form/management/inventoryApproval")
);

const QuickStart = lazy(() => import("@/page/equipmentDetails/quickStart"));
const QuickRepairSinglePage = lazy(
  () => import("@/page/ledgerList/quickRepairSinglePage")
);

// ------ 船舶 ------
const ShipWarrantyWork = lazy(() => import("@page/form/shipWarrantyWork"));
const MonthlyRecordSheet = lazy(
  () => import("@/page/form/shipWarrantyWork/MonthlyRecordSheet")
);
// 计划
const ShipProgram = lazy(() => import("@/page/form/program/shipProgram"));
const AnnualRepairForm = lazy(() => import("@/page/form/program/shipPlan/page/AnnualRepairForm"));
const ShipMaintenanceForm = lazy(() => import("@/page/form/program/shipPlan/page/ShipMaintenanceForm"));
const BudgetPlanForm =  lazy(() => import("@/page/form/program/shipPlan/page/BudgetPlanForm"));
// 工作
const InspectionMaintenance = lazy(()=> import('@page/form/shipWork/InspectionMaintenance'));
const SparePart = lazy(() => import("@/page/form/shipWork/sparePart"));
const ShipWarehousing = lazy(()=> import('@page/form/shipThirdParty/Warehousing'));
const OutsourceProjectApplications = lazy(()=> import('@page/form/shipThirdParty/ProjectOutsourcingSheet'));
const ProductionEquipmentMaintenance = lazy(()=> import('@/page/form/shipThirdParty/ProductionEquipmentMaintenance'));
const ProjectApprovalSheet = lazy(()=> import('@/page/form/shipThirdParty/ProjectApprovalSheet'));

const ShipDetail = lazy(() => import("@page/shipDetail"));

const NotFound = <img src={NotFoundImg} alt="404" />;

const REACT_APP_Version = process.env.REACT_APP_Version
  ? process.env.REACT_APP_Version
  : "ShenZhenYouLian";

const detailsRoutes: { [key: string]: any } = {
  ShenZhenYouLian: [
    "repaircontent",
    "repair",
    "oneMaintenance",
    "twoMaintenance",
    "spotCheckSubmit",
    "spotCheck",
    "quickEnd",
    "quick",
  ],
  ZhouShanYouLian: [
    "repaircontent",
    "repair",
    "oneMaintenance",
    "twoMaintenance",
    "maintenanceWrok",
  ],
  ShenZhenNoLogin: [
    "oneMaintenance",
    "spotCheck",
  ],
};

const RouterDetail = (
  <Suspense fallback={<div>Loading...</div>}>
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="home" element={<Home />} />
      <Route path="maintainWork">
        <Route index element={NotFound} />
        <Route path="application" element={<MaintainWorkApplication />} />
        <Route
          path="projectCostBudget"
          element={<MaintainWorkProjectCostBudget />}
        />
        <Route
          path="projectFitApplication"
          element={<MaintainWorkProjectFitApplication />}
        />
        <Route
          path="outsourceProjectApplication"
          element={<MaintainWorkOutsourceProjectApplication />}
        />
        <Route
          path="horizontalContact"
          element={<MaintainWorkHorizontalContact />}
        />
        <Route
          path="sparePartPurchaseApplication"
          element={<SparePartPurchaseApplication />}
        />
        <Route
          path="ProductionEquipmentMaintenance2"
          element={<ProductionEquipmentMaintenance2 />}
        />
      </Route>
      <Route path="upkeepWork">
        <Route index element={NotFound} />
        <Route path="application" element={<UpkeepWorkApplication />} />
        <Route path="upkeepCheck" element={<UpkeepCheck />} />
        <Route
          path="upkeepSparePartPurchaseApplication"
          element={<UpkeepSparePartPurchaseApplication />}
        />
      </Route>
      <Route path="index.html">
        <Route index element={<EquipmentDetails />} />
        <Route path="quickStart" element={<QuickStart />} />
        {detailsRoutes[REACT_APP_Version]?.map((infoType: string) => (
          <Route
            key={infoType}
            path={infoType}
            element={<EquipmentDetails infoType={infoType} />}
          />
        ))}
      </Route>
      <Route path="shipDetail">
        <Route index element={<ShipDetail />} />
        <Route
          path="shipMaintenance"
          element={<ShipDetail infoType="shipMaintenance" />}
        />
        <Route
          path="shipUpkeep"
          element={<ShipDetail infoType="shipUpkeep" />}
        />
      </Route>
      <Route path="inventoryRecord" element={<InventoryRecord />} />
      <Route path="LedgerList">
        <Route index element={NotFound} />
        <Route
          path="quickRepair"
          element={<LedgerList infoType="quickRepair" />}
        />
        <Route
          path="maintenance"
          element={<LedgerList infoType="maintenance" />}
        />
        <Route
          path="oneMaintenance"
          element={<LedgerList infoType="oneMaintenance" />}
        />
        <Route
          path="twoMaintenance"
          element={<LedgerList infoType="twoMaintenance" />}
        />
        <Route path="spotCheck" element={<LedgerList infoType="spotCheck" />} />
        <Route
          path="quickRepairSinglePage"
          element={<QuickRepairSinglePage />}
        />
      </Route>
      <Route path="shipList">
        <Route index element={NotFound} />
        <Route
          path="shipMaintenance"
          element={<LedgerList infoType="shipMaintenance" />}
        />
        <Route
          path="shipUpkeep"
          element={<LedgerList infoType="shipUpkeep" />}
        />
      </Route>
      <Route path="purchaseWork">
        <Route index element={NotFound} />
        <Route path="assetRequisition" element={<AssetRequisition />} />
        <Route path="recorded" element={<Recorded />} />
      </Route>
      <Route path="facilityBuildWork">
        <Route index element={NotFound} />
        <Route path="buildApproval" element={<BuildApproval />} />
        <Route path="buildApproval2" element={<BuildApproval2 />} />
        <Route path="recorded" element={<FRecorded />} />
      </Route>
      <Route path="basicinformation">
        <Route index element={NotFound} />
        <Route path="maintenanceMaterials">
          <Route index element={NotFound} />
          <Route path="warehousing" element={<Warehousing />} />
          <Route path="receivingmaterials" element={<Receivingmaterials />} />
        </Route>
        <Route path="toolLibrary">
          <Route index element={NotFound} />
          <Route path="scrapSheet" element={<ScrapSheet />} />
        </Route>
        <Route path="turnaroundMaterial">
          <Route index element={NotFound} />
          <Route path="inbound" element={<Inbound />} />
          <Route path="scrapApplication" element={<ScrapApplication />} />
        </Route>
        <Route path="buildApproval" element={<BuildApproval />} />
      </Route>
      {/* 船舶外协项目 */}
      <Route path="shipThirdParty">
        <Route index element={NotFound} />
        <Route path="warehousing" element={<ShipWarehousing />}></Route>
        <Route path="outsourceProjectApplications" element={<OutsourceProjectApplications />}></Route>
        <Route path="productionEquipmentMaintenance" element={<ProductionEquipmentMaintenance />}></Route>
        <Route path="projectApprovalSheet" element={<ProjectApprovalSheet />}></Route>
      </Route>
      {/* 计划 */}
      <Route path="program">
        <Route index element={NotFound} />
        <Route path="projectForm" element={<ProjectForm />} />
        <Route path="projectForm2" element={<ProjectForm2 />} />
        <Route path="program" element={<Program />} />
        <Route path="maintenanceForm" element={<MaintenanceForm />} />
        <Route path="upkeepForm" element={<UpkeepForm />} />
        <Route path="newForm" element={<NewForm />} />
        <Route path="purchaseForm" element={<PurchaseForm />} />
      </Route>
        {/* 船舶计划 */}
      <Route path="shipProgram">
        <Route index element={NotFound} />
        <Route path="shipProgram" element={<ShipProgram />} />
        <Route path="annualRepairForm" element={<AnnualRepairForm />} />
        <Route path="shipMaintenanceForm" element={<ShipMaintenanceForm />} />
        <Route path="budgetPlanForm" element={<BudgetPlanForm />} />
      </Route>
      {/* 使用管理 */}
      <Route path="management">
        <Route index element={NotFound} />
        <Route path="scrapForm" element={<ScrapForm />} />
        <Route path="waijieForm" element={<WaijieForm />} />
        <Route path="transfersForm" element={<TransfersForm />} />
        <Route path="inventoryApproval" element={<InventoryApproval />} />
      </Route>
      <Route path="maintain" element={<Maintain />} />
      {/* 月度维护保养计划 */}
      <Route path="shipWarrantyWork">
        <Route index element={<ShipWarrantyWork />} />
        <Route path="MonthlyRecordSheet" element={<MonthlyRecordSheet />} />
      </Route>
      <Route path="shipWork">
        <Route index element={NotFound} />
        <Route path="InspectionMaintenance" element={<InspectionMaintenance />} />
        <Route path="SparePart" element={<SparePart />} />
      </Route>
      <Route path="*" element={NotFound} />
    </Routes>
  </Suspense>
);

// eslint-disable-next-line react-hooks/exhaustive-deps, import/no-anonymous-default-export
export default () => (
  // process.env.NODE_ENV === "production" ? (
  <HistoryRouter history={history as any} basename={process.env.REACT_APP_PREFIX}>
    {RouterDetail}
  </HistoryRouter>
);
// <BrowserRouter>{RouterDetail}</BrowserRouter>
// ) : (
//   <HashRouter>{RouterDetail}</HashRouter>
// );
