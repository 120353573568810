import { useState } from "react";
import type { FC } from "react";
import { SearchBar as ANTDSearchBar, Button, Toast } from "antd-mobile";
import { ScanQRCode } from "@components/icon";
import style from "../index.module.less";
import {  push } from "@utils/common";

interface IPorps {
  onSearch: (value: string) => void;
  onClear: () => void;
  infoType: string;
}

const SearchBar: FC<IPorps> = ({ onSearch, onClear, infoType }) => {
  const [value, setValue] = useState("");
  const scanQRcodehandle = () => {
    switch (infoType) {
      case "quickRepair":
        push('/index.html/quick');
        return;
      case "maintenance":
        push("/index.html/repair" );
        return;
      case "oneMaintenance":
        push("/index.html/oneMaintenance");
        return;
      case "twoMaintenance":
        push("/index.html/twoMaintenance");
        return;
      case "spotCheck":
        push("/index.html/spotCheck" );
        return;
      default:
        return "未知";
    }
  };

  const mySearch = () => onSearch(value);
  const myClear = () => onClear();

  return (
    <div className={style.header}>
      <div className={style["search-bar-box"] + " ledger-list-search-bar-box"}>
        <ANTDSearchBar
          placeholder="请输入内容"
          style={{
            "--border-radius": "10px",
            "--background": "#f5f5f5",
            "--height": "40px",
            "--padding-left": "12px",
          }}
          value={value}
          onChange={(value: string) => setValue(value)}
          onSearch={onSearch}
          onClear={myClear}
        />
        <ScanQRCode
          className={style["scan-QRcode-icon"]}
          onClick={scanQRcodehandle}
        />
      </div>
      <Button
        color="primary"
        fill="outline"
        className={style.Button}
        onClick={mySearch}
      >
        搜索
      </Button>
    </div>
  );
};

export default SearchBar;
