import { get, post, del, put } from "./tools";
import { MOCK_API } from "./config";

/** ---------- 公用 ---------- */
/** 判断流程是否存在 */
export const WorkflowInstanceExist = (formId: TFormID) => get({ url: `${MOCK_API}/Workflow/WorkflowInstanceExist?formId=${formId}` });
/** 工作提醒跳转获取流程信息 */
export const GetWorkflowInfoByFormId = (formId: TFormID) => get({ url: `${MOCK_API}/Workflow/GetWorkInstanceByFormId?formId=${formId}` });
/** 判断是否能撤回表单 */
export const IsWithdrawalForm = (formId: TFormID) => get({ url: `${MOCK_API}/Workflow/IsWithdrawalForm?formId=${formId}` });
/** 判断是否能删除表单 */
export const IsDeleteForm = (formId: TFormID, currentStepId: string) => get({ url: `${MOCK_API}/Workflow/IsDeleteForm?formId=${formId}&currentStepId=${currentStepId}` });
/** 当前登录人是否能审批表单 */
export const UnplannedSubmitMaintainApplication = (workflowId: TFormID) => post({ url: `${MOCK_API}/Workflow/UnplannedSubmitMaintainApplication?workflowId=${workflowId}` });
/** 获取表单流程 */
export const GetWorkflowStepsPresentList = (workflowId: TFormID) => get({ url: `${MOCK_API}/Workflow/GetWorkflowStepsPresentList?workflowId=${workflowId}` });
/** 获取实际审核节点列表 */
export const getWorkflowNodeInstanceList = (workflowId: TFormID) => get({ url: `${MOCK_API}/Workflow/GetWorkflowNodeInstanceList?workflowId=${workflowId}` });
/** 获取台账部门公司信息 */
export const GetAssetInfoDepartmentDto = (id: string): Promise<IRes<Readonly<AxiosForm.RGetAssetInfoDepartmentDto>> | undefined> => get({ url: `${MOCK_API}/BasicInformation/GetAssetInfoDepartmentDto?id=${id}` });

/** ---------- 维修申请单 ---------- */
/** 获取维修申请单信息 */
export const GetMaintainApplication = (id: TFormID) => get({ url: `${MOCK_API}/MaintainWork/GetMaintainApplication?id=${id}` });
/** 删除维修申请单 */
export const DeleteMaintainApplication = (id: TFormID) => del({ url: `${MOCK_API}/MaintainWork/DeleteMaintainApplication?id=${id}` })
// 提交维修申请单
export const SubmitMaintainApplication = (data: any, planId?: string) => {
  let url = `MaintainWork/SubmitMaintainApplication`;
  if (planId) url += `?planId=${planId}`;
  return post({ url: `${MOCK_API}/${url}`, data });
};
// 非计划维修申请提交
export const SubmitUnplannedMaintainApplication = (projectType: number, data: any) =>
  post({ url: `${MOCK_API}/MaintainWork/UnplannedSubmitMaintainApplication?projectType=${projectType}`, data });
// 编辑计划维修申请单
export const putUpdateMaintainApplication = (data: any): IAxios<any> =>
  put({ url: `${MOCK_API}/MaintainWork/UpdateMaintainApplication`, data });
// 维修申请单节点审批
export const postMaintainApplicationNextStep = (params: Form.IAuditReq) => {
  return post({ url: `${MOCK_API}/MaintainWork/MaintainApplicationNextStep`, config: { params } });
};

/** 维修备件采购申请 获取 */
export const GetMaintainSparePartPurchaseApplication = (id: TFormID) => get({ url: `${MOCK_API}/MaintainWork/GetMaintainSparePartPurchaseApplication?id=${id}` });
/** 维修备件采购申请 审批 */
export const MaintainSparePartPurchaseApplicationNextStep = (params: Form.IAuditReq) => get({ url: `${MOCK_API}/MaintainWork/MaintainSparePartPurchaseApplicationNextStep`, config: { params } });
/** 删除维修备件采购申请 */
export const DeleteMaintainSparePartPurchaseApplication = (id: TFormID) => del({ url: `${MOCK_API}/MaintainWork/DeleteMaintainSparePartPurchaseApplication?id=${id}` })
/** 获取工程配合单 */
export const MGetMaintainProjectFitApplications = (id: TFormID) => get({ url: `${MOCK_API}/MaintainWork/GetMaintainProjectFitApplications?id=${id}` });
/** 删除工程配合单 */
export const MDeleteMaintainProjectFitApplication = (id: TFormID) => del({ url: `${MOCK_API}/MaintainWork/DeleteMaintainProjectFitApplication?id=${id}` });
/** 维修工程配合单节点审批 */
export const postMaintainProjectFitApplicationNextStep = (params: Form.IAuditReq) => {
  return post({
    url: `${MOCK_API}/MaintainWork/MaintainProjectFitApplicationNextStep`,
    config: { params }
  });
};
/** 维修外协单节点审批 */
export const postOutsourceProjectAppl = (params: Form.IAuditReq) =>
  post({
    url: `${MOCK_API}/MaintainWork/OutsourceProjectApplicationNextStep`,
    config: { params }
  });


// 浙江舟山
// -----维修
// 获取横向单内容
export const GetCrosswiseMaintainProjectFitApplications = (id: TFormID) =>
  get({ url: `${MOCK_API}/ZjMaintainProjectFitApplication/GetMaintainProjectFitApplications?id=${id}` });

// 横向单节点审批
export const CrosswiseMaintainProjectFitApplicationNextStep = (
  params: Form.IAuditReq
) =>
  post({
    url: `${MOCK_API}/ZjMaintainProjectFitApplication/MaintainProjectFitApplicationNextStep?${params}`,
    config: { params },
  });
// 删除横向单
export const DeleteCrosswiseMaintainProjectFitApplication = (id: TFormID) =>
  del({
    url: `${MOCK_API}/ZjMaintainProjectFitApplication/DeleteMaintainProjectFitApplication?id=${id}`,
  });


/** ---------- 成本预算审批单 ---------- */
/** 获取成本预算单信息 */
export const GetProjectCostBudget = (id: TFormID) => get({ url: `${MOCK_API}/MaintainWork/GetProjectCostBudget?id=${id}` });
/** 成本预算单审批 */
export const ProjectCostBudgetNextStep = (params: Form.IAuditReq) => post({ url: `${MOCK_API}/MaintainWork/ProjectCostBudgetNextStep`, config: { params } });
export const DeleteProjectCostBudget = (id: TFormID) => del({ url: `${MOCK_API}/MaintainWork/DeleteProjectCostBudget?id=${id}` })

/** ---------- 工程配合单 ---------- */
/** 获取工程配合单信息 */
export const GetMaintainProjectFitApplications = (id: TFormID) => get({ url: `${MOCK_API}/MaintainWork/GetMaintainProjectFitApplications?id=${id}` });
/** 工程配合单审批 */
export const MaintainProjectFitApplicationNextStep = (params: Form.IAuditReq) => post({ url: `${MOCK_API}/MaintainWork/MaintainProjectFitApplicationNextStep`, config: { params } });
/** 删除工程配合单 */
export const DeleteMaintainProjectFitApplication = (id: TFormID) => del({ url: `${MOCK_API}/MaintainWork/DeleteMaintainProjectFitApplication?id=${id}` })

/** ---------- 外协申请单 ---------- */
/** 获取外协申请单 */
export const GetOutsourceProjectApplications = (id: TFormID) => get({ url: `${MOCK_API}/MaintainWork/GetOutsourceProjectApplications?id=${id}` });
export const DeleteOutsourceProjectApplication = (id: TFormID) => del({ url: `${MOCK_API}/MaintainWork/DeleteMaintainApplication?id=${id}` })

/** ---------- 保养工作 ---------- */

/** 保养申请单 */
export const GetUpkeepApplication = (id: TFormID) => get({ url: `${MOCK_API}/UpkeepWork/GetUpkeepApplication?id=${id}` });
export const SubmitUpkeepApplicationWork = (data: any, planId: string) =>
  post({
    url: `${MOCK_API}/UpkeepWork/SubmitUpkeepApplicationWork`,
    data,
    config: { params: { planId } },
  });
// 编辑保养申请单
export const putUpdateUpkeepApplication = (data: any) =>
  put({ url: `${MOCK_API}/UpkeepWork/UpdateUpkeepApplication`, data });
// 保养申请单节点审批
export const postUPWPlanApproval = (params: Form.IAuditReq) =>
  post({ url: `${MOCK_API}/UpkeepWork/PlanApproval?`, config: { params } });
// 删除保养申请单
export const DeleteUpkeepApplication = (id: TFormID) =>
  del({ url: `${MOCK_API}/UpkeepWork/DeleteUpkeepApplication?id=${id}` });

/** 获取保养备件采购申请信息详情 */
export const GetUpkeepSparePartPurchaseApplication = (id: TFormID) => get({ url: `${MOCK_API}/UpkeepWork/GetUpkeepSparePartPurchaseApplication?id=${id}` });
// 保养备件采购节点审批
export const postUpkeepPurchaseApproval = (params: Form.IAuditReq) => post({ url: `${MOCK_API}/UpkeepWork/UpkeepSparePartPurchaseApplicationNextStep?${params}` });
// 删除采购单
export const DeleteUpkeepSparePartPurchaseApplication = (data: string) => del({ url: `${MOCK_API}/UpkeepWork/DeleteUpkeepSparePartPurchaseApplication?id=${data}` });

/** ---------- 保养检查申请单 ---------- */
/** 获取保养检查申请单 */
export const GetUpkeepCheckApplicationsDto = (id: TFormID) => get({ url: `${MOCK_API}/UpkeepWork/GetUpkeepCheckApplicationsDto?id=${id}` });
/** 删除保养检查 */
export const DeleteUpkeepCheckApplication = (id: TFormID) => post({ url: `${MOCK_API}/PurchaseWork/DeleteUpkeepCheckApplication?id=${id}` });
/** 工程配合单审批 */
export const UpkeepApplicationNextStep = (params: Form.IAuditReq) => post({ url: `${MOCK_API}/UpkeepWork/UpkeepApplicationNextStep`, config: { params } });

/** ---------- 购置工作 ---------- */

/** 查询固定资产申购单 */
export const GetFixedAssetRequisitionById = (id: TFormID) => get({ url: `${MOCK_API}/PurchaseWork/GetFixedAssetRequisitionById?id=${id}` });
/** 固定资产申购单流程审批 */
export const postPlanApproval = (params: Form.IAuditReq) => post({ url: `${MOCK_API}/PurchaseWork/PlanApproval`, config: { params } });
/** 删除固定资产申购单 */
export const DeleteFixedAssetRequisition = (id: TFormID) => post({ url: `${MOCK_API}/PurchaseWork/DeleteFixedAssetRequisition?id=${id}`, });
/** 删除固定资产申购单附件 */
export const DeleteFixedAssetRequisitionAttachment = (id: TFormID) => post({ url: `${MOCK_API}/PurchaseWork/DeleteFixedAssetRequisitionAttachment?id=${id}`, });
/** 获取入账 */
export const GetRecordedById = (id: TFormID) => get({ url: `${MOCK_API}/PurchaseWork/GetRecordedById?id=${id}` });
/** 购置入账节点审批 */
export const postRecordedApproval = (params: Form.IAuditReq) => post({ url: `${MOCK_API}/PurchaseWork/RecordedApproval`, config: { params } });
/** 删除入账单 */
export const DeleteRecorded = (id: TFormID) => post({ url: `${MOCK_API}/PurchaseWork/DeleteRecorded?id=${id}` });

/** ---------- 新建工作 ---------- */

// 新建工作新建申请节点审批
export const postNewApplicationApproval = (params: Form.IAuditReq) => post({ url: `${MOCK_API}/FacilityBuildWork/NewApplicationApproval`, config: { params } });
/** 获取新建申请 */
export const GetNewApplicationById = (id: TFormID) => get({ url: `${MOCK_API}/FacilityBuildWork/GetNewApplicationById?id=${id}` });
/** 删除新建申请单
 * @param {TFormID} id TFormID
 */
export const DeleteNewApplication = (id: TFormID) => get({ url: `${MOCK_API}/FacilityBuildWork/DeleteNewApplication?id=${id}` });

// 获取立项单内容
export const GetProjectNewApplicationById = (id: TFormID) =>
  get({ url: `${MOCK_API}/ZjNewApplication/GetNewApplicationById?id=${id}` });
// 删除立项单
export const DeleteProjectNewApplication = (id: TFormID) =>
  del({ url: `${MOCK_API}/ZjNewApplication/DeleteNewApplication?id=${id}` });
// 立项单节点审批
export const NewProjectApplicationApproval = (params: Form.IAuditReq) =>
  post({
    url: `${MOCK_API}/ZjNewApplication/NewApplicationApproval`,
    config: { params },
  });

// 入账
export const GetAccountingApplicationById = (id: TFormID) =>
  get({ url: `${MOCK_API}/FacilityBuildWork/GetAccountingApplicationById?id=${id}` });
// 入账流程审批
export const postRzApproval = (params: Form.IAuditReq) =>
  post({ url: `${MOCK_API}/FacilityBuildWork/RzApproval`, config: { params } });
// 删除入帐单
export const DeleteAccountingApplication = (id: TFormID) =>
  del({ url: `${MOCK_API}/FacilityBuildWork/DeleteAccountingApplication?id=${id}` });

/** ---------- 基本资料  ---------- */

/** 查询入库单信息 */
export const getInStorageInfo = (id: TFormID) => get({ url: `${MOCK_API}/SpareMaintain/GetInStorageInfo?storageRequestId=${id}` });
/** 查询领料单信息 */
export const getPickRequestInfo = (id: TFormID) => get({ url: `${MOCK_API}/SpareMaintain/GetPickRequestInfo?pickRequestId=${id}` });
// 领料单删除
export const DeleteFormPickRequest = (id: TFormID) =>
  del({ url: `${MOCK_API}/SpareMaintain/DeleteFormPickRequest?id=${id}` });
// 入库删除
export const DeleteFormStorageRequest = (id: TFormID) =>
  del({ url: `${MOCK_API}/SpareMaintain/DeleteFormStorageRequest?id=${id}` });

/** 获取工具报废申请数据
 * @param params {id: 表单ID}
 * @returns
 */
export const GetToolsScrapApplication = (id: TFormID) =>
  get({ url: `${MOCK_API}/ToolsScrapApplication/GetToolsScrapApplication?id=${id}` });
/** 工具报废申请节点审批
 * @param {Form.IAuditReq} params Form.IAuditReq
 * @param {IScrapSheetForm} data IScrapSheetForm
 * @returns
 */
export const ApprovalToolsScrapApplication = (params: Form.IAuditReq, data: AxiosForm.IScrapSheetForm) =>
  post({
    url: `${MOCK_API}/ToolsScrapApplication/ApprovalToolsScrapApplication`,
    data,
    config: { params },
  });
/** 删除工具报废申请数据
* @param id 将要删除的表单ID
* @returns
*/
export const DeleteToolsScrapApplication = (id: TFormID) =>
  del({ url: `${MOCK_API}/ToolsScrapApplication/DeleteToolsScrapApplication?id=${id}` });

/** 获取周转材料报废/入库申请数据
 * @param id 表单ID
 * @returns
 */
export const GetRevolvingMaterialsScrapStorageApplication = (id: TFormID): Promise<IRes<Readonly<{
  item1: AxiosForm.IturnaroundMaterialForm;
  item2: AxiosForm.IApprovalInfo[];
}>> | undefined> =>
  get({
    url: `${MOCK_API}/RevolvingMaterialsScrapStorageApplication/GetRevolvingMaterialsScrapStorageApplication`,
    config: { params: { id } },
  });

/** 周转材料报废/入库申请节点审批
* @param {IApprovalParams} params 表单信息
* @param {IApprovalForm} data 表单数据
* @returns 
*/
export const ApprovalRevolvingMaterialsScrapStorageApplication = (
  params: Form.IAuditReq,
  data: AxiosForm.IInboundFormApproval
) =>
  post({
    url: `${MOCK_API}/RevolvingMaterialsScrapStorageApplication/ApprovalRevolvingMaterialsScrapStorageApplication`,
    config: { params },
    data,
  });

/** 删除周转材料报废/入库申请数据
* @param {string} id 表单ID
* @returns 
*/
export const DeleteRevolvingMaterialsScrapStorageApplication = (id: TFormID) =>
  del({
    url: `${MOCK_API}/RevolvingMaterialsScrapStorageApplication/DeleteRevolvingMaterialsScrapStorageApplication`
    , config: { params: { id } }
  })

// ------ 计划类 ------
// 获取维修经验工程类型
export const GetMaintainProjectType = () => get({ url: `${MOCK_API}/EnumKeyValue/GetMaintainProjectType` });
// 获取计划审批完成状态枚举
export const GetPlanFinishStatusEnum = () => get({ url: `${MOCK_API}/EnumKeyValue/GetPlanFinishStatusEnum` });
// 计划审批状态
export const GetPlanApprovalStatusEnum = () => get({ url: `${MOCK_API}/EnumKeyValue/GetPlanApprovalStatusEnum` });

/** 通过id获取立项信息 */
export const GetDeviceMaintenanceProjectById = (id: TFormID) =>
  get({
    url: `${MOCK_API}/DeviceMaintenanceProject/GetDeviceMaintenanceProjectById?id=${id}`,
  });
/** 立项节点审批 */
export const ProjectStepApproval = (params: Form.IAuditReq) => post({ url: `${MOCK_API}/DeviceMaintenanceProject/ProjectStepApproval`, config: { params } });
/** 删除立项 */
export const DeleteProject = (id: TFormID) =>
  del({ url: `${MOCK_API}/DeviceMaintenanceProject/DeleteDeviceMaintenanceProject?id=${id}` });
// 舟山立项
// 获取立项单内容
export const GetMaintainDeviceMaintenanceProjectById = (id: TFormID) =>
  get({ url: `${MOCK_API}/ZjDeviceMaintenanceProject/GetDeviceMaintenanceProjectById?id=${id}` });
// 立项单节点审批
export const MaintainProjectStepApproval = (params: Form.IAuditReq) => post({
  url: `${MOCK_API}/ZjDeviceMaintenanceProject/ProjectStepApproval`, config: { params },
});
// 删除立项单
export const MaintainDeleteDeviceMaintenanceProject = (id: TFormID) =>
  del({ url: `${MOCK_API}/ZjDeviceMaintenanceProject/DeleteDeviceMaintenanceProject?id=${id}` });



/** 获取计划列表信息 */
export const getPlanList = (params: any) =>
  get({ url: `${MOCK_API}/Workflow/GetPlanList`, config: { params } });
// 获取多条计划汇总信息
export const getPlanApprovalFormInfo = (id: TFormID) =>
  get({ url: `${MOCK_API}/Workflow/GetPlanApprovalFormInfo?formId=${id}` });
// 根据Id获取对应的维修计划s
export const GetMaintainPlanDetails = (id: TFormID) => get({ url: `${MOCK_API}/MaintainPlan/GetMaintainPlanDetails?maintainPlanId=${id}` });

// 根据Id获取对应的保养计划
export const GetUpkeepPlanDetails = (id: TFormID) =>
  get({ url: `${MOCK_API}/UpkeepPlan/GetUpkeepPlanDetails?upkeepPlanId=${id}` });
// 保养方案-物料
export const GetUpkeepMaterials = (id: TFormID) =>
  get({
    url: `${MOCK_API}/UpkeepPlan/GetUpkeepMaterials?assetLedgerTreeId=${id}`,
  });
// 保养内容
export const GetUpkeepContents = (id: TFormID) =>
  get({
    url: `${MOCK_API}/UpkeepPlan/GetUpkeepContents?assetLedgerTreeId=${id}`,
  });

// 根据Id获取对应的新建计划
export const GetFacilityBuildPlanById = (id: TFormID) =>
  get({ url: `${MOCK_API}/FacilityBuildPlan/GetFacilityBuildPlanById?facilityBuildPlanId=${id}` });

// 根据Id获取对应的 购置 计划
export const GetPurchasePlanById = (id: TFormID) => get({ url: `${MOCK_API}/PurchasePlan/GetPurchasePlanById?purchasePlanId=${id}` });

/** 维修计划审批 */
export const MPlanApproval = (params: Form.IAuditReq) => post({ url: `${MOCK_API}/MaintainPlan/PlanApproval`, config: { params } });
/** 购置计划审批 */
export const PurchasePlanApproval = (params: Form.IAuditReq) => post({ url: `${MOCK_API}/PurchasePlan/PurchasePlanApproval`, config: { params } });
/** 设施新建计划节点审批 */
export const FacilityPlanApproval = (params: Form.IAuditReq) => post({ url: `${MOCK_API}/FacilityBuildPlan/PlanApproval`, config: { params } });
/** 保养计划节点审批 */
export const UpkeepPlanApproval = (params: Form.IAuditReq) => post({ url: `${MOCK_API}/UpkeepPlan/PlanApproval`, config: { params } });
/** 删除汇总计划 */
export const DeletePlanApprovalFormInfo = (formId: TFormID, systemInfoEnum: number) =>
  del({ url: `${MOCK_API}/Workflow/DeletePlanApprovalFormInfo?formId=${formId}&systemInfoEnum=${systemInfoEnum}` });


// ------ 使用管理 ------


// 根据Id获取对应的 外借
export const GetLendInfoById = (id: TFormID) =>
  get({ url: `${MOCK_API}/LendInfo/GetLendInfoById?lendInfoId=${id}` });
// 根据Id获取对应的 调拨
export const GetAllocateInfoById = (id: TFormID) =>
  get({ url: `${MOCK_API}/AllocateInfo/GetAllocateInfoById?allocateInfoId=${id}` });
/** 根据Id获取对应的生产设备报废申请单 */
export const GetScrapInfoById = (id: TFormID) => get({ url: `${MOCK_API}/ScrapInfo/GetScrapInfoById?scrapInfoId=${id}` });
// 根据Id获取对应的 盘点
export const GetInventoryInfoById = (id: TFormID) =>
  get({ url: `${MOCK_API}/InventoryInfo/GetInventoryInfoById?inventoryInfoId=${id}` });
/** 获取盘点列表设备数据 */
export const GetInventoryDeviceInfos = (
  inventoryId: TFormID,
  pageNo: number,
  pageSize: number
) =>
  get({
    url: `${MOCK_API}/InventoryInfo/GetInventoryDeviceInfos`,
    config: {
      params: {
        inventoryId,
        pageNo,
        pageSize,
      },
    },
  });
// 获取盘点设备状态--启用|闲置|停用|拟报废
export const GetStatueInventory = (id: TFormID) =>
  get({ url: `${MOCK_API}/InventoryInfo/GetStatueInventory?inventoryId=${id}` });

// 设备外借审批
export const LendDeviceApproval = (params: Form.IAuditReq) =>
  post({ url: `${MOCK_API}/LendInfo/LendDeviceApproval`, config: { params } });
// 调拨流程审批
export const AllocateInfoApproval = (params: Form.IAuditReq) =>
  post({ url: `${MOCK_API}/AllocateInfo/AllocateInfoApproval`, config: { params } });
// 报废流程审批
export const ScrapApprovalNextStep = (params: Form.IAuditReq) =>
  post({ url: `${MOCK_API}/ScrapInfo/ScrapApprovalNextStep`, config: { params } });
/** 盘点流程审批 */
export const InventoryApprovalNextStep = (params: Form.IAuditReq) =>
  post({
    url: `${MOCK_API}/InventoryInfo/InventoryApprovalNextStep`,
    config: { params }
  });


// 外借删除
export const DeleteLendInfo = (data: string) =>
  del({ url: `${MOCK_API}/LendInfo/DeleteLendInfo?lendInfoId=${data}` });
// 调拨删除
export const DeleteAllocateInfo = (data: string) =>
  del({ url: `${MOCK_API}/AllocateInfo/DeleteAllocateInfo?allocateInfoId=${data}` });
// 报废删除
export const BDeleteScrapInfo = (data: string) =>
  del({ url: `${MOCK_API}/ScrapInfo/DeleteScrapInfo?scrapInfoId=${data}` });
// 盘点删除
export const DeleteInventoryInfo = (data: string) =>
  del({ url: `${MOCK_API}/InventoryInfo/DeleteInventoryInfo?inventoryInfoId=${data}` });

// 获取台账状态枚举
export const getUseStatusEnum = () =>
  get({ url: `${MOCK_API}/EnumKeyValue/GetUseStatusEnum` });

// 编辑调拨
export const UpdateAllocateInfo = (data: any) =>
  put({ url: `${MOCK_API}/AllocateInfo/UpdateAllocateInfo`, data });


// ------ 快捷维修 ------

/** 提交快捷维修申请表单 */
export const SubmitQuickMaintainApplication = (data: any) =>
  post({
    url: `${MOCK_API}/QuickMaintainApplication/SubmitQuickMaintainApplication`,
    data,
  });
/** 一键完工 */
export const FormComplete = (data: string[]): IAxios<boolean> =>
  post({
    url: `${MOCK_API}/QuickMaintainApplication/FormComplete`,
    data,
  });
/** 是否是自修 */
export const UpdateIsZx = (data: AxiosForm.IUpdateIsZx) =>
  post({ url: `${MOCK_API}/QuickMaintainApplication/UpdateIsZx`, data });
// 快捷维修申请节点审批
export const ApprovalQuickMaintainApplication = (params: Form.IAuditReq, data: AxiosForm.IApprovalQuickMaintainApplicationData) =>
  post({
    url: `${MOCK_API}/QuickMaintainApplication/ApprovalQuickMaintainApplication`,
    config: { params },
    data
  });
/** 获取快捷维修申请数据 */
export const GetQuickMaintainApplication = (
  id: TFormID
): Promise<IRes<AxiosForm.IGetQuickMaintainApplicationRes>> =>
  get({
    url: `${MOCK_API}/QuickMaintainApplication/GetQuickMaintainApplication?`,
    config: { params: { id } },
  });
// 获取快捷维修申请维修内容
export const GetQuickMaintainApplicationContent = (
  params: AxiosForm.IGetQuickMaintainApplicationContent
) =>
  get({
    url: `${MOCK_API}/QuickMaintainApplication/GetQuickMaintainApplicationContent?`,
    config: { params },
  });
// 获取快捷维修申请关联工作内容列表
export const GetQuickWorkContent = (
  params: AxiosForm.IGetQuickWorkContent
): Promise<IRes<AxiosForm.IGetQuickWorkContentRes>> =>
  get({
    url: `${MOCK_API}/QuickMaintainApplication/GetQuickWorkContent?`,
    config: { params },
  });
// 获取快捷维修申请记录
export const GetQuickMaintainApplicationRecord = (
  params: AxiosForm.IGetQuickMaintainApplicationContent
) =>
  get({
    url: `${MOCK_API}/QuickMaintainApplication/GetQuickMaintainApplicationRecord`,
    config: { params },
  });
// 删除快捷维修申请数据
export const DeleteQuickMaintainApplication = (id: TFormID) =>
  del({
    url: `${MOCK_API}/QuickMaintainApplication/DeleteQuickMaintainApplication`,
    config: { params: { id } },
  });
// 获取台账关联的财务编码
export const GetFinancialByDeviceId = (deviceId: string): IAxios<string> =>
  get({
    url: `${MOCK_API}/FinancialInfo/GetFinancialByDeviceId`,
    config: { params: { deviceId } },
  });
/** 根据部门获取快捷维修申请维修内容 */
export const GetQuickMaintainApplicationListByDepartmentId = (
  params: AxiosForm.IGetQuickMaintainApplicationListByDepartmentId
): IAxios<any> =>
  get({
    url: `${MOCK_API}/QuickMaintainApplication/GetQuickMaintainApplicationListByDepartmentId`,
    config: { params },
  });



// ------ 点检 ------


/** 提交点检申请表单 */
export const SubmitSpotCheckApplication = (
  data: AxiosForm.ISpotCheckApplication
): IAxios<any> =>
  post({
    url: `${MOCK_API}/SpotCheckApplication/SubmitSpotCheckApplication`,
    data,
  });
/** 获取点检申请数据 */
export const GetSpotCheckApplication = (
  id: TFormID
): IAxios<AxiosForm.IGetSpotCheckApplicationRes> =>
  get({
    url: `${MOCK_API}/SpotCheckApplication/GetSpotCheckApplication`,
    config: { params: { id } },
  });
/** 获取app设备加载点检历史数据
 * (item1-5)数据，总条数，开始点检数，结束点检数，点检率
 * @param params
 * @returns
 */
export const GetAppSpotCheckApplicationRecord = (
  params: AxiosForm.IGetAppSpotCheckApplicationRecord
): IAxios<AxiosForm.IGetAppSpotCheckApplicationRecordRes> =>
  get({
    url: `${MOCK_API}/SpotCheckApplication/GetAppSpotCheckApplicationRecord`,
    config: { params },
  });


/** 获取pc点检申请数据 */
export const GetSpotCheckApplicationPC = (
  ledgerId: TFormID
): IAxios<AxiosForm.IGetSpotCheckApplicationResPC[]> =>
  get({
    url: `${MOCK_API}/SpotCheckInfo/GetSpotCheckApplication`,
    config: { params: { ledgerId } },
  });

/**
* 获取维修工程列表
* @returns
*/
export const GetMaintainProjectList = (data = { storage: false }) =>
  get({ url: `${MOCK_API}/SpareMaintain/GetMaintainProjectList`, data });

// 根据分类获取其下所有的维保物料信息
export const GetMaterialDataByTreeId = (data: AxiosForm.IGetMaintainMaterialInformation) =>
  get({
    url: `${MOCK_API}/SpareMaintain/GetMaterialDataByTreeId`,
    config: { params: data },
  });
// 获取备件物料供应商信息
export const GetSparePartCategoryList = (id: string) =>
  get({ url: `${MOCK_API}/PickRequestForm/GetSparePartCategoryList?sparePartId=${id}` });
/**
 * 发起领料申请
 * @param data
 * @returns
 */
export const SubmitPickRequestForm = (data:any) =>
  post({ url: `${MOCK_API}/PickRequestForm/SubmitPickRequestForm`, data });

// 领料单审批
export const ApprovalPickRequestForm = (params: Form.IAuditReq, data: any) => {
  return post({
    url: `${MOCK_API}/PickRequestForm/ApprovalPickRequestForm`,
    data,
    config: { params },
  });
};
/**
* 根据维修编码获取物料数据
* @param maintainNumber 维修工程编号:
* @returns 
*/
export const GetPickingApplicationFormItemsByMaintainNumber = (maintainNumber:string) =>
 get({ url: `${MOCK_API}/PickRequestForm/GetPickingApplicationFormItemsByMaintainNumber?maintainNumber=${maintainNumber}` });