import { Dateformat, MyUUIDValidateEasy } from "@/utils";
import { uploadImgFile, GetInfo } from "@axios";
import { FILE } from "@axios/config";
import {
  SubmitSpotCheckApplication,
  GetSpotCheckApplication,
  GetSpotCheckApplicationPC,
} from "@axios/form";
import { NetState } from "@utils";
import { getImg, replace } from "@utils/common";
import { Button, Radio, TextArea, Toast, Checkbox } from "antd-mobile";
import { trim, cloneDeep } from "lodash";
import type { FC } from "react";
import { createRef, useState, useEffect } from "react";
import style from "./index.module.less";
import Table from "@components/table";
import { map } from "lodash";

interface IImgList {
  id?: string;
  name: string;
  filePath: string;
}

interface IPorps {
  assetInfoId: string;
  spotCheckInfoType: number;
}

const SpotCheckSubmit: FC<IPorps> = ({ assetInfoId, spotCheckInfoType }) => {
  const [spotCheckType, setSpotCheckType] = useState(0);
  const [isNormal, setIsNormal] = useState<any>(null);
  const [description, setDescription] = useState("");
  const [imgList, setImgList] = useState<IImgList[]>([]);
  const [loading, setUpLoading] = useState(false);
  // 点检内容
  const [dataSource, setDataSource] = useState<any[]>([]);

  const spotCheckColumns = [
    {
      name: "序号",
      dataIndex: "key",
      render: (data: any, index: any) => <span>{index + 1}</span>,
    },
    { name: "内容详情", dataIndex: "content" },
    {
      name: "正常",
      dataIndex: "isNormal",
      render: (data: any) => {
        let checked = !data.hasOwnProperty("isNormal")
          ? {}
          : { checked: data.isNormal };
        return (
          <div className={style.checkboxWarn}>
            <input
              className={style["my-checkbox-input"]}
              type="checkbox"
              {...checked}
              onChange={(e) => spotCheckCondition(e.target.checked, data)}
            />
            <span
              className={style["my-checkbox-core-normal"]}
              onClick={() =>
                spotCheckCondition(
                  data.hasOwnProperty("isNormal") ? !data.isNormal : true,
                  data
                )
              }
            ></span>
          </div>
        );
      },
    },
    {
      name: "异常",
      dataIndex: "isNormal",
      render: (data: any) => {
        let checked = !data.hasOwnProperty("isNormal")
          ? {}
          : { checked: !data.isNormal };
        return (
          <div className={style.checkboxWarn}>
            <input
              className={style["my-checkbox-input"]}
              type="checkbox"
              {...checked}
              onChange={(e) =>
                spotCheckCondition(
                  data.hasOwnProperty("isNormal") ? !e.target.checked : false,
                  data
                )
              }
            />
            <span
              className={style["my-checkbox-core"]}
              onClick={() =>
                spotCheckCondition(
                  data.hasOwnProperty("isNormal") ? !data.isNormal : false,
                  data
                )
              }
            ></span>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const interval = setTimeout(() => {
      console.log(
        "assetInfoId, spotCheckInfoType",
        assetInfoId,
        spotCheckInfoType
      );
      getInfo();
    }, 10);
    return () => {
      clearTimeout(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInfo = () => {
    if (!MyUUIDValidateEasy(assetInfoId)) {
      Toast.show({ icon: "fail", content: "提交获取台账信息失败" });
      return;
    }
    if (!spotCheckInfoType && typeof spotCheckInfoType !== "number") {
      Toast.show({ icon: "fail", content: "提交获取点检类型信息失败" });
      return;
    }
    GetInfo(assetInfoId).then((res) => {
      if (res?.statusCode === 200) {
        getSpotCheckApplicationPC(res.value.assetLedgerTreeId);
      }
    });
  };

  const getSpotCheckApplicationPC = (ledgerId: string) => {
    GetSpotCheckApplicationPC(ledgerId).then((res) => {
      if (res?.statusCode === 200) {
        let data: any = [];
        if (res.value.length) {
          res.value.forEach((item) => {
            if (item.spotCheckInfoType === spotCheckInfoType) {
              data.push({
                ...item,
                spotCheckInfoId: item.id,
              });
            } else if (item.spotCheckInfoType === spotCheckInfoType) {
              data.push({
                ...item,
                spotCheckInfoId: item.id,
              });
            }
          });
        }
        setDataSource(data);
      }
    });
  };

  const submit = () => {
    if (dataSource.some((x) => !x.hasOwnProperty("isNormal"))) {
      Toast.show("请选择点检内容");
      return;
    }
    if (!dataSource.length) {
      Toast.show("请联系管理员添加点检内容");
      return;
    }
    if (loading) return;
    if (!MyUUIDValidateEasy(assetInfoId)) {
      Toast.show({ icon: "fail", content: "提交获取台账信息失败" });
      return;
    }

    const user = localStorage.getItem("user");
    if (!user) return Toast.show("获取用户信息失败");
    const { user: userObj } = JSON.parse(user);

    let appSpotCheckApplicationFiles: any[] = [];
    let Ddescription = "";
    let newDataSource = cloneDeep(dataSource);

    newDataSource.map((x) => {
      delete x.id;
      return x;
    });

    if (!isNormal) {
      if (trim(description)) {
        Ddescription = description;
      } else {
        Toast.show("请输入点检结果");
        return;
      }

      if (!imgList.length) {
        Toast.show("请上传图片");
        return;
      }
      appSpotCheckApplicationFiles = imgList.map((a) => {
        delete a.id;
        return a;
      });
    }

    const reqBody: AxiosForm.ISpotCheckApplication = {
      assetInfoId,
      isNormal,
      spotCheckType,
      description: Ddescription,
      time: Dateformat(new Date(), "spotCheck"),
      appSpotCheckApplicationFiles,
      spotCheckUserName:userObj.name,
      spotCheckUserId:userObj.id,
      appSpotCheckApplicationContents: newDataSource,
      spotCheckInfoType:spotCheckInfoType,
    };
    setUpLoading(true);
    SubmitSpotCheckApplication(reqBody).then((res) => {
      if (res?.statusCode === 200) {
        Toast.show({ icon: "success", content: "提交点检成功！" });
        setTimeout(() => {
          replace("/index.html?id=" + reqBody.assetInfoId);
        }, 1000);
      }
    });
  };
  const fileNode: any = createRef();

  /** 触发上传 */
  const onOpenFileChooser = () => {
    if (!!fileNode) fileNode.current.click();
  };
  /**
   * 上传图片
   */
  const showPicture = (e: any) => {
    getImg(e.target).then(({ data, file }) => {
      const formData = new FormData();
      formData.append("file", file);
      uploadImgFile(formData)
        .then((res) => {
          if (res?.statusCode === NetState.Ok) {
            const newRecord = {
              name: res.data.FrName,
              filePath: res.data.FrPath,
            };

            setImgList((e) => [...e, newRecord]);
          } else {
            return Promise.reject(res?.message || "上传失败\n网络出错");
          }
        })
        .catch((error) => {
          Toast.show(error?.message || "上传失败\n网络出错");
        });
    });
  };

  const deleteImg = (filePath: string) => {
    if (!loading) setImgList(imgList.filter((e) => e.filePath !== filePath));
  };

  const spotCheckCondition = (checked: boolean, data: any) => {
    let newData = map(dataSource, (n) => {
      if (data.id === n.id) {
        n["isNormal"] = checked;
      }
      return n;
    });

    if (
      newData.some((x) => {
        return x.isNormal === false;
      })
    ) {
      setIsNormal(false);
    } else {
      setIsNormal(true);
    }
    console.log("判断股", newData, checked);
    setDataSource(newData);
  };

  const normalSpotCheck = () => {
    setIsNormal(true);
    setDataSource(
      map(dataSource, (n) => {
        n["isNormal"] = true;
        return n;
      })
    );
  };

  return (
    <>
      <input
        ref={fileNode}
        style={{ display: "none" }}
        id="fileInpBtn"
        type="file"
        onChange={showPicture}
        accept="image/*"
        multiple={false}
      />
      <div className={`${style.inputInfo} ${style.spotCheckInfo}`}>
        <div className={style.selectType}>
          点检类别
          <Radio.Group
            value={spotCheckType}
            onChange={(n) => setSpotCheckType(Number(n))}
          >
            <Radio value={0}>开始点检</Radio>
            <Radio value={1}>结束点检</Radio>
          </Radio.Group>
        </div>
        <div>
          {/* 需求变更，添加点检内容 */}
          <Table
            rowKey={"spotCheckInfoId"}
            currentModule="pointCheckContent"
            key={"spotCheck-table"}
            columns={spotCheckColumns}
            dataSource={dataSource}
            rowSelection={false}
          />
        </div>
        <div>点检内容</div>
        <div className={style.spotCheckButtons}>
          <Button
            color="primary"
            // className={isNormal ? "" : style.notClictk}
            className={
              typeof isNormal !== "boolean"
                ? style.notClictk
                : isNormal
                ? ""
                : style.notClictk
            }
            // onClick={() => setIsNormal(true)} //需求变更
            onClick={() => normalSpotCheck()}
          >
            正常
          </Button>
          <Button
            color="danger"
            // className={isNormal ? style.notClictk : ""}
            className={
              typeof isNormal !== "boolean"
                ? style.notClictk
                : isNormal
                ? style.notClictk
                : ""
            }
            // onClick={() => setIsNormal(false)} //需求变更
          >
            缺陷
          </Button>
        </div>
        {!isNormal && (
          <>
            <TextArea
              placeholder="输入点检结果"
              rows={4}
              value={description}
              onChange={(s) => setDescription(s)}
            />
            <div>图片上传</div>
            <div className={style.imgs}>
              <div className={style.upImg} onClick={() => onOpenFileChooser()}>
                点击上传
              </div>
              {imgList.map(({ name, filePath }) => (
                <div key={filePath}>
                  <div
                    className={style.close}
                    onClick={() => deleteImg(filePath)}
                  />
                  <img src={FILE + filePath} alt={name} />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <Button className={style.CheckButton} loading={loading} onClick={submit}>
        提 交 保 存
      </Button>
    </>
  );
};

export default SpotCheckSubmit;
